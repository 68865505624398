import { ViewSignIn, ViewSignUp, ViewType } from "@supabase/auth-ui-shared";

export enum links {
  ROOT = "/",
  CHAT = "/chat",
  LOGIN = "/login",
  SETUP = "/setup",
  SETTINGS = "/settings",
  HISTORY = "/history",
  VOCABULARY = "/vocabulary",
  SUMMARY = "/summary",
}

export const authViewStates: Record<
  ViewSignIn | ViewSignUp,
  { state: { authview: ViewType } }
> = {
  sign_in: { state: { authview: 'sign_in' } },
  sign_up: { state: { authview: 'sign_up' } },
}