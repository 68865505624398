import {SupportedLanguageCodes} from "interfaces/SupportedLanguages"
import {FC, useEffect, useState} from "react"
import {ChooseLanguage} from "../components/ChooseLanguage"
import {ChooseLevel} from "../components/ChooseLevel"
import {FormWrapper} from "../components/FormWrapper"
import {MultiStep} from "../components/MultiStep"
import {Stepper} from "../components/Stepper"
import { useUserClient } from "../clients/user"
import {Button} from "../components/Button"
import {LevelDTO} from "interfaces/Level"
import {useNavigate} from "react-router-dom"
import {useAuth} from "../contexts/AuthContext"
import { getLanguages } from "../helpers/language"

export const Setup: FC = () => {
  const [level, setLevel] = useState<LevelDTO | null>(null)
  const [language, setLanguage] = useState<SupportedLanguageCodes | null>(null)
  const [nativeLanguage, setNativeLanguage] = useState<SupportedLanguageCodes | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const languages = getLanguages()
  const { refreshUser } = useAuth()
  const navigate = useNavigate()
  const userClient = useUserClient()
  const stepVariables = [nativeLanguage, language, level]
  const step = stepVariables.findIndex(val => !val)

  const lang = language && languages[language]

  const titles = [
    <p>Choose your native language</p>,
    <p>Choose your target language</p>,
    <p>
        <span className="inline">Choose your </span>
        {lang && <img
          src={lang.iconURL}
          alt={lang.name}
          className="inline w-4 h-4 rounded-full"
        />}
        <span className="inline"> level</span>
    </p>,
    <p>Preparing Profile</p>,
  ]
  const title = titles[step]

  const back = () => {
    if (level) { setLevel(null); return }
    if (language) { setLanguage(null); return }
    if (nativeLanguage) { setNativeLanguage(null); return }
  }

  useEffect(() => {
    if (!level || !language || !nativeLanguage) return;

    const callAPI = async () => {
      setIsLoading(true)
      try {
        await userClient.update({ 
          userLanguageCode: nativeLanguage,
          targetLanguageCode: language, 
          languages: [{ languageCode: language, level }] 
        })
        await refreshUser()
      } catch (e) { 
        console.error(e)
      } finally {
        setIsLoading(false)
        navigate('/')
      }
    }

    callAPI()
  }, [ step ]) 

  return <FormWrapper title={title} isLoading={isLoading}>
    <Stepper current={step} steps={4} />
    <MultiStep currentStep={step} steps={[
      <ChooseLanguage onSubmit={setNativeLanguage}/>,
      <ChooseLanguage onSubmit={setLanguage} omit={nativeLanguage ? [nativeLanguage] : []}/>,
      <ChooseLevel isLoading={false} onSubmit={setLevel}/>,
    ]}/>
    { step > 0 && 
      <Button onClick={back}>
        Back
      </Button>
    }
  </FormWrapper>

}
