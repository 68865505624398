import {faRefresh} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {FC, useState} from "react"
import {useNavigate} from "react-router-dom"
import {Button} from "../components/Button"
import {links} from "../constants/links"
import {useAuth} from "../contexts/AuthContext"
import {useLesson} from "../contexts/LessonContext"
import { getLanguages } from "../helpers/language"
import _ from "lodash"

type StartItemProps = {
  emogi: string,
  phrase: string,
  targetLanguagePhrase: string,
  tag?: 'recommended' | 'completed',
  onClick: () => void,
}
const StarterItem: FC<StartItemProps> = ({
  emogi,
  phrase,
  targetLanguagePhrase,
  onClick,
  tag
}) => {
  return <li key={phrase}>
      <a className="flex flex-col items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white" onClick={onClick}>
        <div className="flex flex-row items-start w-full">
          <p>{emogi}</p>
          <span className="flex-1 ml-3 whitespace-nowrap">{phrase}</span>
          {tag === 'recommended' && <span className="inline-flex items-center justify-center px-2 py-0.5 ml-3 text-xs font-medium text-gray-500 bg-gray-200 rounded dark:bg-gray-700 dark:text-gray-400">Recommended</span>}
        </div>
        <div className="self-start py-1">
          <p className="h-8 overflow-hidden text-sm font-normal text-gray-500 leading-4 dark:text-gray-400">
            "{targetLanguagePhrase}"
          </p>
        </div>
      </a>
  </li>
}

export const Starter: React.FC = function() {
  const { user } = useAuth()
  const navigate = useNavigate()
  const { startLesson, isLoading } = useLesson()
  const starters = _.mapValues(getLanguages(), l => l.phrases)
  const [itemSliceIndex, setItemSliceIndex] = useState(0)
  const targetLanguageCode = user?.targetLanguage?.code!

  const clippedIndex = itemSliceIndex % starters[targetLanguageCode].length
  const items = starters[targetLanguageCode]
    .slice(clippedIndex, clippedIndex+3)
    .map(starter => {
      return <StarterItem 
        key={starter.phrase}
        phrase={starter.phrase} 
        emogi={starter.emoji}
        targetLanguagePhrase={starter.targetLanguagePhrase}
        onClick={async () => {
          await startLesson(starter.targetLanguagePhrase)
          navigate(links.CHAT)
        }}
      />
  })

  const refreshItems = () => {
    setItemSliceIndex(i => (i + 3))
  }

  return (
    <section className="flex flex-col justify-center h-full isolate bg-main">
      <div className="w-full max-w-sm p-4 mx-auto bg-white border border-gray-200 rounded-lg shadow sm:p-6 dark:bg-gray-800 dark:border-gray-700">
          <h5 className="mb-3 text-base font-semibold text-gray-900 md:text-xl dark:text-white">
            Start A Lesson
          </h5>
          <p className="text-sm font-normal text-gray-500 dark:text-gray-400">
            Kick off a {user?.targetLanguage?.displayName} lesson with one of these conversation starters.
          </p>
          <ul className="my-4 space-y-3">
            {items}
          </ul>

          <div className="flex justify-center w-full p-2">
            <button type="button" className="inline-flex justify-center p-2 text-lg font-medium text-center text-white rounded-full bg-main hover:bg-third focus:ring-4 focus:outline-none focus:ring-blue-200 dark:focus:ring-blue-900" onClick={refreshItems}>
              <FontAwesomeIcon icon={faRefresh} spin={isLoading} />
            </button>
          </div>
          
          <div className="pt-4">
          <Button 
            isLoading={isLoading}
            onClick={async () => {
            await startLesson()
            navigate(links.CHAT)
          }}> Or just start chatting </Button>
          </div>

      </div>
    </section>
  )
}
