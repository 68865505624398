import {LanguageFile} from "../helpers/language";

export const pt: LanguageFile = {
  "code": "pt",
  "localCode": "pt-PT",
  "name": "portuguese",
  "displayName": "Portuguese",
  "voices": ["pt-PT-Standard-A"],
  "iconURL": "pt.png",
  "phrases": [
    {"phrase": "About myself", "targetLanguagePhrase": "Gostaria de falar sobre mim mesmo.", "emoji": "🧑"},
    {"phrase": "Favorite Spanish dish", "targetLanguagePhrase": "Gostaria de falar sobre o meu prato espanhol favorito.", "emoji": "🥘"},
    {"phrase": "About my pet", "targetLanguagePhrase": "Gostaria de falar sobre o meu animal de estimação.", "emoji": "🐾"},
    {"phrase": "Favorite season", "targetLanguagePhrase": "Gostaria de falar sobre a minha estação do ano favorita.", "emoji": "🌦"},
    {"phrase": "About music", "targetLanguagePhrase": "Gostaria de falar sobre música.", "emoji": "🎵"},
    {"phrase": "Favorite book", "targetLanguagePhrase": "Gostaria de falar sobre o meu livro favorito.", "emoji": "📚"},
    {"phrase": "Dream vacation", "targetLanguagePhrase": "Gostaria de falar sobre as minhas férias dos sonhos.", "emoji": "🌴"},
    {"phrase": "About sports", "targetLanguagePhrase": "Gostaria de falar sobre desportos.", "emoji": "⚽"},
    {"phrase": "About a film I've recently watched", "targetLanguagePhrase": "Gostaria de falar sobre um filme que assisti recentemente.", "emoji": "🎬"},
    {"phrase": "Favorite type of coffee", "targetLanguagePhrase": "Gostaria de falar sobre o meu tipo de café favorito.", "emoji": "☕"},
    {"phrase": "About my hobbies", "targetLanguagePhrase": "Gostaria de falar sobre os meus hobbies.", "emoji": "🎨"},
    {"phrase": "About my job", "targetLanguagePhrase": "Gostaria de falar sobre o meu trabalho.", "emoji": "💼"},
    {"phrase": "About gardening", "targetLanguagePhrase": "Gostaria de falar sobre jardinagem.", "emoji": "🌿"},
    {"phrase": "About fashion", "targetLanguagePhrase": "Gostaria de falar sobre moda.", "emoji": "👗"},
    {"phrase": "Favorite childhood memory", "targetLanguagePhrase": "Gostaria de falar sobre a minha memória de infância favorita.", "emoji": "🧸"},
    {"phrase": "About technology", "targetLanguagePhrase": "Gostaria de falar sobre tecnologia.", "emoji": "💻"},
    {"phrase": "About my favorite city", "targetLanguagePhrase": "Gostaria de falar sobre a minha cidade favorita.", "emoji": "🏙"},
    {"phrase": "About my favorite car", "targetLanguagePhrase": "Gostaria de falar sobre o meu carro favorito.", "emoji": "🚗"},
    {"phrase": "About sustainability", "targetLanguagePhrase": "Gostaria de falar sobre sustentabilidade.", "emoji": "🌍"},
    {"phrase": "About baking", "targetLanguagePhrase": "Gostaria de falar sobre pastelaria.", "emoji": "🧁"},
    {"phrase": "Favorite workout routine", "targetLanguagePhrase": "Gostaria de falar sobre a minha rotina de treino favorita.", "emoji": "🏋️"},
    {"phrase": "About photography", "targetLanguagePhrase": "Gostaria de falar sobre fotografia.", "emoji": "📸"},
    {"phrase": "About my favorite video game", "targetLanguagePhrase": "Gostaria de falar sobre o meu videojogo favorito.", "emoji": "🎮"},
    {"phrase": "About a concert I've attended", "targetLanguagePhrase": "Gostaria de falar sobre um concerto a que assisti.", "emoji": "🎤"},
    {"phrase": "About a hiking trip", "targetLanguagePhrase": "Gostaria de falar sobre uma caminhada que fiz.", "emoji": "🥾"},
    {"phrase": "About my favorite cocktail", "targetLanguagePhrase": "Gostaria de falar sobre o meu cocktail favorito.", "emoji": "🍹"},
    {"phrase": "About astronomy", "targetLanguagePhrase": "Gostaria de falar sobre astronomia.", "emoji": "🌌"},
    {"phrase": "About an inspiring person in my life", "targetLanguagePhrase": "Gostaria de falar sobre uma pessoa que me inspira na minha vida.", "emoji": "💫"},
    {"phrase": "About a language I'm learning", "targetLanguagePhrase": "Gostaria de falar sobre uma língua que estou a aprender.", "emoji": "🌐"},
    {"phrase": "About my favorite piece of art", "targetLanguagePhrase": "Gostaria de falar sobre a minha obra de arte favorita.", "emoji": "🎨"}
  ],
  "questionPrompts": {
    "meaning": ["O que significa __*__?", "O que isso significa?"],
    "correct": ["Está correto __*__?", "Está correto?"],
    "alternative": ["Você quis dizer algo diferente de __*__?", "Você quis dizer algo mais?"],
    "intentPrompt": ["O que você estava tentando dizer em vez de __*__? Eu te direi como dizer isso em __target_lang__.", "O que você estava tentando dizer? Eu te direi como dizer isso em __target_lang__."],
    "mistakePrompt": ["Quais são os erros na frase __*__", "Quais são os erros nesta frase?"]
  },
  "commands": {
    "translate": "^como se diz (.*)\\?",
    "repeat": "repita isso",
    "repeatSlowly": "diga isso devagar",
    "retry": "volte atrás"
  }
}
