import {faV} from "@fortawesome/free-solid-svg-icons"
import React from "react"
import {HTMLAttributes } from "react"
import {IconButton} from "./IconButton"
import { CircleCounter } from "./CircleCounter"
import { CornerMounted } from "./CornerMounted"


type Props = {
  notificationCount: number
  totalCount: number
} & HTMLAttributes<HTMLButtonElement>

export const VocabularyIcon = React.forwardRef<HTMLButtonElement, Props>(
  ({ totalCount, notificationCount, ...props }, ref) => {
    const hasNotifications = notificationCount > 0

    return  <div>
      <IconButton 
        icon={faV} 
        circle 
        grayscale 
        inverse 
        extraClassnames="z-10" 
        ref={ref} 
        {...props}
      >
        { hasNotifications && <CornerMounted pos='bottom-left'> 
            <CircleCounter color="green" count={notificationCount} />
          </CornerMounted>
        }
        <CornerMounted pos='top-left'>
          <CircleCounter color="main" count={totalCount} /> 
        </CornerMounted>
      </IconButton>
    </div>
})
