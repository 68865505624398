import {faEllipsis} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

interface Props extends React.HTMLAttributes<HTMLOrSVGElement> { 
  inverse?: boolean,
  transparent?: boolean,
} 

export const Loading: React.FC<Props> = ({
  inverse,
  transparent,
  ...props
}) => {
  const colors = {
    bg: 'white',
    text: 'main',
  }

  const bg = inverse ? colors.text : colors.bg
  const text = inverse ? colors.bg : colors.text

  return <FontAwesomeIcon 
    {...props}
    className={`text-3xl text-${text} bg-${transparent ? 'transparent' : bg}`}
    icon={faEllipsis} 
    beat 
  />
};
