import {useNavigate} from "react-router-dom";
import {links} from "../constants/links";
import {useError} from "../contexts/ErrorContext";
import {Button} from "./Button";

interface Props {
  error?: Error | null,
}

export const ErrorWrapper: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  error,
}) => {
  const navigate = useNavigate()
  const { setError } = useError()

  if (error) console.error(error.message)

  if (error) return <div className="flex flex-col items-center justify-center h-full isolate bg-main">
    <p className="text-2xl text-white bold">Oops, something went wrong :(</p>
    <div className="py-8">
      <Button 
        onClick={() => {
          setError(null)
          return navigate(links.ROOT);
        }}
        stretch={false}
      >
        Go back to landing page
      </Button>
    </div>
  </div>

  return <>{children}</>
};
