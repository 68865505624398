import {faEllipsis} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

export const Loading: React.FC = function() {
  return (
    <div className="flex flex-col">
      {/* first page section*/}
      <section className="flex flex-col items-center justify-center h-screen px-12 isolate bg-main">
        <FontAwesomeIcon className='text-6xl text-white' icon={faEllipsis} beatFade />
      </section>
    </div>
  )
}
