import _ from "lodash";
import {FormWrapper} from "./FormWrapper";
import {Card} from "./Card";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDove, faEgg, faKiwiBird} from "@fortawesome/free-solid-svg-icons";
import { LevelDTO } from "../../lib/interfaces/Level"

interface ChooseLevelProps {
  onSubmit: (level: LevelDTO) => void,
  isLoading: boolean,
}

const levels: {level: LevelDTO, levelDisplayName: string, description: string, icon: JSX.Element }[] = [
  { level: 'BEGINNER', levelDisplayName: 'Beginner', description: "just getting started", icon: <FontAwesomeIcon icon={faEgg} className="pr-2 text-green-600"/>},
  { level: 'INTERMEDIATE', levelDisplayName: 'Intermediate', description: "can manage a conversation", icon: <FontAwesomeIcon icon={faKiwiBird} className="pr-2 text-blue-600"/>},
  { level: 'ADVANCED', levelDisplayName: "Advanced", description: "you're flying!", icon: <FontAwesomeIcon icon={faDove} className="pr-2 text-red-600"/>},
]

const ChooseLevel: React.FC<ChooseLevelProps> = function({ isLoading, onSubmit }) {

   return (
      <>
        <ul className="mb-8 text-left text-gray-500 space-y-4 dark:text-gray-400">
        { _.values(levels).map(({ level, levelDisplayName, description, icon }) => (
          <li 
            className="flex" 
            key={`${level}-item`}
            onClick={(e) => {onSubmit(level)}}
          >
            <Card>
              { icon }
              <h3 className="inline-block mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">{levelDisplayName}</h3>
              <p className="block mb-3 font-normal text-gray-500 dark:text-gray-400">{description}</p>
            </Card>
          </li>))
        }
        </ul>
      </>
    );
 }

export {ChooseLevel}
