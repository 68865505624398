import { FC, PropsWithChildren } from "react"

export type CircleCounterProps = {
    count: number,
    color?: 'main' | 'green',
}
export const CircleCounter: FC<PropsWithChildren<CircleCounterProps>> = ({ count, color = 'main' }) => {

    const colorClass = {
        main: 'bg-main',
        green: 'bg-green-500',
    }[color]

    return <p className={`
                inline-flex 
                items-center justify-center 
                w-6 h-6 
                border-2 border-white rounded-full 
                ${colorClass}
                text-xs text-white font-bold 
                dark:border-gray-900
            `}>
        {count}
    </p>
}