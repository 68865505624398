import {PropsWithChildren, useEffect, useState} from 'react'
import {FormWrapper} from '../components/FormWrapper'
import {SupportedLanguageCodes } from 'interfaces/SupportedLanguages'
import {useAuth} from '../contexts/AuthContext'
import {LanguagePicker} from '../components/LanguagePicker'
import { useUserClient } from '../clients/user'
import {SelectDropdown} from '../components/SelectDropdown'
import {LevelDTO} from 'interfaces/Level'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheck, faVolumeHigh, faX } from '@fortawesome/free-solid-svg-icons'
import {Button} from '../components/Button'
import _ from 'lodash'
import {Loading} from '../components/Loading'
import { getLanguages } from '../helpers/language'
import {Toggle} from "../components/Toggle";
import { useChat } from '../hooks/use-chat'

type SectionProps = PropsWithChildren<{
  title: string,
}>
const Section = ({children, title}: SectionProps) => {
    const id = title.toLowerCase().split(" ").join("-");
    return <div>
        <a id={id}>
            <h2 className="text-base font-semibold text-gray-900 leading-7 dark:text-gray-100" id={id}>{title}</h2>
        </a>
        <p className="mt-1 text-sm text-gray-600 leading-6"></p>
        <div>
            {children}
        </div>
    </div>;
}

export function Settings() {
  const { user, refreshUser, loadingUser } = useAuth()
  const languages = getLanguages();
  const userClient = useUserClient()
  const { speak, isSpeaking } = useChat()

  if (loadingUser) return <Loading />

  const currentUserTargetLanguage = user.targetLanguage?.code || 'fr'
  const currentUserLanguage = user.userLanguageCode
  const getLevelForLanguage = (lang: SupportedLanguageCodes) => user.languages.find(l => l.languageCode === lang)?.level || null
  const userLanguageLevel = user.languages.find(l => l.languageCode === user.targetLanguage?.code)?.level || null

  const [nativeLanguage, setNativeLanguage] = useState<SupportedLanguageCodes>(currentUserLanguage)
  const [language, setLanguage] = useState<SupportedLanguageCodes>(currentUserTargetLanguage)
  const [level, setLevel] = useState<LevelDTO | null>(userLanguageLevel)
  const [savedMessageOpen, setSavedMessageOpen] = useState(false)
  const [autoReadEnabled, setAutoReadEnabled] = useState(user.autoReadEnabled)
  const toggleAutoReadEnabled = () => setAutoReadEnabled(!autoReadEnabled)

  useEffect(() => {
    setSavedMessageOpen(false)
  }, [language, level])

  useEffect(() => {
    setLevel(getLevelForLanguage(language))
  }, [language])

  const submit = async () => {
    if (!language) return;
    if (!nativeLanguage) return;
    if (!level) return;
    if (!user || !user.UID) return;
    console.log('saving', language, level)

    await userClient.update({
      userLanguageCode: nativeLanguage,
      targetLanguageCode: language,
      languages: [{languageCode: language, level }],
      autoReadEnabled,
    })

    await refreshUser() 
    setSavedMessageOpen(true)
  }

  const onChangeLevel = (newLevel: LevelDTO) => {
    setLevel(newLevel)
  }

  const onChangeLanguage = (l: SupportedLanguageCodes) => {
    setLanguage(l)
  }

  const onChangeNativeLanguage = (l: SupportedLanguageCodes) => {
    setNativeLanguage(l)
  }

  const levels: {id: string, name: string, level: LevelDTO | null}[] = [
    {id: "BEGINNER", name: 'Beginner', level: "BEGINNER"},
    {id: "INTERMEDIATE", name: 'Intermediate', level: "INTERMEDIATE"},
    {id: "ADVANCED", name: 'Advanced', level: "ADVANCED"},
    {id: "unchosen", name: 'Choose a level', level: null},
  ]
  const levelIndex = levels.findIndex(l => l.level === level)

  return (
    <FormWrapper title='Your Settings'>
      <form onSubmit={(e) => {e.preventDefault(); submit()}} >
          <div className="space-y-12">
              <Section title={'Settings'}>
                  <div className='py-2'>
                      <LanguagePicker
                          title="Native Language"
                          onChange={onChangeNativeLanguage}
                          startingLanguage={nativeLanguage}
                      />
                  </div>
                  <div className='py-2'>
                      <LanguagePicker
                          title="Target Language"
                          onChange={onChangeLanguage}
                          startingLanguage={currentUserTargetLanguage}
                          languageCodes={_.values(languages).map(l => l.code).filter(lc => lc !== nativeLanguage)}
                      />
                  </div>
                  <div className='py-2'>
                          <SelectDropdown<{ level: LevelDTO | null, id: string, name: string }>
                              title={'Language Level'}
                              options={levels}
                              selected={levels[levelIndex]}
                              setSelected={(item) => {
                                  item.level && onChangeLevel(item.level)
                              }}
                          />
                      </div>
              </Section>
              <Section title={'Lesson Preferences'}>
                  <div className='py-2 flex items-center gap-4'>
                      <Toggle checked={autoReadEnabled}
                              value={'Automatically read responses aloud'}
                              onClick={toggleAutoReadEnabled}
                      >
                      </Toggle>
                      <div>
                          <h1 className="font-medium text-gray-900 dark:text-gray-100">Read Aloud</h1>
                          <p className="text-sm text-gray-500 dark:text-gray-400">Automatically read the chat responses out loud.</p>
                      </div>
                  </div>
              </Section>
              <Section title={'Media Test'}>
                  <div className='py-2 flex items-center gap-4'>
                      <Button
                        inverse
                        stretch={false}
                        disabled={isSpeaking}
                        onClick={() => speak('Hey, this is a test, your audio is working!')}
                      >
                          <FontAwesomeIcon icon={faVolumeHigh} className="h-4 w-4" />
                          <span className="pl-2">Test Audio</span>
                      </Button>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        Click to verify your audio is working correctly.
                      </p>
                  </div>
              </Section>
              <div>
                  <Button onClick={submit}>
                      Save Changes
                  </Button>
              </div>
          </div>
      </form>
      {savedMessageOpen && <div id="toast-default" className="flex items-center w-full max-w-xs p-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800" role="alert">
          <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-blue-500 bg-blue-100 rounded-lg dark:bg-blue-800 dark:text-blue-200">
              <FontAwesomeIcon icon={faCheck} fixedWidth />
              <span className="sr-only">Fire icon</span>
          </div>
          <div className="ml-3 text-sm font-normal">Your settings have been saved.</div>
          <button 
            onClick={(e) => {e.preventDefault(); setSavedMessageOpen(false)}}
            type="button" 
            className={`
            inline-flex 
            justify-center items-center
            bg-white text-gray-400 
            h-8 w-8 
            ml-auto -mx-1.5 -my-1.5 
            p-1.5 
            rounded-lg 
            hover:bg-gray-100 hover:text-gray-900 
            focus:ring-2 focus:ring-gray-300 
            dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700`} data-dismiss-target="#toast-default" aria-label="Close"
            >
              <span className="sr-only">Close</span>
              <FontAwesomeIcon icon={faX} fixedWidth className="w-4 h-4"/>
          </button>
      </div> }
    </FormWrapper>
  )
}

