import {useError} from "../contexts/ErrorContext";
import {ErrorWrapper} from "./ErrorWrapper";
import {Navbar} from "./Navbar";
import React from "react";

export const Page: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { error } = useError()

  return (
    <div className="flex flex-col h-screen bg-white">
      <Navbar/>
      <ErrorWrapper error={error}>
        <div className="flex-1">
          {children}
        </div>
      </ErrorWrapper>
    </div>
  );
};
