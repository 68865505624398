import React, { FC, PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Route, Navigate, Routes } from 'react-router-dom';
import { Language } from './helpers/language';
import { SignIn } from './routes/SignIn';
import { Page } from './components/Page';
import { Settings } from './routes/Settings';
import { History } from './routes/History';
import { LessonProvider, useLesson } from './contexts/LessonContext';
import Summary from './routes/Summary';
import { LandingInfo } from './components/LandingInfo';
import Chat from './routes/Chat';
import { Loading } from './routes/Loading';
import { Starter } from './routes/Starter';
import { Vocabulary } from './routes/Vocabulary';
import { AudioProvider } from './contexts/AudioContext';
import Modal from 'react-modal'
import { createClient } from '@supabase/supabase-js';
import { AuthProviderSB, useAuth } from './contexts/AuthContext';
import { Setup } from './routes/Setup';
import { UserDTO } from 'interfaces/User';
import Progress from './routes/Progress';
import { links } from './constants/links';
import { ErrorProvider } from './contexts/ErrorContext';
import _ from 'lodash';
import * as Sentry from '@sentry/react';

export interface LingoUser extends UserDTO {
  targetLanguage: Language | null
  userLanguage: Language
}

if (!process.env.REACT_APP_SB_URL) throw Error("must provide REACT_APP_SB_URL")
if (!process.env.REACT_APP_SB_ANON_KEY) throw Error("must provide REACT_APP_SB_ANON_KEY")

Sentry.init({
  dsn: "https://11a50977e3a93570e997404026f7e3cd@o4508426480910336.ingest.de.sentry.io/4508426486939728",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const supabase = createClient(
  process.env.REACT_APP_SB_URL,
  process.env.REACT_APP_SB_ANON_KEY,
)

const WrapInPage: FC<PropsWithChildren> = ({ children }) => {
  return <Page>
    {children}
  </Page>
}

const PrivateRoute: FC<PropsWithChildren> = ({ children }) => {
  const { loadingUser, user } = useAuth()
  if (loadingUser) return <Loading />

  return user
    ? <>{children}</>
    : <Navigate to={links.ROOT} replace />
}

const UnloadedUserOnlyRoute: FC<PropsWithChildren> = ({ children }) => {
  const { isUserReady } = useAuth()

  return !isUserReady
    ? <>{children}</>
    : <Navigate to={links.ROOT} replace />
}

const RequireTargetLanguage: FC<PropsWithChildren> = ({ children }) => {
  const { user } = useAuth()
  return user?.targetLanguage
    ? <>{children}</>
    : <Navigate to={links.SETUP} replace />
}

const WithActiveLesson: FC<PropsWithChildren<{ or: ReactElement }>> = ({ children, or }) => {
  const { isInLesson } = useLesson()
  return <>{isInLesson() ? children : or}</>
}

const RequireReadyUser: FC<PropsWithChildren> = ({ children }) => {
  return <PrivateRoute>
    <RequireTargetLanguage>
      {children}
    </RequireTargetLanguage>
  </PrivateRoute>
}

const App: React.FC = () => {
  const { user, loadingUser, isUserReady } = useAuth()

  const LoginRoute = <UnloadedUserOnlyRoute><WrapInPage><SignIn supabaseClient={supabase} /></WrapInPage></UnloadedUserOnlyRoute>
  const LandingRoute = loadingUser ? <Loading /> : (<LandingInfo />)
  const SetupRoute = <PrivateRoute><WrapInPage><Setup /></WrapInPage></PrivateRoute>
  const SettingsRoute = <PrivateRoute><WrapInPage><Settings /></WrapInPage></PrivateRoute>
  const ProgressRoute = <PrivateRoute><WrapInPage><Progress /></WrapInPage></PrivateRoute>
  const HistoryRoute = <PrivateRoute><WrapInPage><History /></WrapInPage></PrivateRoute>
  const SummaryRoute = <PrivateRoute><WrapInPage><Summary /></WrapInPage></PrivateRoute>
  const VocabularyRoute = <RequireReadyUser><WrapInPage><Vocabulary /></WrapInPage></RequireReadyUser>
  const StarterRoute = <RequireReadyUser><WrapInPage><Starter /></WrapInPage></RequireReadyUser>
  const ChatRoute = <RequireReadyUser>
    <WithActiveLesson or={StarterRoute}>
      <WrapInPage>
        <Chat />
      </WrapInPage>
    </WithActiveLesson>
  </RequireReadyUser>

  return (
    <Routes>
      <Route path={links.LOGIN} element={LoginRoute} />
      <Route path={links.ROOT} element={user ? ProgressRoute : LandingRoute} />
      <Route path={links.CHAT} element={ChatRoute} />
      <Route path={links.SETUP} element={SetupRoute} />
      <Route path={links.SETTINGS} element={SettingsRoute} />
      <Route path={links.HISTORY} element={HistoryRoute} />
      <Route path={links.VOCABULARY} element={VocabularyRoute} />
      <Route path={`${links.SUMMARY}/:lessonId`} element={SummaryRoute} />
    </Routes>
  );
};

Modal.setAppElement(document.getElementById('root') as HTMLElement)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <AudioProvider>
      <ErrorProvider>
        <AuthProviderSB supabaseClient={supabase}>
          <LessonProvider>
            <BrowserRouter>
              <div className='flex flex-col h-screen' >
                <App />
              </div>
            </BrowserRouter>
          </LessonProvider>
        </AuthProviderSB>
      </ErrorProvider>
    </AudioProvider>
  </React.StrictMode>
);