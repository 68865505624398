import React, { FC } from 'react';

interface Props {
  text: string;
  toHighlight: string[];
}

const Highlighter: FC<Props> = ({ text, toHighlight }) => {
  const matches = toHighlight
    .map(h => ({i: text.toLowerCase().indexOf(h.toLowerCase()), text: h}))
    .filter(h => h.i >= 0)
  
  let part = 0;
  const parts: string[] = [""]
  const firstCharIsBold = matches.some(m => m.i === 0) // first char is bold
  let currentlyWritingBoldBuffer = firstCharIsBold
  const toggleBuffer = () => { 
    currentlyWritingBoldBuffer = !currentlyWritingBoldBuffer; 
    part++; 
    parts[part] = "" 
  }

  for (let i = 0; i < text.length; i++ ) {
    if (matches.some(m => i >= m.i && i < m.i + m.text.length)) {
      // bold
      if (!currentlyWritingBoldBuffer) {
        toggleBuffer()
      }
    } else {
      // notBold
      if (currentlyWritingBoldBuffer) {
        toggleBuffer()
      }
    }
    parts[part] += text[i]
  }

  if (firstCharIsBold) {
    return <>{parts.map((s, i) => (i % 2 == 0) ? <strong key={s + i}>{s}</strong> : <span key={s + i}>{s}</span>) } </>
  } else {
    return <>{parts.map((s, i) => (i % 2 == 1) ? <strong key={s + i}>{s}</strong> : <span key={s + i}>{s}</span>) } </>
  }
}

export default Highlighter;

