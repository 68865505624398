import { useLongPress } from 'use-long-press';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMicrophoneAlt, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

type MicButtonProps = {
  startRecording: () => void,
  stopRecording: () => void,
  cancelRecording: () => void,
  isRecording: boolean,
  isEnabled: boolean,
  setHandsFreeRecording: (isHandsFree: boolean) => void
};

const MicButton = (props: MicButtonProps) => {
   const {
    startRecording,
    stopRecording,
    cancelRecording,
    isRecording,
    isEnabled,
    setHandsFreeRecording,
  } = props;

   const bind = useLongPress(() => { 
     // if it's a long press, it's not handsfree
     setHandsFreeRecording(false) 
   }, {
    cancelOutsideElement: true,
    onStart: (event, meta) => {
      if (!isEnabled) return;

      if (isRecording) {
        stopRecording()
        setHandsFreeRecording(false)
      } else {
        startRecording()
        setHandsFreeRecording(true)
      }
    },
    // onCancel is a regular click
    onCancel: (event, meta) => {
      if (meta.reason === 'cancelled-outside-element') {
        setHandsFreeRecording(false)
        cancelRecording();
      }
    },
    onFinish: (event, meta) => {
      if (!isEnabled) return;

      setHandsFreeRecording(false)
      stopRecording()
    },
  });

  const activeClasses = isRecording
    ? "bg-green-400 text-white scale-150" 
    : "bg-gray-100 text-gray-500 disabled:text-gray-300"

  return <button {...bind()} 
            type="button" 
            disabled={!isEnabled}
            className={"flex flex-row items-center content-center justify-start p-4 rounded-full gap-1 h-sm drop-shadow transition-colors transition-transform duration-300 " + activeClasses}
          >
    {isRecording 
      ? <FontAwesomeIcon icon={faPaperPlane} fixedWidth />
      : <FontAwesomeIcon icon={faMicrophoneAlt} fixedWidth /> }
    </button>
};

export { MicButton }

