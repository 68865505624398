import { Fragment } from 'react'
import { Disclosure, Menu, Transition  } from '@headlessui/react'
import {useLocation, useNavigate} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCircleUser} from '@fortawesome/free-solid-svg-icons'
import { ReactComponent as BotIcon } from '../images/chatbot-icon.svg'
import {useAuth} from '../contexts/AuthContext'
import {useLesson} from '../contexts/LessonContext'
import {Button} from './Button'
import { authViewStates, links } from '../constants/links'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

type MenuItem = {
  name: string,
  current: boolean,
  handler: () => Promise<void>,
}

export function Navbar() {
  const navigate = useNavigate()
  const location = useLocation()
  const { user, logout } = useAuth()
  const { isInLesson } = useLesson()

  const navigation: MenuItem[] = [
    { name: 'Chat', current: false, handler: async () => {navigate(links.CHAT)} }, 
  ]

  const loggedOutProfileMenu = [
    { name: "Login", handler: async () => navigate(links.LOGIN, authViewStates.sign_in) },
  ]

  const loggedInProfileMenu = [
    { name: "Progress Tracker", handler: () => navigate(links.ROOT)  },
    { name: "Lesson History", handler: () => navigate(links.HISTORY)  },
    { name: "Vocabulary", handler: () => navigate(links.VOCABULARY)  },
    { name: "Settings", handler: () => navigate(links.SETTINGS) },
    { name: "Sign Out", handler: async () => {
      const loggedOut = await logout();
      if (loggedOut) navigate(links.ROOT)
    }},
  ]

  const profileMenuItems = user 
    ? loggedInProfileMenu 
    : loggedOutProfileMenu

  const profileDropdown = <Menu as="div" className="relative ml-3">
    <div>
      <Menu.Button className="flex text-sm text-white rounded-full bg-main focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
        <span className="sr-only">Open user menu</span>
        <FontAwesomeIcon icon={faCircleUser} className="w-8 h-8 rounded-full"/>
      </Menu.Button>
    </div>
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items className="absolute right-0 z-30 w-48 py-1 mt-2 bg-white shadow-lg origin-top-right rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-900">
        {profileMenuItems.map(item => (
          <Menu.Item key={item.name}>
          {({ active  }) => (
              <a
                key={item.name}
                href="#"
                onClick={(e) => {e.preventDefault(); item.handler()}}
                className={classNames(active ? 'bg-gray-100 dark:bg-gray-700' : '', 'block px-4 py-2 text-sm text-gray-700 dark:text-gray-100')}
              >
                {item.name}
              </a>
          )}
          </Menu.Item>))}
      </Menu.Items>
    </Transition>
  </Menu>

  const startLessonButton = <Button
    stretch={false}
    primary={true}
    onClick={() => user ? navigate(links.CHAT) : navigate(links.LOGIN)}
    className="w-auto"
  > Start A Lesson 
  </Button>
  
  const returnToLessonButton = <Button
    stretch={false}
    primary={false}
    inverse={true}
    className="w-auto"
    onClick={() => user ? navigate(links.CHAT) : navigate(links.LOGIN)}
  > Go To Lesson
  </Button>

  const CTAButtons = isInLesson() ? returnToLessonButton : startLessonButton

  const isOnStartLessonPage = location.pathname === links.CHAT

  const botIcon = <div className="flex items-center flex-shrink-0">
    <BotIcon 
      className="block w-auto h-8 fill-slate-200"
      onClick={(e) => {navigate(links.ROOT)}}
    />
  </div>

  return (
      <Disclosure as="nav" className="bg-main">
      {({ open  }) => (
          <>
            <div className="px-2 mx-auto max-w-7xl sm:px-6 lg:px-8">
              <div className="relative items-center h-16 grid grid-cols-3 gap-1">
                <div className="justify-self-start">
                  {user && !isOnStartLessonPage && CTAButtons}
                </div>
                <div className="justify-self-center">
                  {botIcon}
                </div>
                <div className="justify-self-end">
                  {profileDropdown}
                </div>
              </div>
            </div>
            <Disclosure.Panel className="sm:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1">
              {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    className={classNames(
                        item.current ? 'bg-secondary text-white' : 'text-gray-300 hover:bg-third hover:text-white',
                        'block rounded-md px-3 py-2 text-base font-medium'
                        )}
                    aria-current={item.current ? 'page' : undefined} >
                    {item.name}
                  </Disclosure.Button>
                  ))}
              </div>
            </Disclosure.Panel>
        </>
        )}
      </Disclosure>
    )
}

