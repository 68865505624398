import {isLeft} from 'fp-ts/lib/Either'
import {PathReporter} from 'io-ts/lib/PathReporter'
import * as t from 'io-ts'
import {VocabularyDTO, VocabularyWithLastReviewedDTO, VocabularyWithLastReviewedDTOValidator} from 'interfaces/Vocabulary'
import {SupportedLanguageCodes} from 'interfaces/SupportedLanguages'
import {useAuth} from '../contexts/AuthContext'
import {ensureToken} from '../helpers/clients'

const listResponseValidator = t.type({
  vocabulary: t.array(VocabularyWithLastReviewedDTOValidator)
})

const buildQueryString = function(
  language?: SupportedLanguageCodes, 
  enabled?: boolean
): string {
  const queries = [] 
  if (language) queries.push(`language=${language}`)
  if (enabled !== undefined) queries.push(`enabled=${enabled}`)

  return queries.length > 0
    ? `?${queries.join(`&`)}`
    : ""
}

const update = async (
  token: string, 
  id: number,
  vocabulary: Partial<VocabularyDTO>,
): Promise<void> => {

  const response = await fetch(`/.netlify/functions/update-vocabulary`, {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      id,
      vocabulary,
    })
  })
  if (!response.ok) {
    throw new Error(response.statusText)
  }

  return 
}

const list = async (
  token: string, 
  filter?: { language?: SupportedLanguageCodes, enabled?: boolean }
): Promise<VocabularyWithLastReviewedDTO[]> => {

  const queryString = buildQueryString(filter?.language, filter?.enabled)

  const response = await fetch(`/.netlify/functions/get-vocabulary${queryString}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  if (!response.ok) {
    throw new Error(response.statusText)
  }

  const responseData = await response.json()

  const decoded = listResponseValidator.decode(responseData)
  if (isLeft(decoded)) {
    throw new Error(`Could not validate data: ${PathReporter.report(decoded).join("\n")}`)
  }

  const { vocabulary } = decoded.right

  return vocabulary
}

const count = async (
  token: string, 
): Promise<number> => {

  const response = await fetch(`/.netlify/functions/count-vocabulary`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  if (!response.ok) {
    throw new Error(response.statusText)
  }

  const responseData = await response.json()

  const decoded = t.type({ 
    count: t.number
  }).decode(responseData)
  if (isLeft(decoded)) {
    throw new Error(`Could not validate data: ${PathReporter.report(decoded).join("\n")}`)
  }

  const { count } = decoded.right

  return count
}

export const useVocabularyClient = () => {
  const { session } = useAuth()
  const token = session?.access_token

  return {
    update: (
      a1: Parameters<typeof update>[1],
      a2: Parameters<typeof update>[2],
    ) => update(ensureToken(token), a1, a2),
    list: (
      a1: Parameters<typeof list>[1],
    ) => list(ensureToken(token), a1),
    isReady: !!token,
    count: () => count(ensureToken(token))
  }
}
