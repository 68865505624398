import {faDeleteLeft, faEllipsis, faGaugeSimpleHigh, faLanguage, faRemove, faRepeat, faX} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  FunctionComponent,
  DetailedHTMLProps,
  TableHTMLAttributes,
  HTMLAttributes,
  FC,
  ReactElement,
  PropsWithChildren,
} from "react";
import {ChatMessageCommands, ChatMessageRoles, ChatMessageT} from "../types/ChatMessage";
import HighlightInstances from "./HighlightInstancesNEW";
import {IconButton} from "./IconButton";
import { LessonVocabulary } from "../contexts/LessonContext";
import { Options } from "react-markdown"

const translationGoogleAttribution = <img className="p-2 h-7" src="color-regular.png" />

// This lets us style any markdown tables that are rendered
const CustomTable: FunctionComponent<
  Omit<
    DetailedHTMLProps<TableHTMLAttributes<HTMLTableElement>, HTMLTableElement>,
    "ref"
  > &
    Options
> = ({ children, ...props }) => {
  return (
    <div className="overflow-x-auto">
      <table {...props} className="w-full text-left border-collapse table-auto">
        {children}
      </table>
    </div>
  );
};


type OptionsToggleButtonProps = {
  setMenuOpen: (on: boolean) => void,
  menuOpen: boolean
}
const OptionsToggleButton: FC<OptionsToggleButtonProps> = ({
  setMenuOpen,
  menuOpen,
}) => <IconButton
    circle
    inverse
    onClick={() => setMenuOpen(!menuOpen)}
    icon={menuOpen ? faX : faEllipsis}
  />

type ChatButtonsProps = {
  setMenuOpen: (on: boolean) => void,
  buttons: ReactElement[],
  menuOpen: boolean,
}
const ChatButtons: FC<ChatButtonsProps> = ({
  buttons,
  menuOpen,
  setMenuOpen,
}) => {
  return <div className="flex flex-row justify-start p-2">
    <div className="pr-2"><OptionsToggleButton menuOpen={menuOpen} setMenuOpen={setMenuOpen}/></div>
    {menuOpen && buttons.map((b, i) => 
      <div key={i} className="pr-2"> {b} </div>
    )}
  </div>
}

const InLessonVocabulary: FC<PropsWithChildren> = ({ children }) =>
  <span className={`bg-green-500 text-white hover:bg-green-600 rounded-lg p-1 transition`}>{children}</span>

/**
 * This component renders a single chat message. It is rendered according to
 * whether it isa  message from the assistant or the user, or a command type message.
 */
interface Props {
  message: ChatMessageT;
  isThinking?: boolean;
  displayOptions?: boolean;
  toggleTranslation?: () => Promise<void>
  toggleDisplayOptions?: () => void;
  readAloud?: (message: ChatMessageT, speed?: 'normal' | 'slow') => void;
  vocabulary?: LessonVocabulary[]
  onClick?: (message: ChatMessageT) => void;
  removeTo?: (message: ChatMessageT) => void;
  buttonsEnabled?: boolean;
}
export const ChatMessage: React.FC<React.PropsWithChildren<Props>> = ({
    message,
    isThinking,
    toggleTranslation,
    toggleDisplayOptions = () => {},
    displayOptions = false,
    readAloud,
    vocabulary = [],
    onClick,
    removeTo,
    buttonsEnabled = true,
  }) => {

    interface CommandProps extends HTMLAttributes<HTMLDivElement> {}
    const Command: React.FC<React.PropsWithChildren<CommandProps>> = ({children, ...props}) => {
     return <div className="flex flex-col items-center"> 
        <div className="max-w-lg p-2 bg-gray-300 border-2 rounded-lg border-main" {...props}>
          <p className="flex font-bold">{children}</p>
        </div>
        <ChatButtons 
          buttons={[
            RepeatOptionButton,
          ]}
          setMenuOpen={toggleDisplayOptions}
          menuOpen={displayOptions}
        />
      </div>
    }

    const onToggleTranslation = async () => {
      toggleTranslation && await toggleTranslation();
    }

    const onRepeatOutLoud = () => {
      readAloud && readAloud(message);
    }
    
    const RemoveToButton = <IconButton
      circle
      onClick={() => removeTo && removeTo(message)}
      icon={faDeleteLeft}
    />

    const TranslateOptionButton = <IconButton
      circle
      onClick={onToggleTranslation}
      icon={message.translationLoading ? faEllipsis : faLanguage}
    />

    const RepeatOptionButton = <IconButton
      circle
      onClick={onRepeatOutLoud}
      icon={faRepeat}
    />

    // make vocabulary bold for assistant messages
    const prunedVocabulary = vocabulary
      .filter(v => v.enabled)
      .filter(v => !v.addedDuringLesson)
      .map(v => v.text) 
      .map(v => v.trim())
    const uniqueVocab = Array.from(new Set(prunedVocabulary))

    const prunedVocabulary2 = vocabulary
      .filter(v => v.enabled)
      .filter(v => !v.reviewed)
      .filter(v => v.addedDuringLesson)
      .map(v => v.text) 
      .map(v => v.trim())
    const LessonVocabulary = Array.from(new Set(prunedVocabulary2))

    const Buttons = !isThinking && <ChatButtons 
      buttons={[
        RepeatOptionButton,
        TranslateOptionButton,
        RemoveToButton,
      ]}
      setMenuOpen={() => toggleDisplayOptions()}
      menuOpen={displayOptions}
    /> 

    switch (message.role) {
      case ChatMessageRoles.USER: 
        return <>
          <div className="flex items-end justify-end" >
            <div className="flex flex-col flex-items-end">
              <div 
                className={`max-w-lg p-2 bg-gray-300 border-2 rounded-lg border-main ${onClick ? 'cursor-pointer' : ''}`}
                onClick={() => onClick && onClick(message)}
              >
                {isThinking 
                  ? <FontAwesomeIcon icon={faEllipsis} beatFade/>
                  : <div className="flex flex-col items-center">
                      <p className="self-start">
                        <HighlightInstances 
                          text={message.content} 
                          styledMatches={[
                            {matches: LessonVocabulary, component: InLessonVocabulary},
                            {matches: uniqueVocab, component: ({ children }) => <strong>{children}</strong>},
                          ]}
                        />
                      </p>
                      {message.translationEnabled && <>
                        <FontAwesomeIcon icon={faLanguage} />
                        <p className="font-bold">{message.translation}</p> 
                        {translationGoogleAttribution}
                      </>}
                    </div>
                }
              </div> 
              { buttonsEnabled && Buttons }
            </div>
          </div>
        </>
      case ChatMessageRoles.ASSISTANT:
        return <div className="flex items-end" >
          <div className="flex flex-col">
            <div 
              className={`p-2 mr-20 bg-gray-100 border-2 rounded-lg border-main ${onClick ? 'cursor-pointer' : ''}`}
              style={{minWidth:"28px"}}
              onClick={() => onClick && onClick(message)}
            >
              {isThinking 
                ? <FontAwesomeIcon icon={faEllipsis} beatFade/>
                : <div className="flex flex-col items-center">
                    <p className="self-start">
                      <HighlightInstances 
                        text={message.content} 
                        styledMatches={[
                          { matches: LessonVocabulary, component: InLessonVocabulary},
                          { matches: uniqueVocab, component: ({ children }) => <strong>{children}</strong> },
                        ]}
                      />
                    </p>
                    {message.translationEnabled && <>
                      <FontAwesomeIcon icon={faLanguage} />
                      <p className="font-bold">{message.translation}</p>
                      {translationGoogleAttribution}
                    </>}
                  </div>
              }
              </div>
              { buttonsEnabled && Buttons }
            </div>
          </div>
        case ChatMessageCommands.COMMAND_REPEAT:
          return <Command>
             <FontAwesomeIcon icon={faRepeat} className={'p-2'} />
          </Command>
        case ChatMessageCommands.COMMAND_REPEAT_SLOWLY:
          return <Command>
            <FontAwesomeIcon icon={faGaugeSimpleHigh} className={'p-2'} flip={'horizontal'}/>
          </Command>
        case ChatMessageCommands.COMMAND_RETRY:
          return <Command>
            <FontAwesomeIcon icon={faDeleteLeft} className={'p-2'} />
          </Command>
        case ChatMessageCommands.COMMAND_TRANSLATE:
          const [original, translation] = message.content.split(" = ")
          return <Command onClick={toggleDisplayOptions}>
            <span className="text-center basis-0 grow">{original}</span>
            <FontAwesomeIcon icon={faLanguage} className={'p-2'} />
            <span className="text-center basis-0 grow">{translation}</span>
          </Command>
    }
}
