import * as t from 'io-ts'
import {SupportedLanguageCodes} from "interfaces/SupportedLanguages"
import _ from 'lodash'
import {ChatMessageCommand, ChatMessageCommands} from '../types/ChatMessage'
import {de} from '../data/de'
import {se} from '../data/se'
import {pt} from '../data/pt'
import {es} from '../data/es'
import {en} from '../data/en'
import {fr} from '../data/fr'
import {nl} from '../data/nl'
import {no} from '../data/no'
import {is} from '../data/is'
import {it} from '../data/it'

const LanguagePrompts = t.type({
  correct: t.tuple([t.string, t.string]),
  alternative: t.tuple([t.string, t.string]),
  meaning: t.tuple([t.string, t.string]),
  intentPrompt: t.tuple([t.string, t.string]),
  mistakePrompt: t.tuple([t.string, t.string]),
})
const Commands = t.type({
  translate: t.string,
  repeat: t.string,
  repeatSlowly: t.string,
  retry: t.string,
})
const Phrase = t.type({
  phrase: t.string,
  targetLanguagePhrase: t.string,
  emoji: t.string,
})

const LanguageFile = t.type({
  code: SupportedLanguageCodes,
  localCode: t.string,
  name: t.string,
  displayName: t.string,
  voices: t.array(t.string),
  iconURL: t.string,
  questionPrompts: LanguagePrompts,
  commands: Commands,
  phrases: t.array(Phrase),
})
const LanguageFiles = t.record(SupportedLanguageCodes, LanguageFile)

export type LanguageFile = t.TypeOf<typeof LanguageFile>
export type LanguageFiles = t.TypeOf<typeof LanguageFiles>
export type Phrase = t.TypeOf<typeof Phrase>
export type Languages = Record<SupportedLanguageCodes, Language>
export type Phrases = Record<SupportedLanguageCodes, Phrase[]>

export function getLanguages(): Languages {
  return {
    de: new Language(de),
    se: new Language(se),
    pt: new Language(pt),
    es: new Language(es),
    en: new Language(en),
    fr: new Language(fr),
    it: new Language(it),
    nl: new Language(nl),
    no: new Language(no),
    is: new Language(is),
  }
}

export class Language {
  private replacementString = "__*__"
  private targetLangReplacementString = "__target_lang__"
  private readonly commandRegex: {
    translate: RegExp, 
    retry: RegExp, 
    repeat: RegExp, 
    repeatSlowly: RegExp, 
  }

  constructor(
    private readonly l: LanguageFile,
  ) {
    this.commandRegex = _.mapValues(l.commands, (cmd) => new RegExp(cmd))
  }

  public readonly code = this.l.code
  public readonly localCode = this.l.localCode
  public readonly name = this.l.name
  public readonly displayName = this.l.displayName
  public readonly voices = this.l.voices
  public readonly iconURL = this.l.iconURL
  public readonly phrases = this.l.phrases
  
  private getPrompt(prompt: keyof t.TypeOf<typeof LanguagePrompts>, s?: string): string {
    if (!s) return this.l.questionPrompts[prompt][1] 
    
    return this.l.questionPrompts[prompt][0].replace(this.replacementString, `"${s}"`)
  }

  public correctPrompt(s?: string): string {
    return this.getPrompt("correct", s)
  }
  public alternativePrompt(s?: string): string {
    return this.getPrompt("alternative", s)
  }
  public meaningPrompt(s?: string): string {
    return this.getPrompt("meaning", s)
  }
  public mistakePrompt(s?: string): string {
    return this.getPrompt("mistakePrompt", s)
  }
  public intentPrompt(targetLang: string, s?: string): string {
    return this.getPrompt("intentPrompt", s).replaceAll(this.targetLangReplacementString, targetLang)
  }

  public matchesCommand(s?: string): 
      { cmd: Exclude<ChatMessageCommand, 'command-translate'> } 
    | { cmd: "command-translate", msg: string } 
    |  null {
      if (!s) return null

      const formattedText = s.toLowerCase().replace(/[.,/#¡!$%^&*;:{}=\-_`~()]/g, "");
      if (formattedText == this.l.commands.repeat.toLowerCase()) return { cmd: ChatMessageCommands.COMMAND_REPEAT }
      if (formattedText == this.l.commands.repeatSlowly.toLowerCase()) return { cmd: ChatMessageCommands.COMMAND_REPEAT_SLOWLY }
      if (formattedText == this.l.commands.retry.toLowerCase()) return { cmd: ChatMessageCommands.COMMAND_RETRY }
      const matches = this.commandRegex.translate.exec(formattedText)
      if (matches && matches.length > 1) {
        return { cmd: ChatMessageCommands.COMMAND_TRANSLATE, msg: matches[1] }
      }
      return null
  }
}
