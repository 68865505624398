import {SupportedLanguageCodes} from 'interfaces/SupportedLanguages'
import _ from 'lodash'
import {useState} from 'react'
import {SelectDropdown} from '../components/SelectDropdown'
import { getLanguages } from '../helpers/language'

type LanguageDropdownItem = {
  id: SupportedLanguageCodes,
  name: string,
  avatar: string, // image url
}

type LanguagePickerProps = {
  onChange: (languageCode: SupportedLanguageCodes) => void
  title?: string,
  languageCodes?: SupportedLanguageCodes[]
  startingLanguage?: SupportedLanguageCodes
}
export function LanguagePicker(props: LanguagePickerProps) {
  const { onChange, startingLanguage, languageCodes, title} = props
  const languages = getLanguages()

  const langs = languageCodes 
    ? _.values(languages).filter(sl => languageCodes.includes(sl.code))
    : _.values(languages)

  const startingIndex = startingLanguage
    ? Math.max(0, langs.findIndex((l) => l.code === startingLanguage))
    : 0

  const dropdownItems: LanguageDropdownItem[] = langs
    .map(l => ({id: l.code, name: l.name, avatar: l.iconURL}))
  const [selected, setSelected] = useState<LanguageDropdownItem>(dropdownItems[startingIndex])

  const onSelect = async (item: LanguageDropdownItem) => {
    if (!selected || !selected.id) return;
    setSelected(item)
    onChange(item.id)
  }

  return <SelectDropdown<LanguageDropdownItem>
    title={title || 'Target Language'}
    options={dropdownItems}
    selected={selected}
    setSelected={onSelect} 
  />
}

