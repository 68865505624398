import {LanguageFile} from "../helpers/language";

export const fr: LanguageFile = {
  "code": "fr",
  "localCode": "fr-FR",
  "name": "french",
  "displayName": "French",
  "voices": ["fr-FR-Standard-A"],
  "iconURL": "fr.png",
  "phrases": [
    {"phrase": "About myself", "targetLanguagePhrase": "Je voudrais parler de moi.", "emoji": "🧑"},
    {"phrase": "Favorite French dish", "targetLanguagePhrase": "Je voudrais parler de mon plat français préféré.", "emoji": "🥘"},
    {"phrase": "About my pet", "targetLanguagePhrase": "Je voudrais parler de mon animal de compagnie.", "emoji": "🐾"},
    {"phrase": "Favorite season", "targetLanguagePhrase": "Je voudrais parler de ma saison préférée.", "emoji": "🌦"},
    {"phrase": "About music", "targetLanguagePhrase": "Je voudrais parler de musique.", "emoji": "🎵"},
    {"phrase": "Favorite book", "targetLanguagePhrase": "Je voudrais parler de mon livre préféré.", "emoji": "📚"},
    {"phrase": "Dream vacation", "targetLanguagePhrase": "Je voudrais parler de mes vacances de rêve.", "emoji": "🌴"},
    {"phrase": "About sports", "targetLanguagePhrase": "Je voudrais parler de sport.", "emoji": "⚽"},
    {"phrase": "About a film I've recently watched", "targetLanguagePhrase": "Je voudrais parler d'un film que j'ai récemment vu.", "emoji": "🎬"},
    {"phrase": "Favorite type of coffee", "targetLanguagePhrase": "Je voudrais parler de mon type de café préféré.", "emoji": "☕"},
    {"phrase": "About my hobbies", "targetLanguagePhrase": "Je voudrais parler de mes passe-temps.", "emoji": "🎨"},
    {"phrase": "About my job", "targetLanguagePhrase": "Je voudrais parler de mon travail.", "emoji": "💼"},
    {"phrase": "About gardening", "targetLanguagePhrase": "Je voudrais parler de jardinage.", "emoji": "🌿"},
    {"phrase": "About fashion", "targetLanguagePhrase": "Je voudrais parler de mode.", "emoji": "👗"},
    {"phrase": "Favorite childhood memory", "targetLanguagePhrase": "Je voudrais parler de mon souvenir d'enfance préféré.", "emoji": "🧸"},
    {"phrase": "About technology", "targetLanguagePhrase": "Je voudrais parler de technologie.", "emoji": "💻"},
    {"phrase": "About my favorite city", "targetLanguagePhrase": "Je voudrais parler de ma ville préférée.", "emoji": "🏙"},
    {"phrase": "About my favorite car", "targetLanguagePhrase": "Je voudrais parler de ma voiture préférée.", "emoji": "🚗"},
    {"phrase": "About sustainability", "targetLanguagePhrase": "Je voudrais parler de durabilité.", "emoji": "🌍"},
    {"phrase": "About baking", "targetLanguagePhrase": "Je voudrais parler de pâtisserie.", "emoji": "🧁"},
    {"phrase": "Favorite workout routine", "targetLanguagePhrase": "Je voudrais parler de ma routine d'entraînement préférée.", "emoji": "🏋️"},
    {"phrase": "About photography", "targetLanguagePhrase": "Je voudrais parler de photographie.", "emoji": "📸"},
    {"phrase": "About my favorite video game", "targetLanguagePhrase": "Je voudrais parler de mon jeu vidéo préféré.", "emoji": "🎮"},
    {"phrase": "About a concert I've attended", "targetLanguagePhrase": "Je voudrais parler d'un concert auquel j'ai assisté.", "emoji": "🎤"},
    {"phrase": "About a hiking trip", "targetLanguagePhrase": "Je voudrais parler d'une randonnée que j'ai faite.", "emoji": "🥾"},
    {"phrase": "About my favorite cocktail", "targetLanguagePhrase": "Je voudrais parler de mon cocktail préféré.", "emoji": "🍹"},
    {"phrase": "About astronomy", "targetLanguagePhrase": "Je voudrais parler d'astronomie.", "emoji": "🌌"},
    {"phrase": "About an inspiring person in my life", "targetLanguagePhrase": "Je voudrais parler d'une personne qui m'inspire dans ma vie.", "emoji": "💫"},
    {"phrase": "About a language I'm learning", "targetLanguagePhrase": "Je voudrais parler d'une langue que j'apprends.", "emoji": "🌐"},
    {"phrase": "About my favorite piece of art", "targetLanguagePhrase": "Je voudrais parler de mon œuvre d'art préférée.", "emoji": "🎨"}
  ],
  "questionPrompts": {
    "meaning": ["Que signifie __*__?", "Qu'est-ce que ça signifie?"],
    "correct": ["Est-ce que __*__ est correct?", "Est-ce correct?"],
    "alternative": ["Vouliez-vous dire autre chose que __*__?", "Vouliez-vous dire autre chose?"],
    "intentPrompt": ["Qu'essayiez-vous de dire à la place de __*__? Je vous dirai comment le dire en __target_lang__.", "Qu'essayiez-vous de dire? Je vous dirai comment le dire en __target_lang__."],
    "mistakePrompt": ["Quelles sont les erreurs dans la phrase __*__", "Quelles sont les erreurs dans cette phrase?"]
  },
  "commands": {
    "translate": "^comment dit-on (.*)\\?",
    "repeat": "répète ça",
    "repeatSlowly": "dis ça lentement",
    "retry": "reviens en arrière"
  }
}
