import React, { FC } from 'react';
import { flattenOverlappingSeries } from '../helpers/series';
import {wordsMatch} from '../helpers/words';

type StyledMatch = {
  matches: string[];
  component: React.ComponentType<any>;
}

interface Props {
  text: string;
  styledMatches: StyledMatch[]
}

type Match = {
  start: number;
  end: number;
  text: string;
  component: React.ComponentType<any>;
}    

// splits a sentence into words but provides the indexes of the characters
const splitWords = (text: string): { index: number, word: string }[] => {
  const words = text.split(' ');
  const indices: { index: number, word: string }[] = [];
  let index = 0;
  words.forEach(word => {
    indices.push({ index, word });
    index += word.length + 1;
  });
  return indices;
}

// returns the start and end offset of the search string in the text, only matches whole words
const findAllIndices = (text: string, search: string): [number, number][] => {
  const split = splitWords(text);
  return split.flatMap(({ index, word }, wordIndex) => {
    // acount for multiword match
    const searchWords = search.split(' ')
    if (searchWords.length > 1) {
      const multiword = split.map(({ word }) => word).slice(wordIndex, wordIndex + searchWords.length).join(' ');
      if (wordsMatch(multiword, search)) {
        return [[index, index+multiword.length]];
      }
    }
    if (wordsMatch(word, search)) {
      return [[index, index+word.length]];
    }
    return [];
  });
}

const makeMatches = (indices: [number, number][], text: string, component: React.ComponentType<any>) => {
  return indices.map(([start, end]) => ({
    start,
    end,
    text: text.slice(start, end),
    component
  }));
}

const Highlighter: FC<Props> = ({ text, styledMatches }) => {
  const matches: Match[] = styledMatches.flatMap(sm => {

    const ms = sm.matches
      .flatMap(textToMatch => makeMatches(findAllIndices(text, textToMatch), text, sm.component))
      .filter(h => h.start >= 0)
      .sort((a, b) => a.start - b.start);

    return flattenOverlappingSeries(
      ms,
      {
        getSeriesFromItem: (m: Match) => [m.start, m.end],
        mapToNewItem: (a: Match, b: Match, s: number, e: number) => ({ 
          ...a, text: a.text.length >= b.text.length ? a.text : b.text, 
          start: s, 
          end: e 
        })
      }
    );
  })

  const result = [];
  let currentBuffer = '';

  let i = 0;
  while (i < text.length) {
    // if we find a match, add the buffer to the results unless the buffer is empty
    const match = matches.find(m => m.start === i);

    if (match) {
      if (currentBuffer.length > 0) {
        result.push(<span key={i}>{currentBuffer}</span>);
        currentBuffer = '';
      }
      result.push(<match.component key={text + i}>{match.text}</match.component>);
      i = match.end;
    } else {
      currentBuffer += text[i];
      i++;
    }
  }

  if (currentBuffer.length > 0) {
    result.push(<span key={text.length}>{currentBuffer}</span>)
  }

  return <>{result}</>;
}

export default Highlighter;

