import { FC, PropsWithChildren } from "react"

export type CornerMountedProps = {
    pos?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'
}
export const CornerMounted: FC<PropsWithChildren<CornerMountedProps>> = ({ pos = 'top-right', children }) => {
    const vertical = pos === 'top-left' || pos === 'top-right'
        ? 'top'
        : 'bottom'
    const horizontal = pos === 'top-left' || pos === 'bottom-left'
        ? 'left'
        : 'right'

    return <>
        <span className="sr-only -bottom-2 -top-2 -left-2 -right-2">Notifications</span> {/* classes just to load for tailwind */}
        <div className={`
      absolute 
      -${vertical}-2 -${horizontal}-2 
    `}>
        { children }
    </div>
    </>
}