import {useAuth} from "../contexts/AuthContext"
import {ChatMessageCommand} from "../types/ChatMessage"

export interface TranscriptionResult {
  type: 'command' | 'message' | 'error'
  command: ChatMessageCommand | null
}

export class TranscriptionResultError {
  public readonly type = 'error'
  public readonly command = null
  constructor(public readonly e: Error) { }
}

export class TranscriptionResultMessage {
  public readonly type = 'message'
  public readonly command = null
  constructor(public readonly message: string) { }
}

export class TranscriptionResultTranslationCommand {
  public readonly type = 'command'
  public readonly command = 'command-translate'
  constructor(public readonly original: string) { }
}

class TranscriptionResultCommand {
  public readonly type = 'command'
  constructor(public readonly command: "command-repeat" | "command-retry" | "command-repeat-slowly") { }
}

export function useTranscriber() {
  const { user, loadingUser } = useAuth();
  
  async function transcribeSpeechToText(voiceBlob: Blob): Promise<string> {
    // for mp4 type files, convert to wav
    const transcriptionEndpoint = voiceBlob.type === 'audio/mp4' || voiceBlob.type === 'audio/m4a' 
      ? '/.netlify/functions/speak-wav'
      : '/api/speak' 

    const response = await fetch(transcriptionEndpoint, {
      method: 'POST',
      body: voiceBlob,
      headers: {
        'Content-Type': voiceBlob.type, // Adjust the content type according to your audio file format
      },
    })

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();

    return data.text
  }

  function extractCommand(text: string): TranscriptionResult {
    if (loadingUser) throw new Error("user must be logged in")
    const { userLanguage } = user;
    const cmdResponse = userLanguage.matchesCommand(text)
    if (!cmdResponse) return new TranscriptionResultMessage(text)

    const { cmd } = cmdResponse

    switch (cmd) {
      case 'command-retry':
        return new TranscriptionResultCommand("command-retry")
      case 'command-repeat':
        return new TranscriptionResultCommand("command-repeat")
      case 'command-repeat-slowly':
        return new TranscriptionResultCommand("command-repeat-slowly")
      case 'command-translate':
        const { msg } = cmdResponse
        return new TranscriptionResultTranslationCommand(msg)
    }
  }

  async function transcribe(voiceBlob: Blob): Promise<TranscriptionResult> {
    try {
      const text = await transcribeSpeechToText(voiceBlob)

      return extractCommand(text)
    } catch (e: any) {
      return new TranscriptionResultError(e)
    }
  }

  return { transcribe }
}
