import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type Props = {} & React.ButtonHTMLAttributes<HTMLButtonElement>

export const CloseButton: React.FC<Props> = ({ ...props }) => {
  return <button className="inline-flex items-center justify-center w-10 h-10 mr-2 text-gray-700 bg-white rounded-full transition-colors duration-150 focus:shadow-outline hover:bg-gray-200"
            {...props}
          >
    <FontAwesomeIcon icon={faXmark} />
  </button>
}
