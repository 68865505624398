import AnimatedNumbers from "react-animated-numbers"
import {faPersonChalkboard, faLanguage} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FC, PropsWithChildren, useEffect, useState} from "react";
import {useVocabularyClient} from "../clients/vocabulary";
import {useLessonClient} from "../clients/lesson";
import {Button} from "../components/Button";
import {useNavigate} from "react-router-dom"
import {links} from "../constants/links";
import {LoadingWrapper} from "../components/LoadingWrapper";
import {WeeklyStreak} from "../components/WeeklyStreak";
import {HistoricalLesson} from "./History";
import {useError} from "../contexts/ErrorContext";

const lastMonday = new Date();
lastMonday.setDate(lastMonday.getDate() - ((lastMonday.getDay() + 6) % 7)); // Get last Monday
lastMonday.setHours(0, 0, 0, 0); // Set time to 00:00:00.000

const Section: FC<PropsWithChildren> = ({ children }) => {
    return <div className={`
      w-full max-w-sm 
      p-4 sm:p-6 
      my-4
      mx-auto 
      bg-white 
      border border-gray-200 rounded-lg 
      shadow 
      dark:bg-gray-700 dark:border-gray-600`}>
      {children}
    </div>
}

export default function Progress() {
  const [loading, setLoading] = useState(true)
  const { setError } = useError()
  const [vocabularyCount, setVocabularyCount] = useState<number>(0)
  const [lessonCount, setLessonCount] = useState<number>(0)
  const [weekLessons, setWeekLessons] = useState<HistoricalLesson[]>([])
  const vocabularyClient = useVocabularyClient()
  const lessonClient = useLessonClient()
  const navigate = useNavigate()

  useEffect(() => {
    const loadCountData = async () => {
      try {
        const count = await vocabularyClient.count()
        const lessons = await lessonClient.list()
        setVocabularyCount(count)
        setLessonCount(lessons.filter(l => l.finishedAt).length)
        setWeekLessons(lessons
          .filter(({finishedAt}) => finishedAt >= lastMonday)
          .sort((a,b) => a.finishedAt.getTime() - b.finishedAt.getTime())
          .reduce((acc: HistoricalLesson[], curr: HistoricalLesson) => { 
            return acc.some(l => l.finishedAt.getDay() === curr.finishedAt.getDay()) 
              ? acc
              : [...acc, curr] 
          }, [])
        )
        setError(null)
      } catch (e) {
        setVocabularyCount(0)
        setError(new Error('Error loading progress'))
      }
      setLoading(false)
    }

    loadCountData()
  }, [])

  return (
    <LoadingWrapper loading={loading} inverse>
      <section className="flex flex-col justify-center h-full isolate bg-main">

        {/* start lesson section */}
        <Section>
          <h5 className="mb-3 text-base font-semibold text-gray-900 md:text-xl dark:text-white">
            Start a new lesson
          </h5>
          <p className="text-sm font-normal text-gray-500 dark:text-gray-200">
            Continue your streak and start a new lesson.
          </p>
          <div className="flex justify-center pt-4">
            <Button 
              primary
              onClick={() => navigate(links.CHAT)}
              stretch={false}
            >
              Start Lesson
            </Button>
          </div>
        </Section>

        {/* streak section */}
        <Section>
            <h5 className="mb-3 text-base font-semibold text-gray-900 md:text-xl dark:text-white">
              Practice Streak
            </h5>
            <p className="text-sm font-normal text-gray-500 dark:text-gray-200">
              you've practiced 
              {" "}
              <span className="font-bold text-main">
                {weekLessons.length}/7
              </span>
              {" "}
              days this week{weekLessons.length > 1 ? "!" : "."}
            </p>
            <div className="flex flex-col items-center pt-6">
              <WeeklyStreak lessons={weekLessons}/>
            </div>
        </Section>

        {/* progress section */}
        <Section>
            <h5 className="mb-3 text-base font-semibold text-gray-900 md:text-xl dark:text-white">
              Your Progress
            </h5>
            <p className="text-sm font-normal text-gray-500 dark:text-gray-200">
              See how much progress you've made since you started using Lingful. 
            </p>
            <div className="pt-4 text-2xl text-center sm:px-0 text-main">
              <FontAwesomeIcon icon={faLanguage} />
            </div>
            <div className="flex items-center justify-center p-2">
                <span className="text-main">
                  <AnimatedNumbers
                    animateToNumber={vocabularyCount}
                    fontStyle={{ fontSize: 32 }}
                    transitions={(index) => ({
                        type: "spring",
                        duration: index + 0.3,
                    })}
                  ></AnimatedNumbers>
                </span>

                <p className="px-2 text-xl dark:text-gray-100">new words learned!</p>
            </div>
            <div className="py-2">
              <Button 
               inverse 
                onClick={() => navigate(links.VOCABULARY)}
              >
                View Vocabulary
              </Button>
            </div>
            
            <div className="flex justify-center pt-4 sm:px-0">
              <span className="flex flex-row text-2xl text-main">
                <FontAwesomeIcon icon={faPersonChalkboard} />
              </span>
            </div>
            <div className="flex items-center justify-center p-2">
                <span className="text-main">
                  <AnimatedNumbers
                    animateToNumber={lessonCount}
                    fontStyle={{ fontSize: 32 }}
                  />
                </span>

                <p className="px-2 text-xl dark:text-gray-100">lessons completed!</p>
            </div>
            <Button 
              inverse
              onClick={() => navigate(links.HISTORY)}
            >
              View Lessons
            </Button>
        </Section>
      </section>
    </LoadingWrapper>
  )
}

