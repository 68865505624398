import { useState} from "react";
import {useLesson} from '../contexts/LessonContext';
import {useConfirm} from '../hooks/use-confirm';
import {useNavigate} from 'react-router-dom';
import {DropDownDots} from '../components/DropDownDots';
import Modal from 'react-modal'
import {ThreadChat} from '../components/ThreadChat';
import {ChatInput} from '../components/ChatInput';
import {ChatBody} from "../components/ChatBody";
import {ChatMessageT} from "../types/ChatMessage";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGear } from "@fortawesome/free-solid-svg-icons";
import {DDMenuItem, DropDownMenu} from "../components/DropDownMenu";
import {VocabularyDropDown} from "../components/VocabularyDropDown";

export default function Chat() {
  const [threadIsOpen, setThreadIsOpen] = useState(false);
  const [threadMessage, setThreadMessage] = useState<ChatMessageT | null>(null);

  const navigate = useNavigate();
  const { endLesson, getChat, getVocabulary, isCalculating } = useLesson();
  const chat = getChat()

  const [ConfirmEndLesson, confirmEndLesson] = useConfirm(
    { 
      title: 'End Lesson', 
      text: "Are you sure you want to end the lesson?", 
      cancelButtonText: `No, let's keep going!`, 
      confirmButtonText: `Yes, I'm done.` 
    }
  )

  // This hook is responsible for managing the chat and communicating with the
  // backend
  const { 
    currentChat,
		chatHistory,
		sendMessage,
		cancel,
		state,
		toggleMessageTranslation,
		readAloud,
    stopAllAudio,
    removeTo,
  } = chat;

  const finish = async function() {
    const confirmed = await confirmEndLesson()
    if (confirmed) {
      const id = await endLesson();
      navigate(`/summary/${id}`)
    }
  }

  const startThread = (msg: ChatMessageT) => {
    setThreadMessage(msg)
    setThreadIsOpen(true)
  }

  const closeThread = () => {
    stopAllAudio();
    setThreadMessage(null)
    setThreadIsOpen(false)
  }

  const vocabulary = getVocabulary()
  const reviewedVocabulary = vocabulary.filter(v => v.reviewed)
  const addedDuringLessonVocabulary = vocabulary.filter(v => v.addedDuringLesson)

  return (
    <div className={'flex flex-col h-full'} id="#chat-container">
      <Modal
        isOpen={isCalculating}
        shouldCloseOnOverlayClick={false}
        onRequestClose={() => closeThread()}
        overlayClassName={'Overlay'}
      >
        <div className={`w-full h-full flex flex-col items-center justify-center bg-main text-white`}>
          <h2 className={`
            text-white text-xl leading-tight tracking-loose font-bold 
            p-2
          `}>
            Analysing Lesson... 
          </h2>
          <div className={`p-2`}>
            <FontAwesomeIcon icon={faGear} spin /> 
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={threadIsOpen}
        contentLabel="Thread Chat Modal"
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => closeThread()}
        closeTimeoutMS={200}
        className="Modal"
        overlayClassName={`Overlay`}
      >
          {threadMessage && <ThreadChat msg={threadMessage} /> }
      </Modal>
      <ConfirmEndLesson />
      <main className="container flex flex-col flex-grow w-full p-6 mx-auto bg-white md:rounded-lg md:shadow-md dark:bg-secondary">
        <div className='sticky top-0 z-10 pb-4'>
          <div className="flex flex-row justify-between pt-2">
            <VocabularyDropDown 
              vocabulary={reviewedVocabulary.concat(addedDuringLessonVocabulary)}
            />
            <DropDownMenu position='bottomLeft' renderButton={({ onClick, buttonRef }) => <DropDownDots ref={buttonRef} onClick={onClick} /> }>
              {toggle => [
                  <DDMenuItem onClick={() => { navigate('/settings'); toggle()} } key={'preferences'}>Preferences</DDMenuItem>,
                  <DDMenuItem onClick={() => { finish(); toggle()} } danger key={'endlesson'}>End Lesson</DDMenuItem>,
              ] }
            </DropDownMenu>
          </div>
        </div>
        <ChatBody
          state={state}
          currentChat={currentChat}
          chatHistory={chatHistory}
          cancel={cancel}
          onMessageClick={startThread}
          vocabulary={reviewedVocabulary.concat(addedDuringLessonVocabulary)}
          toggleMessageTranslation={toggleMessageTranslation}
          readAloud={readAloud}
          removeTo={removeTo}
        />
      </main>
      <section className="fixed bottom-0 w-screen p-2 bg-main">
        <ChatInput 
          sendMessage={sendMessage}
          stopAllAudio={stopAllAudio}
          isEnabled={state === 'idle'}
        />
      </section>
    </div>
  );
}
