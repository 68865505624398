import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { IconButton } from "./IconButton";

type MessageTextInputProps = {
  inputRef: React.Ref<HTMLInputElement>,
  isEnabled: boolean,
  sendMessage: (message: string) => void,
  value: string,
  onChange: (text: string) => void,  // New prop for input change
  isTranscribing: boolean,  // New prop to track transcription state
}

const MessageTextInput = function(props: MessageTextInputProps) {
  const { inputRef, isEnabled, sendMessage, value, onChange, isTranscribing } = props;
  const canSend = isEnabled && value !== "";

  return <>
    <form
      className="flex flex-row w-full"
      onSubmit={(e) => {
        e.preventDefault();
        sendMessage(value);
        onChange("");  // Clear input after sending
      }}
    >
      <div className="relative w-full">
        <input
          type="text"
          ref={inputRef}
          className="w-full px-2 py-2 rounded-l-lg outline-none"
          placeholder={isEnabled ? "Type your message..." : "..."}
          value={value}  // Controlled input
          onChange={(e) => onChange(e.target.value)}  // Update on change
          disabled={!isEnabled}
        />
        {isTranscribing && (
          <div className="absolute top-1/2 right-3 transform -translate-y-1/2">
            <div className="w-4 h-4 border-2 border-gray-300 border-t-transparent rounded-full animate-spin"></div>
          </div>
        )}
      </div>
      <IconButton
        circle
        type="submit"
        disabled={!canSend}
        style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
        icon={faPaperPlane}
      ></IconButton>
    </form>
  </>
}

export { MessageTextInput }

