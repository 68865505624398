import { fetchEventSource } from "@fortaine/fetch-event-source";
import { useState, useMemo, useEffect } from "react";
import crypto from 'crypto-js'
import { appConfig } from "../config.browser";
import _ from 'lodash';
import {useAuth} from "../contexts/AuthContext";
import {AssistantMessageLessonEvent, LessonEvent, RepetitionRequestLessonEvent, RetryRequestLessonEvent, TranslationRequestLessonEvent, UserMessageLessonEvent, VocabularyRequestLessonEvent} from "../types/LessonEvent";
import {TranscriptionResult, TranscriptionResultError, TranscriptionResultMessage, TranscriptionResultTranslationCommand, useTranscriber} from "./use-transcriber";
import {TextToSpeechClient} from "../clients/text-to-speech";
import {TranslationClient} from "../clients/translate";
import {ChatMessageT, ChatMessageSource, newChatMessage, newChatCommand, ChatMessageCommands, ChatMessageRoles } from "../types/ChatMessage";
import {VocabularyDTO} from "interfaces/Vocabulary";
import {useAudio} from "../contexts/AudioContext";

const API_PATH = "/api/chat";

type utterance = { url: string }
type utteranceBySpeed = Record<'slow'|'normal', utterance>
type utteranceCache = Record<string, utteranceBySpeed> 

export type ChatHook = ReturnType<typeof useChat>
export type ChatState ="idle" | "transcribing" | "waiting" | "loading" 

export interface UseChatOptions {
  addEvent?: (e: LessonEvent) => void,
  getVocabulary?: () => VocabularyDTO[],
  prompt?: string,
}

/**
 * A custom hook to handle the chat state and logic
 */
export function useChat(opts?: UseChatOptions) {
  const {
    addEvent = (e: LessonEvent) => {},
    getVocabulary = () => [],
    prompt,
  } = opts || {};

  const [utterances, setUtternaces] = useState<utteranceCache>({})
  const [currentChat, setCurrentChat] = useState<string | null>(null);
  const [chatHistory, setChatHistory] = useState<ChatMessageT[]>([]);
  const [state, setState] = useState<ChatState>("idle");
  const { audioPlayer } = useAudio();
  const { resetQueue, stopAll } = audioPlayer
  const { user } = useAuth()
  const { transcribe } = useTranscriber()

  const targetLanguage = user?.targetLanguage 
  const userLanguageLevel = user?.languages.find(l => l.languageCode === targetLanguage?.code)?.level

  // chatbot will reply any time there is a user message posted to chat
  useEffect(() => {
    if (state !== 'idle') return;
    if (chatHistory.length < 1) return;

    if (chatHistory[chatHistory.length - 1].role === 'user') {
      getReply()
    }
  }, [chatHistory, state])

  // Lets us cancel the stream
  const abortController = useMemo(() => new AbortController(), []);

  const ensureReady = () => {
    if (!user) throw Error("user must be logged in")
  }

  // takes a buffer array and plays it for user
  function speak(arrayBuffer: {data: Array<any>, type: "buffer"}): string {
    const audioData = new Uint8Array(arrayBuffer.data);
    const blob = new Blob([audioData.buffer],{type:'audio/mp3'});
    const url = URL.createObjectURL(blob)
    resetQueue([url])
    return url
  }

  function findAndUpdate<T>(arr: T[], identifier: (t: T) => boolean, modifier: (t: T) => T) {
    const i = arr.findIndex(identifier)
    return arr.slice(0, i)
      .concat(modifier(arr[i]))
      .concat(arr.slice(i+1))
  }

  async function toggleMessageTranslation(message: ChatMessageT) {
    if (!user) throw Error("user must be logged in")
    if (!targetLanguage) throw Error("user must have target language")

    if (!message.translation) {
      setChatHistory(chatHistory => findAndUpdate(chatHistory, 
        (msg) => msg.id === message.id, 
        (msg) => ({...msg, translationLoading: true})
      ))
    }
    const translation = message.translation || await TranslationClient.translate(
      message.content, 
      user.userLanguageCode, 
      user.UID!, 
      targetLanguage.code
    )
    addEvent(new TranslationRequestLessonEvent(new Date(), {
      messageId: message.id.toISOString(),
      phrase: message.content,
    }, {
      userLanguagePhrase: translation,
      targetLanguagePhrase: message.content,
    }))
    setChatHistory(chatHistory => findAndUpdate(chatHistory, 
      (msg) => msg.id === message.id, 
      (msg) => ({...msg, 
        translationEnabled: !msg.translationEnabled, 
        translationLoading: false, 
        translation 
      }),
    ))
  }

  async function readAloud(text: string, speed: 'slow' | 'normal' = 'normal') {
    if (!user) throw Error("user must be logged in")
    if (!targetLanguage) throw Error("user must have target language")

    if (text.includes(" = ")) text = text.split(" = ")[1]

    const hash = crypto.SHA1(text).toString()
    if (_.has(utterances, `${hash}.${speed}.url`)) {
       resetQueue([utterances[hash][speed].url])
      return;
    }
  
    let audioData: any;
    switch(speed) {
      case 'slow':
        audioData = await TextToSpeechClient.speak(text, targetLanguage, { speed: 0.7 })
        break;
      case 'normal':
        audioData = await TextToSpeechClient.speak(text, targetLanguage, { speed: 1 })
        break;
    }

    const url = speak(audioData)
    setUtternaces(utterances => _.setWith(utterances, `${hash}.${speed}.url`, url, _.clone))
  }

  async function checkForErrors(chatMessage: ChatMessageT): Promise<boolean> {
      const response = await fetch('/.netlify/functions/has-errors', {
        method: 'POST',
        body: JSON.stringify({ text: chatMessage.content })
      });

      const hasErrors = await response.json()

      console.log(hasErrors)

      return hasErrors
  }

  /**
   * Cancels the current chat and adds the current chat to the history
   */
  function cancel() {
    setState("idle");
    abortController.abort();
    if (currentChat) {
      setChatHistory(history => [
        ...history,
        newChatMessage(currentChat, ChatMessageRoles.ASSISTANT),
      ]);
      setCurrentChat("");
    }
  }

  /**
   * Clears the chat history
   */
  function clear() {
    setChatHistory([]);
  }
  
  /**
   * Overwrites the chat history with a new history
   */
  function overwriteHistory(history: ChatMessageT[]) {
    setCurrentChat(null)
    setChatHistory(history);
  }

  function getLastMessageOrTranslation(): ChatMessageT {
    const relevantMessages = chatHistory.filter(msg => msg.role === ChatMessageRoles.ASSISTANT || msg.role === ChatMessageCommands.COMMAND_TRANSLATE)
    return relevantMessages[relevantMessages.length-1]
  }

  async function repeat(msg: ChatMessageT, speed: 'normal' | 'slow' = 'normal') {
    // fire and forget
    addEvent(new RepetitionRequestLessonEvent(
      new Date(),
      { phrase: msg.content, messageId: msg.id.toISOString()},
      { speed },
    ))
    await readAloud(msg.content, speed)
    setState('idle')
  }

  async function repeatSlowly(msg: ChatMessageT) {
    // fire and forget
    addEvent(new RepetitionRequestLessonEvent(
      new Date(),
      { phrase: msg.content, messageId: msg.id.toISOString()},
      { speed: 'slow' },
    ))
    await readAloud(msg.content, 'slow')
    setState('idle')
  }

  async function removeTo(msg: ChatMessageT) {
    const indexAtLastUserMessage = chatHistory.findIndex(c => c.id.toISOString() === msg.id.toISOString())

    const messagesAfterReset = chatHistory
      .slice(indexAtLastUserMessage)
    addEvent(new RetryRequestLessonEvent(messagesAfterReset.map(m => ({
      messageId: m.id.toISOString(),
      phrase: m.content,
    })), new Date()))

    setChatHistory(chatHistory => {
      return chatHistory.slice(0, indexAtLastUserMessage)
    })
  }

  function getLastUserMessage(): ChatMessageT {
    const indexAtLastUserMessage = chatHistory.findLastIndex(msg => msg.role === ChatMessageRoles.USER)
    if (indexAtLastUserMessage < 0) throw Error("cannot remove last message because message does not exist") 

    return chatHistory[indexAtLastUserMessage]
  }

  async function removeLastUserAssistantChatPair() {
    const lastUserMessage = getLastUserMessage()
    removeTo(lastUserMessage)
  }
  
  async function addTranslateCommand(phrase: string) {
    if (!user) throw Error("user must be logged in")
    if (!targetLanguage) throw Error("user must have target language")

    setState('transcribing')
    const translation = await TranslationClient.translate(phrase, targetLanguage.code, user.UID, user.userLanguageCode)
    const newMessage = newChatCommand(ChatMessageCommands.COMMAND_TRANSLATE, { 
      content:`"${phrase}" = "${translation}"`
    })
    setChatHistory(chatHistory => [...chatHistory, newMessage]) 
    readAloud(translation)
    addEvent(new VocabularyRequestLessonEvent(newMessage.id, {
      userLanguagePhrase: phrase,
      targetLanguagePhrase: translation,
    }))
    setState('idle')
  }

  async function sendAudio(blob: Blob) {
    setState("transcribing");
    const t: TranscriptionResult = await transcribe(blob)
    if (t.command) {
      handleCommand(t)
      setState("idle")
      return;
    } else if (t.type === 'message') {
      const msg = (t as TranscriptionResultMessage).message
      setState("idle")
      return sendMessage(msg, 'audio')
    } else if (t.type === 'error') {
      const err = (t as TranscriptionResultError).e 
      console.error(err)
      setState("idle")
      return;
    }
  }

  async function handleCommand(command: TranscriptionResult) {
    switch (command.command) {
      case "command-retry":
          setState("idle")
          removeLastUserAssistantChatPair()
          setChatHistory(chatHistory => [...chatHistory, newChatCommand(ChatMessageCommands.COMMAND_RETRY)])
          break
      case "command-repeat":
          setState("idle")
          setChatHistory(chatHistory => [...chatHistory, newChatCommand(ChatMessageCommands.COMMAND_REPEAT)])
          return repeat(getLastMessageOrTranslation())
      case "command-repeat-slowly":
          setState("idle")
          setChatHistory(chatHistory => [...chatHistory, newChatCommand(ChatMessageCommands.COMMAND_REPEAT_SLOWLY)])
          return repeatSlowly(getLastMessageOrTranslation())
      case "command-translate":
        const message = (command as TranscriptionResultTranslationCommand).original
        setState("idle")
        addTranslateCommand(message)
    }
  }

  async function getReply() {
    if (!user) throw Error("user must be logged in")
    if (!targetLanguage) throw Error("user must have target language")

    setState("waiting");
    let chatContent = ""
    const body = JSON.stringify({
      // optionally override the default prompt
      prompt,
      // Only send the most recent messages. This is also
      // done in the serverless function, but we do it here
      // to avoid sending too much data
      messages: chatHistory
        .filter(msg => [ChatMessageRoles.USER, ChatMessageRoles.ASSISTANT].some(role => role === msg.role))
        .map(msg => ({content: msg.content, role: msg.role }))
        .slice(-appConfig.historyLength),
    });

    // This is like an EventSource, but allows things like
    // POST requests and headers
    fetchEventSource(API_PATH + '?' + new URLSearchParams({
      lang: targetLanguage.name,
      ...(userLanguageLevel ? {level: userLanguageLevel} : {}),
      vocabulary: getVocabulary()
        .filter(v => v.enabled)
        .map(v => v.text)
        .slice(0, 20)
        .join(","),
    }), {
      body,
      method: "POST",
      signal: abortController.signal,
      onclose: () => {
        setState("idle");
      },
      onmessage: (event) => {
        switch (event.event) {
          case "delta": {
            // This is a new word or chunk from the AI
            setState("loading");
            const message = JSON.parse(event.data);
            if (message?.role === ChatMessageRoles.ASSISTANT) {
              chatContent = "";
              return;
            }
            if (message.content) {
              chatContent += message.content;
              setCurrentChat(chatContent);
            }
            break;
          }
          case "open": {
            // The stream has opened and we should recieve
            // a delta event soon. This is normally almost instant.
            setCurrentChat("...");
            break;
          }
          case "done": {
            // When it's done, we add the message to the history
            // and reset the current chat
            const newMessage = newChatMessage(chatContent, ChatMessageRoles.ASSISTANT)
            setChatHistory((curr) => [
              ...curr,
              newMessage
            ]);
            addEvent(new AssistantMessageLessonEvent({ message: newMessage.content}, newMessage.id))
            setCurrentChat(null);
            setState("idle");

            // and read aloud the message
            // Todo: read the message eagerly, perhaps on sentence breaks.
            user.autoReadEnabled && readAloud(chatContent)
            break;
          }
          default:
            break;
        }
      },
    });
  }

  const sendMessage = async (message: string, source: ChatMessageSource = 'text') => {
    const chatMessage = newChatMessage(message, ChatMessageRoles.USER, source)

    // commented out for now while I work on other features (11/05/2023)
    // checkForErrors(chatMessage) // fire and forget
    
    setChatHistory(history => [
        ...history,
        chatMessage,
    ]);
    addEvent(new UserMessageLessonEvent({ message }, chatMessage.id))
  };

  return { 
    sendMessage,
		currentChat,
		chatHistory,
		cancel,
		clear,
		state,
		toggleMessageTranslation,
		readAloud: repeat,
		stopAllAudio: stopAll,
    overwriteHistory,
    removeTo,
    speak: readAloud,
    isSpeaking: audioPlayer.isPlaying
  };
}
