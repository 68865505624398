import {LanguageFile} from "../helpers/language";

export const de: LanguageFile = {
  "code": "de",
  "localCode": "de-DE",
  "name": "german",
  "displayName": "German",
  "voices": ["de-DE-Standard-A"],
  "iconURL": "de.png",
  "phrases": [
    {"phrase": "About myself", "targetLanguagePhrase": "Ich möchte über mich selbst sprechen.", "emoji": "🧑"},
    {"phrase": "Favorite Spanish dish", "targetLanguagePhrase": "Ich möchte über mein Lieblings-Spanisches Gericht sprechen.", "emoji": "🥘"},
    {"phrase": "About my pet", "targetLanguagePhrase": "Ich möchte über mein Haustier sprechen.", "emoji": "🐾"},
    {"phrase": "Favorite season", "targetLanguagePhrase": "Ich möchte über meine Lieblingsjahreszeit sprechen.", "emoji": "🌦"},
    {"phrase": "About music", "targetLanguagePhrase": "Ich möchte über Musik sprechen.", "emoji": "🎵"},
    {"phrase": "Favorite book", "targetLanguagePhrase": "Ich möchte über mein Lieblingsbuch sprechen.", "emoji": "📚"},
    {"phrase": "Dream vacation", "targetLanguagePhrase": "Ich möchte über meinen Traumurlaub sprechen.", "emoji": "🌴"},
    {"phrase": "About sports", "targetLanguagePhrase": "Ich möchte über Sport sprechen.", "emoji": "⚽"},
    {"phrase": "About a film I've recently watched", "targetLanguagePhrase": "Ich möchte über einen Film sprechen, den ich kürzlich gesehen habe.", "emoji": "🎬"},
    {"phrase": "Favorite type of coffee", "targetLanguagePhrase": "Ich möchte über meine Lieblingskaffeesorte sprechen.", "emoji": "☕"},
    {"phrase": "About my hobbies", "targetLanguagePhrase": "Ich möchte über meine Hobbys sprechen.", "emoji": "🎨"},
    {"phrase": "About my job", "targetLanguagePhrase": "Ich möchte über meinen Beruf sprechen.", "emoji": "💼"},
    {"phrase": "About gardening", "targetLanguagePhrase": "Ich möchte über Gartenarbeit sprechen.", "emoji": "🌿"},
    {"phrase": "About fashion", "targetLanguagePhrase": "Ich möchte über Mode sprechen.", "emoji": "👗"},
    {"phrase": "Favorite childhood memory", "targetLanguagePhrase": "Ich möchte über meine liebste Kindheitserinnerung sprechen.", "emoji": "🧸"},
    {"phrase": "About technology", "targetLanguagePhrase": "Ich möchte über Technologie sprechen.", "emoji": "💻"},
    {"phrase": "About my favorite city", "targetLanguagePhrase": "Ich möchte über meine Lieblingsstadt sprechen.", "emoji": "🏙"},
    {"phrase": "About my favorite car", "targetLanguagePhrase": "Ich möchte über mein Lieblingsauto sprechen.", "emoji": "🚗"},
    {"phrase": "About sustainability", "targetLanguagePhrase": "Ich möchte über Nachhaltigkeit sprechen.", "emoji": "🌍"},
    {"phrase": "About baking", "targetLanguagePhrase": "Ich möchte über Backen sprechen.", "emoji": "🧁"},
    {"phrase": "Favorite workout routine", "targetLanguagePhrase": "Ich möchte über meine Lieblings-Trainingsroutine sprechen.", "emoji": "🏋️"},
    {"phrase": "About photography", "targetLanguagePhrase": "Ich möchte über Fotografie sprechen.", "emoji": "📸"},
    {"phrase": "About my favorite video game", "targetLanguagePhrase": "Ich möchte über mein Lieblings-Videospiel sprechen.", "emoji": "🎮"},
    {"phrase": "About a concert I've attended", "targetLanguagePhrase": "Ich möchte über ein Konzert sprechen, das ich besucht habe.", "emoji": "🎤"},
    {"phrase": "About a hiking trip", "targetLanguagePhrase": "Ich möchte über eine Wanderung sprechen, die ich gemacht habe.", "emoji": "🥾"},
    {"phrase": "About my favorite cocktail", "targetLanguagePhrase": "Ich möchte über meinen Lieblingscocktail sprechen.", "emoji": "🍹"},
    {"phrase": "About astronomy", "targetLanguagePhrase": "Ich möchte über Astronomie sprechen.", "emoji": "🌌"},
    {"phrase": "About an inspiring person in my life", "targetLanguagePhrase": "Ich möchte über eine inspirierende Person in meinem Leben sprechen.", "emoji": "💫"},
    {"phrase": "About a language I'm learning", "targetLanguagePhrase": "Ich möchte über eine Sprache sprechen, die ich lerne.", "emoji": "🌐"},
    {"phrase": "About my favorite piece of art", "targetLanguagePhrase": "Ich möchte über mein Lieblingskunstwerk sprechen.", "emoji": "🎨"}
  ],
  "questionPrompts": {
    "meaning": ["Was bedeutet __*__?", "Was bedeutet das?"],
    "correct": ["Ist __*__ richtig?", "Ist das richtig?"],
    "alternative": ["Meintest du etwas anderes als __*__?", "Meintest du etwas anderes?"],
    "intentPrompt": ["Was wollten Sie statt __*__ sagen? Ich sage Ihnen, wie man es auf __target_lang__ sagt.", "Was wollten Sie sagen? Ich sage Ihnen, wie man es auf __target_lang__ sagt."],
    "mistakePrompt": ["Was sind die Fehler im Satz __*__", "Was sind die Fehler in diesem Satz?"]
  },
  "commands": {
    "translate": "^wie sagt man (.*)\\?",
    "repeat": "sag das noch einmal",
    "repeatSlowly": "sag das langsam",
    "retry": "geh zurück"
  }
}
