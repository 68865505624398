import { useState } from 'react';
import { Howl } from 'howler';

export function useAudioPlayer() {
  const [audioPlayers, setAudioPlayers] = useState<Howl[]>([]);
  const [isPlaying, setIsPlaying] = useState(false);

  const enqueueAudio = (audioSrc: string) => {
    const sound = new Howl({
      src: [audioSrc],
      onend: handleEnded
    });

    setAudioPlayers(players => players.concat(sound));
    if (!queueEmpty()) {
      play()
    }
  }

  const resetQueue = (audioURIs: string[]) => {
    audioPlayers.forEach(player => player.unload())

    const players = audioURIs.map(uri => new Howl({
      src: [uri],
      format: 'mp3',
      onend: handleEnded
    }))
    players.forEach(player => player.play())
    setAudioPlayers(players)
    setIsPlaying(true)
  }

  const popAudioPlayer = (): Howl => {
    return audioPlayers[audioPlayers.length - 1]
  }

  const hasAudioPlayer = (): boolean => {
    return audioPlayers.length > 0
  }

  const handleEnded = () => {
    const players = audioPlayers
    setAudioPlayers(players.slice(0, -1))
    if (queueEmpty()) {
      setIsPlaying(false)
    } else {
      play()
    }
  };

  const stopAll = () => {
    audioPlayers.forEach(player => player.stop().unload())
    setAudioPlayers([])
  }

  const play = () => {
    setIsPlaying(true)
    const currentAudio = popAudioPlayer()
    currentAudio.play()
  };

  const queueEmpty = () => {
    return audioPlayers.length === 0
  }

  return { isPlaying, play, resetQueue, stopAll };
}

