import {LanguageFile} from "../helpers/language";

export const is: LanguageFile = {
  "code": "is",
  "localCode": "is-IS",
  "name": "icelandic",
  "displayName": "Icelandic",
  "voices": ["is-IS-Standard-A"],
  "iconURL": "is.png",
  "phrases": [
    {"phrase": "About myself", "targetLanguagePhrase": "Ég vildi tala um mig sjálfan.", "emoji": "🧑"},
    {"phrase": "Favorite French dish", "targetLanguagePhrase": "Ég vildi tala um uppáhalds franska réttinn minn.", "emoji": "🥘"},
    {"phrase": "About my pet", "targetLanguagePhrase": "Ég vildi tala um gæludýrið mitt.", "emoji": "🐾"},
    {"phrase": "Favorite season", "targetLanguagePhrase": "Ég vildi tala um uppáhalds árstíðina mína.", "emoji": "🌦"},
    {"phrase": "About music", "targetLanguagePhrase": "Ég vildi tala um tónlist.", "emoji": "🎵"},
    {"phrase": "Favorite book", "targetLanguagePhrase": "Ég vildi tala um uppáhalds bókina mína.", "emoji": "📚"},
    {"phrase": "Dream vacation", "targetLanguagePhrase": "Ég vildi tala um draumafriðinn minn.", "emoji": "🌴"},
    {"phrase": "About sports", "targetLanguagePhrase": "Ég vildi tala um íþróttir.", "emoji": "⚽"},
    {"phrase": "About a film I've recently watched", "targetLanguagePhrase": "Ég vildi tala um mynd sem ég sá nýlega.", "emoji": "🎬"},
    {"phrase": "Favorite type of coffee", "targetLanguagePhrase": "Ég vildi tala um uppáhalds kaffitegundina mína.", "emoji": "☕"},
    {"phrase": "About my hobbies", "targetLanguagePhrase": "Ég vildi tala um áhugamál mín.", "emoji": "🎨"},
    {"phrase": "About my job", "targetLanguagePhrase": "Ég vildi tala um starfið mitt.", "emoji": "💼"},
    {"phrase": "About gardening", "targetLanguagePhrase": "Ég vildi tala um garðyrkju.", "emoji": "🌿"},
    {"phrase": "About fashion", "targetLanguagePhrase": "Ég vildi tala um tísku.", "emoji": "👗"},
    {"phrase": "Favorite childhood memory", "targetLanguagePhrase": "Ég vildi tala um uppáhalds æskuminninguna mína.", "emoji": "🧸"},
    {"phrase": "About technology", "targetLanguagePhrase": "Ég vildi tala um tækni.", "emoji": "💻"},
    {"phrase": "About my favorite city", "targetLanguagePhrase": "Ég vildi tala um uppáhalds borgina mína.", "emoji": "🏙"},
    {"phrase": "About my favorite car", "targetLanguagePhrase": "Ég vildi tala um uppáhalds bílinn minn.", "emoji": "🚗"},
    {"phrase": "About sustainability", "targetLanguagePhrase": "Ég vildi tala um sjálfbærni.", "emoji": "🌍"},
    {"phrase": "About baking", "targetLanguagePhrase": "Ég vildi tala um bakstur.", "emoji": "🧁"},
    {"phrase": "Favorite workout routine", "targetLanguagePhrase": "Ég vildi tala um uppáhalds æfingarútínu mína.", "emoji": "🏋️"},
    {"phrase": "About photography", "targetLanguagePhrase": "Ég vildi tala um ljósmyndun.", "emoji": "📸"},
    {"phrase": "About my favorite video game", "targetLanguagePhrase": "Ég vildi tala um uppáhalds tölvuleikinn minn.", "emoji": "🎮"},
    {"phrase": "About a concert I've attended", "targetLanguagePhrase": "Ég vildi tala um tónleika sem ég mætti á.", "emoji": "🎤"},
    {"phrase": "About a hiking trip", "targetLanguagePhrase": "Ég vildi tala um gönguferð sem ég fór á.", "emoji": "🥾"},
    {"phrase": "About my favorite cocktail", "targetLanguagePhrase": "Ég vildi tala um uppáhalds kokteilinn minn.", "emoji": "🍹"},
    {"phrase": "About astronomy", "targetLanguagePhrase": "Ég vildi tala um stjörnufræði.", "emoji": "🌌"},
    {"phrase": "About an inspiring person in my life", "targetLanguagePhrase": "Ég vildi tala um mann sem hefur haft áhrif á mig í lífinu mínu.", "emoji": "💫"},
    {"phrase": "About a language I'm learning", "targetLanguagePhrase": "Ég vildi tala um tungumál sem ég er að læra.", "emoji": "🌐"},
    {"phrase": "About my favorite piece of art", "targetLanguagePhrase": "Ég vildi tala um uppáhalds listaverkið mitt.", "emoji": "🎨"}
  ],
  "questionPrompts": {
    "meaning": ["Hvað þýðir __*__?", "Hvað þýðir það?"],
    "correct": ["Er __*__ rétt?", "Er það rétt?"],
    "alternative": ["Áttirðu við eitthvað annað en __*__?", "Áttirðu við eitthvað annað?"],
    "intentPrompt": ["Hvað varstu að reyna að segja í stað __*__? Ég mun segja þér hvernig á að segja það á __target_lang__.", "Hvað varstu að reyna að segja? Ég mun segja þér hvernig á að segja það á __target_lang__."],
    "mistakePrompt": ["Hvað eru villurnar í setningunni __*__", "Hvað eru villurnar í þessari setningu?"]
  },
  "commands": {
    "translate": "^hvernig segir maður (.*)\\?",
    "repeat": "segðu það aftur",
    "repeatSlowly": "segðu það hægt",
    "retry": "farðu aftur"
  }
}
