import * as t from "io-ts"
import { UserDTO, UserDTOValidator } from 'interfaces/User'
import {isLeft} from "fp-ts/lib/Either"
import {PathReporter} from "io-ts/lib/PathReporter"
import {useAuth} from "../contexts/AuthContext"
import {ensureToken} from "../helpers/clients"

const UserResponseValidator = t.type({
  user: UserDTOValidator
})

const get = async (
  token: string, 
): Promise<UserDTO> => {
  const response = await fetch(`/.netlify/functions/get-user`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    }
  })
  if (!response.ok) {
    throw new Error(response.statusText)
  }

  const responseData = await response.json()

  const decoded = UserResponseValidator.decode(responseData)
  if (isLeft(decoded)) {
    throw new Error(`Could not validate data: ${PathReporter.report(decoded).join("\n")}`)
  }

  const { user } = decoded.right

  return user
}

const update = async (
  token: string, 
  user: Partial<UserDTO>
): Promise<void> => {
  const response = await fetch(`/.netlify/functions/update-user`, {
    method: 'PATCH',
    body: JSON.stringify({ user }),
    headers: {
      Authorization: `Bearer ${token}`,
    }
  })
  if (!response.ok) {
    throw new Error(response.statusText)
  }
}

// still needed as it's called at startup
export const UserClient = { update, get }

// hook can only be safely used after session loading
export const useUserClient = () => {
  const { session } = useAuth()
  const token = session?.access_token

  return {
    get: () => get(ensureToken(token)),
    update: (
      a1: Parameters<typeof update>[1],
    ) => update(ensureToken(token), a1),
    isReady: !!token
  }
}
