import {IconDefinition} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon, FontAwesomeIconProps} from "@fortawesome/react-fontawesome"
import React from "react"
import {FC, PropsWithChildren, Ref} from "react"

type Props = {
  icon: IconDefinition,
  iconProps?: Partial<FontAwesomeIconProps>,
  circle?: boolean
  primary?: boolean
  grayscale?: boolean
  inverse?: boolean
  extraClassnames?: string,
  type?: 'submit' | 'reset' | 'button',
}

type ButtonHTMLProps = React.HTMLProps<HTMLButtonElement>;

export const IconButton = React.forwardRef<HTMLButtonElement, PropsWithChildren<ButtonHTMLProps & Props>>(
  ({ 
    children, 
    icon, 
    circle = false, 
    primary = false, 
    grayscale = false, 
    inverse = false, 
    extraClassnames = "", 
    type = 'button' as const, 
    iconProps, 
    ...props 
  }, ref) => {

  const styles = {
    gray: {
      main: "gray-400",
      counter: "white",
    },
    normal: {
      main: "main",
      counter: "white",
    },
    primary: {
      main: "green-600",
      counter: "white",
    },
  }

  if ([primary, grayscale].filter(s => s === true).length > 1) throw Error("only 1 of primary, grayscale allowed")
  const style = styles[primary ? 'primary' : grayscale ? 'gray' : 'normal']
  if (inverse) {
    const temp = style.main
    style.main = style.counter
    style.counter = temp
  }

  return <button 
    className={`
    relative
    inline-flex justify-center 
    text-${style.counter} text-center text-sm font-medium 
    bg-${style.main}
    border-${style.counter} border-2 ${circle ? `rounded-full` : `rounded-lg`}
    px-2 py-2.5 
    hover:bg-${style.counter} hover:text-${style.main} hover:border-${style.main}
    focus:ring-4 focus:text-${style.counter} focus:bg-${style.main} focus:outline-none focus:ring-blue-200 
    disabled:opacity-50
    dark:focus:ring-blue-900 
    ${extraClassnames}
  `.split("\n").map(s => s.trim()).join(" ")} 
    type={type}
    ref={ref}
    {...props} 
    >
      <div className="hidden text-gray-400 bg-gray-400 hover:bg-gray-400"></div>
      <FontAwesomeIcon icon={icon} fixedWidth { ...iconProps } />
      {children}
  </button>
})
