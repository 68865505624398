import {LanguageFile} from "../helpers/language";

export const es: LanguageFile = {
  "code": "es",
  "localCode": "es-ES",
  "name": "spanish",
  "displayName": "Spanish",
  "voices": ["es-ES-Standard-A"],
  "iconURL": "es.png",
  "phrases": [
    {"phrase": "About myself", "targetLanguagePhrase": "Me gustaría hablar de mí mismo.", "emoji": "🧑"},
    {"phrase": "Favorite Spanish dish", "targetLanguagePhrase": "Me gustaría hablar de mi plato español favorito.", "emoji": "🥘"},
    {"phrase": "About my pet", "targetLanguagePhrase": "Me gustaría hablar de mi mascota.", "emoji": "🐾"},
    {"phrase": "Favorite season", "targetLanguagePhrase": "Me gustaría hablar de mi estación favorita.", "emoji": "🌦"},
    {"phrase": "About music", "targetLanguagePhrase": "Me gustaría hablar de música.", "emoji": "🎵"},
    {"phrase": "Favorite book", "targetLanguagePhrase": "Me gustaría hablar de mi libro favorito.", "emoji": "📚"},
    {"phrase": "Dream vacation", "targetLanguagePhrase": "Me gustaría hablar de mis vacaciones soñadas.", "emoji": "🌴"},
    {"phrase": "About sports", "targetLanguagePhrase": "Me gustaría hablar de deportes.", "emoji": "⚽"},
    {"phrase": "About a film I've recently watched", "targetLanguagePhrase": "Me gustaría hablar de una película que vi recientemente.", "emoji": "🎬"},
    {"phrase": "Favorite type of coffee", "targetLanguagePhrase": "Me gustaría hablar de mi tipo de café favorito.", "emoji": "☕"},
    {"phrase": "About my hobbies", "targetLanguagePhrase": "Me gustaría hablar de mis pasatiempos.", "emoji": "🎨"},
    {"phrase": "About my job", "targetLanguagePhrase": "Me gustaría hablar de mi trabajo.", "emoji": "💼"},
    {"phrase": "About gardening", "targetLanguagePhrase": "Me gustaría hablar de jardinería.", "emoji": "🌿"},
    {"phrase": "About fashion", "targetLanguagePhrase": "Me gustaría hablar de moda.", "emoji": "👗"},
    {"phrase": "Favorite childhood memory", "targetLanguagePhrase": "Me gustaría hablar de mi recuerdo favorito de la infancia.", "emoji": "🧸"},
    {"phrase": "About technology", "targetLanguagePhrase": "Me gustaría hablar de tecnología.", "emoji": "💻"},
    {"phrase": "About my favorite city", "targetLanguagePhrase": "Me gustaría hablar de mi ciudad favorita.", "emoji": "🏙"},
    {"phrase": "About my favorite car", "targetLanguagePhrase": "Me gustaría hablar de mi coche favorito.", "emoji": "🚗"},
    {"phrase": "About sustainability", "targetLanguagePhrase": "Me gustaría hablar de sostenibilidad.", "emoji": "🌍"},
    {"phrase": "About baking", "targetLanguagePhrase": "Me gustaría hablar de repostería.", "emoji": "🧁"},
    {"phrase": "Favorite workout routine", "targetLanguagePhrase": "Me gustaría hablar de mi rutina de entrenamiento favorita.", "emoji": "🏋️"},
    {"phrase": "About photography", "targetLanguagePhrase": "Me gustaría hablar de fotografía.", "emoji": "📸"},
    {"phrase": "About my favorite video game", "targetLanguagePhrase": "Me gustaría hablar de mi videojuego favorito.", "emoji": "🎮"},
    {"phrase": "About a concert I've attended", "targetLanguagePhrase": "Me gustaría hablar de un concierto al que he asistido.", "emoji": "🎤"},
    {"phrase": "About a hiking trip", "targetLanguagePhrase": "Me gustaría hablar de una excursión que he hecho.", "emoji": "🥾"},
    {"phrase": "About my favorite cocktail", "targetLanguagePhrase": "Me gustaría hablar de mi cóctel favorito.", "emoji": "🍹"},
    {"phrase": "About astronomy", "targetLanguagePhrase": "Me gustaría hablar de astronomía.", "emoji": "🌌"},
    {"phrase": "About an inspiring person in my life", "targetLanguagePhrase": "Me gustaría hablar de una persona que me inspira en mi vida.", "emoji": "💫"},
    {"phrase": "About a language I'm learning", "targetLanguagePhrase": "Me gustaría hablar de un idioma que estoy aprendiendo.", "emoji": "🌐"},
    {"phrase": "About my favorite piece of art", "targetLanguagePhrase": "Me gustaría hablar de mi obra de arte favorita.", "emoji": "🎨"}
  ],
  "questionPrompts": {
    "meaning": ["¿Qué significa __*__?", "¿Qué significa eso?"],
    "correct": ["¿Es __*__ correcto?", "¿Es correcto?"],
    "alternative": ["¿Quisiste decir algo distinto a __*__?", "¿Quisiste decir algo más?"],
    "intentPrompt": ["¿Qué querías decir en lugar de __*__? Te diré cómo decirlo en __target_lang__.", "¿Qué querías decir? Te diré cómo decirlo en __target_lang__."],
    "mistakePrompt": ["¿Cuáles son los errores en la frase __*__", "¿Cuáles son los errores en esta frase?"]
  },
  "commands": {
    "translate": "^¿cómo se dice (.*)\\?",
    "repeat": "repite eso",
    "repeatSlowly": "dilo lentamente",
    "retry": "vuelve atrás"
  }
}
