import {LanguageFile} from "../helpers/language";

export const nl: LanguageFile = {
  "code": "nl",
  "localCode": "nl-NL",
  "name": "dutch",
  "displayName": "Dutch",
  "voices": ["nl-NL-Standard-A"],
  "iconURL": "nl.png",
  "phrases": [
    {"phrase": "About myself", "targetLanguagePhrase": "Ik wil graag over mezelf praten.", "emoji": "🧑"},
    {"phrase": "Favorite French dish", "targetLanguagePhrase": "Ik wil graag over mijn favoriete Franse gerecht praten.", "emoji": "🥘"},
    {"phrase": "About my pet", "targetLanguagePhrase": "Ik wil graag over mijn huisdier praten.", "emoji": "🐾"},
    {"phrase": "Favorite season", "targetLanguagePhrase": "Ik wil graag over mijn favoriete seizoen praten.", "emoji": "🌦"},
    {"phrase": "About music", "targetLanguagePhrase": "Ik wil graag over muziek praten.", "emoji": "🎵"},
    {"phrase": "Favorite book", "targetLanguagePhrase": "Ik wil graag over mijn favoriete boek praten.", "emoji": "📚"},
    {"phrase": "Dream vacation", "targetLanguagePhrase": "Ik wil graag over mijn droomvakantie praten.", "emoji": "🌴"},
    {"phrase": "About sports", "targetLanguagePhrase": "Ik wil graag over sport praten.", "emoji": "⚽"},
    {"phrase": "About a film I've recently watched", "targetLanguagePhrase": "Ik wil graag praten over een film die ik onlangs heb gezien.", "emoji": "🎬"},
    {"phrase": "Favorite type of coffee", "targetLanguagePhrase": "Ik wil graag over mijn favoriete soort koffie praten.", "emoji": "☕"},
    {"phrase": "About my hobbies", "targetLanguagePhrase": "Ik wil graag over mijn hobby's praten.", "emoji": "🎨"},
    {"phrase": "About my job", "targetLanguagePhrase": "Ik wil graag over mijn werk praten.", "emoji": "💼"},
    {"phrase": "About gardening", "targetLanguagePhrase": "Ik wil graag over tuinieren praten.", "emoji": "🌿"},
    {"phrase": "About fashion", "targetLanguagePhrase": "Ik wil graag over mode praten.", "emoji": "👗"},
    {"phrase": "Favorite childhood memory", "targetLanguagePhrase": "Ik wil graag over mijn favoriete jeugdherinnering praten.", "emoji": "🧸"},
    {"phrase": "About technology", "targetLanguagePhrase": "Ik wil graag over technologie praten.", "emoji": "💻"},
    {"phrase": "About my favorite city", "targetLanguagePhrase": "Ik wil graag over mijn favoriete stad praten.", "emoji": "🏙"},
    {"phrase": "About my favorite car", "targetLanguagePhrase": "Ik wil graag over mijn favoriete auto praten.", "emoji": "🚗"},
    {"phrase": "About sustainability", "targetLanguagePhrase": "Ik wil graag over duurzaamheid praten.", "emoji": "🌍"},
    {"phrase": "About baking", "targetLanguagePhrase": "Ik wil graag over bakken praten.", "emoji": "🧁"},
    {"phrase": "Favorite workout routine", "targetLanguagePhrase": "Ik wil graag over mijn favoriete workout routine praten.", "emoji": "🏋️"},
    {"phrase": "About photography", "targetLanguagePhrase": "Ik wil graag over fotografie praten.", "emoji": "📸"},
    {"phrase": "About my favorite video game", "targetLanguagePhrase": "Ik wil graag over mijn favoriete videogame praten.", "emoji": "🎮"},
    {"phrase": "About a concert I've attended", "targetLanguagePhrase": "Ik wil graag praten over een concert dat ik heb bijgewoond.", "emoji": "🎤"},
    {"phrase": "About a hiking trip", "targetLanguagePhrase": "Ik wil graag praten over een wandeltocht die ik heb gemaakt.", "emoji": "🥾"},
    {"phrase": "About my favorite cocktail", "targetLanguagePhrase": "Ik wil graag over mijn favoriete cocktail praten.", "emoji": "🍹"},
    {"phrase": "About astronomy", "targetLanguagePhrase": "Ik wil graag over astronomie praten.", "emoji": "🌌"},
    {"phrase": "About an inspiring person in my life", "targetLanguagePhrase": "Ik wil graag praten over een inspirerend persoon in mijn leven.", "emoji": "💫"},
    {"phrase": "About a language I'm learning", "targetLanguagePhrase": "Ik wil graag praten over een taal die ik leer.", "emoji": "🌐"},
    {"phrase": "About my favorite piece of art", "targetLanguagePhrase": "Ik wil graag praten over mijn favoriete kunstwerk.", "emoji": "🎨"}
  ], 
  "questionPrompts": {
    "meaning": ["Wat betekent __*__?", "Wat betekent dat?"],
    "correct": ["Is __*__ correct?", "Is dat correct?"],
    "alternative": ["Bedoelde je iets anders dan __*__?", "Bedoelde je iets anders?"],
    "intentPrompt": ["Wat probeerde je te zeggen in plaats van __*__? Ik zal je vertellen hoe je het in __target_lang__ zegt.", "Wat probeerde je te zeggen? Ik zal je vertellen hoe je het in __target_lang__ zegt."],
    "mistakePrompt": ["Wat zijn de fouten in de zin __*__", "Wat zijn de fouten in deze zin?"]
  },
  "commands": {
    "translate": "^hoe zeg je (.*)\\?",
    "repeat": "zeg dat nog een keer",
    "repeatSlowly": "zeg dat langzaam",
    "retry": "ga terug"
  }
}
