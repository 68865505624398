import { Loading } from "../components/Loading";

interface Props {
  loading: boolean,
  className?: string,
  inverse?: boolean,
  transparent?: boolean,
}

export const LoadingWrapper: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  loading,
  inverse,
  transparent,
}) => {
  const bgColor = transparent 
    ? 'transparent' 
    : inverse 
      ? "main" 
      : "white"

  return loading 
      ? <div className={`flex flex-col items-center justify-center w-full h-full bg-${bgColor}`}>
        <Loading inverse={inverse} transparent={transparent} />
      </div>
      : <>{children}</>
};
