import {useEffect, useState} from 'react'
import {VocabularyDTO} from '../../lib/interfaces/Vocabulary'
import { useVocabularyClient } from '../clients/vocabulary'
import {LanguagePicker} from '../components/LanguagePicker'
import {useAuth} from '../contexts/AuthContext'
import {LoadingWrapper} from '../components/LoadingWrapper'
import {CloseButton} from '../components/CloseButton'
import {SupportedLanguageCodes} from 'interfaces/SupportedLanguages'
import _ from 'lodash'
import {useError} from '../contexts/ErrorContext'
import { getLanguages } from '../helpers/language'

export function Vocabulary() {
  const languages = getLanguages()
  const { user } = useAuth()
  const currentUserLanguage = user?.targetLanguage?.code

  const [vocabulary, setVocabulary] = useState<VocabularyDTO[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedLanguage, setLanguage] = useState<SupportedLanguageCodes>(currentUserLanguage || 'fr')

  const { setError } = useError()
  const vocabularyClient = useVocabularyClient();

  // todo: fix this, it's calling twice
  useEffect(() => {
    const callApi = async () => {
      try {
        const filter = { language: selectedLanguage, enabled: true }
        const vocabulary = await vocabularyClient.list(filter)
        setError(null)
        setVocabulary(vocabulary)
        setLoading(false)
      } catch (e: any) {
        setError(e)
      }
    }

    callApi()
    setLoading(true)
  }, [selectedLanguage])

  const disableVocabulary = async (v: VocabularyDTO) => {
    await vocabularyClient.update(v.id, { enabled: false })

    setVocabulary(items => items.filter(item => item.id !== v.id))
  }

  if (!currentUserLanguage) throw Error("user must have a default language")

  return <VocabularyDisplay 
    loading={loading}
    currentUserLanguage={currentUserLanguage}
    userLanguages={_.values(languages).map(v => v.code)}
    vocabulary={vocabulary.filter(v => v.enabled)}
    onLanguageChange={setLanguage}
    disableVocabulary={disableVocabulary}
  />
}

type VocabularyDisplayProps = {
  loading: boolean,
  currentUserLanguage: SupportedLanguageCodes,
  userLanguages: SupportedLanguageCodes[],
  vocabulary: VocabularyDTO[],
  onLanguageChange: (language: SupportedLanguageCodes) => void
  disableVocabulary: (vocabulary: VocabularyDTO) => void
}

function VocabularyDisplay(props: VocabularyDisplayProps) {
  const {
    currentUserLanguage,
    userLanguages,
    vocabulary,
    onLanguageChange,
    loading,
    disableVocabulary,
  } = props

  return (
    <div 
      title='Lesson History' 
      className="container h-full py-6 mx-auto bg-gray-100 dark:bg-gray-700 dark:text-gray-100"
    >
      <div className='flex flex-col items-center h-full px-2'>
        <h1 className='mb-6 text-2xl font-semibold text-center text-gray-900 dark:text-gray-100'>Your Vocabulary</h1>
        <LanguagePicker 
          onChange={onLanguageChange} 
          startingLanguage={currentUserLanguage} 
          languageCodes={userLanguages}
        />
        <LoadingWrapper loading={loading} transparent>
          {vocabulary.length === 0 
            ? <p className='p-4 py-12 text-lg font-semibold text-center'>You have no saved vocabulary yet.</p>
            : <ul className='py-2'>
              { vocabulary.map(vocabulary => <li key={vocabulary.id} className='flex flex-row items-center py-2'>
                <div className='px-2'>
                  <CloseButton onClick={() => disableVocabulary(vocabulary)} />
                </div>
                <p className='text-lg'>{vocabulary.text}</p>
              </li>)}
            </ul>
          }
        </LoadingWrapper>
      </div>
    </div>
  )
}
