import { useState, useMemo, useEffect, useRef, FC } from "react";
import {ChatMessageRoles, ChatMessageT, newChatMessage} from '../types/ChatMessage';
import {ChatMessage} from '../components/ChatMessage';
import {ChatState} from "../hooks/use-chat";
import { LessonVocabulary } from "../contexts/LessonContext";

type Props = {
  vocabulary: LessonVocabulary[]
  onMessageClick: (msg: ChatMessageT) => void
  currentChat: string | null
  chatHistory: ChatMessageT[]
  cancel: () => void
  state: ChatState
	toggleMessageTranslation: (msg: ChatMessageT) => Promise<void>
	removeTo: (msg: ChatMessageT) => Promise<void>
	readAloud: (msg: ChatMessageT) => Promise<void>
  buttonsEnabled?: boolean
}

export const ChatBody: FC<Props> = ({
  vocabulary,
  onMessageClick,
  currentChat,
  chatHistory,
  cancel,
  state,
  toggleMessageTranslation,
  readAloud,
  removeTo,
  buttonsEnabled = true,
}) => {
  const [openMessage, setOpenMessage] = useState<string>("")

  const toggleDisplayOptions = function(chatId: string) {
    if (openMessage === chatId) {
      setOpenMessage("")
    } else {
      setOpenMessage(chatId)
    }
  }

  // This is the message that is currently being generated by the AI
  const currentMessage = useMemo<ChatMessageT>(() => {
      return { 
        id: new Date(),
        content: currentChat ?? "",
        role: ChatMessageRoles.ASSISTANT,
        source: "text",
        translation: "",
        translationEnabled: false,
        translationLoading: false,
      };
  }, [currentChat]);

  // This is a ref to the bottom of the chat history. We use it to scroll
  // to the bottom when a new message is added.
  const bottomRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chatHistory.length == 0) return;
    scrollToBottom();
  }, [currentChat, chatHistory, state]);

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const StopGeneratingButton = (
      <button
        className="px-4 py-2 my-2 text-gray-900 bg-gray-100 rounded-lg"
        onClick={cancel}
        >
        Stop generating
      </button>
  )

  return (
    <section className="flex-grow pb-8 mb-32 overflow-y-auto">
      <div className="flex flex-col space-y-4">
        {chatHistory.map((chat, i) =>
            <ChatMessage 
              key={i} 
              message={chat} 
              toggleTranslation={() => toggleMessageTranslation(chat)} 
              toggleDisplayOptions={() => toggleDisplayOptions(chat.id.toISOString())} 
              displayOptions={chat.id.toISOString() === openMessage}
              readAloud={readAloud}
              removeTo={removeTo}
              vocabulary={vocabulary}
              onClick={() => onMessageClick(chat)}
              buttonsEnabled={buttonsEnabled}
            />
          )}
          {state === 'waiting' && <ChatMessage message={newChatMessage("", ChatMessageRoles.ASSISTANT, 'text')} isThinking /> }
          {state === 'transcribing' && <ChatMessage message={newChatMessage("", ChatMessageRoles.USER, 'text')} isThinking /> }
          {state !== 'waiting' && state !== 'transcribing' && state !== 'idle' && <ChatMessage message={currentMessage} vocabulary={vocabulary} />
        }
      </div>
      {state === "idle" || state === "transcribing" ? null : StopGeneratingButton }
      <div ref={bottomRef} />
    </section>
  );
}
