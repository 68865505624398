export type Series = [number, number]

type FlattenOverlappingSeriesParams<T> = {
  getSeriesFromItem?: (item: T) => [number, number],
  mapToNewItem?: (a: T, b: T, s: number, e: number) => T,
  mergeTouching?: boolean,
}

export function flattenOverlappingSeries<T>(
  items: T[],
  opts: FlattenOverlappingSeriesParams<T> = { },
): T[] {
  const getSeriesFromItem = opts.getSeriesFromItem || ((item: T) => item as [number, number])
  const mapToNewItem = opts.mapToNewItem || ((a: T, b: T, s: number, e: number) => [s, e] as T)
  const mergeTouching = opts.mergeTouching === undefined ? true : opts.mergeTouching

  // Sort using callback to extract ranges
  const sortedItems = items.sort((a, b) => getSeriesFromItem(a)[0] - getSeriesFromItem(b)[0]);

  const merged: T[] = [];

  // Iterate through the sorted array
  for (let i = 0; i < sortedItems.length; i++) {
    const current = sortedItems[i]
    const currentRange = getSeriesFromItem(current);
    const [start, end ] = currentRange

    const hasOverlap = () => mergeTouching 
      ? getSeriesFromItem(merged[merged.length - 1])[1] < start
      : getSeriesFromItem(merged[merged.length - 1])[1] <= start

    // If the merged array is empty or if the current range does not overlap with the last range in the merged array
    if (!merged.length || hasOverlap()) {
      merged.push(mapToNewItem(current, current, ...currentRange)); // Add the current range as it is
    } else {
      // There's an overlap, so merge the current range with the last range in the merged array
      const top = merged.pop()
      if (!top) {
        throw new Error('top is undefined');
      }
      const newTop = mapToNewItem(top, current, getSeriesFromItem(top)[0], Math.max(getSeriesFromItem(top)[1], end));
      merged.push(newTop);
    }
  }

  return merged;
}

export function findAllIndicies<T> (a: T[], predicate: (item: T) => boolean): number[] {
  const indicies: number[] = []

  for (let i = 0; i < a.length; i++) {
    if (predicate(a[i])) {
      indicies.push(i)
    }
  }

  return indicies
}
