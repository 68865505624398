import {FC, PropsWithChildren, useCallback, useRef } from "react"
import {Highlight, SelectableWord} from "../hooks/use-selectable-words"

type Props = {
  words: SelectableWord[],
  onEnterWordAtIndex: (i: number) => void,
  wordIdPrefix?: string,
}
export const SelectableWords: FC<Props> = ({ 
  words, 
  onEnterWordAtIndex,
  wordIdPrefix = 'selectable-word-abstract',
}) => {

 return <h2 
    className={`mb-4 
text-2xl text-center text-gray-900 dark:text-main italic tracking-tight touch-none
`}>"
  {words.map((word, i) => {
    return <span key={i + word.text + '-fragment'}>
      <Word 
        i={i}
        key={i + word.text }
        selected={word.selected}
        match={word.match}
        reviewed={word.reviewed}
        onDown={useCallback(word.startSelecting, [])}
        onEnterWordAtIndex={onEnterWordAtIndex}
        idPrefix={wordIdPrefix}
      >
        {word.text}
      </Word>
      {i !== words.length-1 && <span
        key={i + word.text + '-space'}
        className={`
          cursor-text
          select-none 
          py-1 w-1
          ${word.selected === 'leftEnd' || word.selected === 'middle' ? 'bg-main' : '' } 
          ${word.match === 'leftEnd' || word.match === 'middle' ? 'border-b-4 border-green-500' : '' } 
        `}
      >
        {" "}
      </span>}
    </span>
    })}
    "
</h2>
}

type WordProps = {
  i: number,
  selected: Highlight,
  match: Highlight,
  reviewed: Highlight,
  onDown: () => void,
  onEnterWordAtIndex: (i: number) => void,
  idPrefix: string,
}
const Word: FC<PropsWithChildren<WordProps>> = ({
  children, 
  selected,
  match,
  reviewed,
  onDown,
  onEnterWordAtIndex,
  idPrefix,
  i,
}) => {
  const selectedStyles = (selected: Highlight) => {
    const selectedStyle = `bg-main text-gray-100 dark:text-white`

    switch (selected) {
      case 'single':
        return `${selectedStyle} rounded-lg`
      case 'leftEnd':
        return `${selectedStyle} rounded-l-lg`
      case 'rightEnd':
        return `${selectedStyle} rounded-r-lg`
      case 'middle':
        return selectedStyle
      case 'none':
        return 'bg-none hover:bg-gray-100'
    }
  }

  const wordSpanRef = useRef<HTMLSpanElement>(null)

  return <span 
    ref={wordSpanRef}
    id={`${idPrefix}-${i}`}
    className={`
      select-none 
      cursor-text
      py-1 
      leading-10
      ${selectedStyles(selected)}
      ${match !== 'none' 
        ? 'border-b-4 border-green-500' 
        : reviewed !== 'none' 
          ? 'border-b-4 border-gray-800' 
          : ''}
    ${selected === 'none' 
      ? match === 'none' && reviewed === 'none' 
        ? 'hover:rounded-lg'
        : 'hover:rounded-t-lg'
      : ''}
    `}

    onTouchStart={(e: React.TouchEvent) => {
      onDown();
    }}
    onMouseDown={(e: React.MouseEvent) => {
      onDown();
    }}
    onMouseMove={(e: React.MouseEvent) => {
      const el = document.elementFromPoint(e.clientX, e.clientY)

      if (!el) return;
      if (!el.id.startsWith(`${idPrefix}-`)) return;
      // use regex with idPrefix to get id
      const id = el.id.match(new RegExp(`^${idPrefix}-([0-9]+)$`))
      if (!id) return

      const i = parseInt(id![1])
      onEnterWordAtIndex(i);
    }}
    onTouchMove={(e: React.TouchEvent) => {
      const touch = e.touches[0]
      const el = document.elementFromPoint(touch.clientX, touch.clientY)

      if (!el) return;
      if (!el.id.startsWith(`${idPrefix}-`)) return;
      // use regex with idPrefix to get id
      const id = el.id.match(new RegExp(`^${idPrefix}-([0-9]+)$`))
      if (!id) return

      const i = parseInt(id![1])
      onEnterWordAtIndex(i);
    }}
  >
    {children}
  </span>
}
