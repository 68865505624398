import {LanguageFile} from "../helpers/language";

export const se: LanguageFile = {
  "code": "se",
  "localCode": "sv-SE",
  "name": "swedish",
  "displayName": "Swedish",
  "voices": ["sv-SE-Standard-A"],
  "iconURL": "se.png",
  "phrases": [
    {"phrase": "About myself", "targetLanguagePhrase": "Jag skulle vilja prata om mig själv.", "emoji": "🧑"},
    {"phrase": "Favorite French dish", "targetLanguagePhrase": "Jag skulle vilja prata om min favorit franska rätt.", "emoji": "🥘"},
    {"phrase": "About my pet", "targetLanguagePhrase": "Jag skulle vilja prata om mitt husdjur.", "emoji": "🐾"},
    {"phrase": "Favorite season", "targetLanguagePhrase": "Jag skulle vilja prata om min favoritårstid.", "emoji": "🌦"},
    {"phrase": "About music", "targetLanguagePhrase": "Jag skulle vilja prata om musik.", "emoji": "🎵"},
    {"phrase": "Favorite book", "targetLanguagePhrase": "Jag skulle vilja prata om min favoritbok.", "emoji": "📚"},
    {"phrase": "Dream vacation", "targetLanguagePhrase": "Jag skulle vilja prata om min drömsemester.", "emoji": "🌴"},
    {"phrase": "About sports", "targetLanguagePhrase": "Jag skulle vilja prata om sport.", "emoji": "⚽"},
    {"phrase": "About a film I've recently watched", "targetLanguagePhrase": "Jag skulle vilja prata om en film jag nyligen såg.", "emoji": "🎬"},
    {"phrase": "Favorite type of coffee", "targetLanguagePhrase": "Jag skulle vilja prata om min favoritkaffetyp.", "emoji": "☕"},
    {"phrase": "About my hobbies", "targetLanguagePhrase": "Jag skulle vilja prata om mina hobbies.", "emoji": "🎨"},
    {"phrase": "About my job", "targetLanguagePhrase": "Jag skulle vilja prata om mitt jobb.", "emoji": "💼"},
    {"phrase": "About gardening", "targetLanguagePhrase": "Jag skulle vilja prata om trädgårdsarbete.", "emoji": "🌿"},
    {"phrase": "About fashion", "targetLanguagePhrase": "Jag skulle vilja prata om mode.", "emoji": "👗"},
    {"phrase": "Favorite childhood memory", "targetLanguagePhrase": "Jag skulle vilja prata om mitt favorit barndomsminne.", "emoji": "🧸"},
    {"phrase": "About technology", "targetLanguagePhrase": "Jag skulle vilja prata om teknik.", "emoji": "💻"},
    {"phrase": "About my favorite city", "targetLanguagePhrase": "Jag skulle vilja prata om min favoritstad.", "emoji": "🏙"},
    {"phrase": "About my favorite car", "targetLanguagePhrase": "Jag skulle vilja prata om min favoritbil.", "emoji": "🚗"},
    {"phrase": "About sustainability", "targetLanguagePhrase": "Jag skulle vilja prata om hållbarhet.", "emoji": "🌍"},
    {"phrase": "About baking", "targetLanguagePhrase": "Jag skulle vilja prata om bakning.", "emoji": "🧁"},
    {"phrase": "Favorite workout routine", "targetLanguagePhrase": "Jag skulle vilja prata om min favorit träningsrutin.", "emoji": "🏋️"},
    {"phrase": "About photography", "targetLanguagePhrase": "Jag skulle vilja prata om fotografi.", "emoji": "📸"},
    {"phrase": "About my favorite video game", "targetLanguagePhrase": "Jag skulle vilja prata om mitt favorit videospel.", "emoji": "🎮"},
    {"phrase": "About a concert I've attended", "targetLanguagePhrase": "Jag skulle vilja prata om en konsert jag har besökt.", "emoji": "🎤"},
    {"phrase": "About a hiking trip", "targetLanguagePhrase": "Jag skulle vilja prata om en vandringstur jag har gjort.", "emoji": "🥾"},
    {"phrase": "About my favorite cocktail", "targetLanguagePhrase": "Jag skulle vilja prata om min favoritcocktail.", "emoji": "🍹"},
    {"phrase": "About astronomy", "targetLanguagePhrase": "Jag skulle vilja prata om astronomi.", "emoji": "🌌"},
    {"phrase": "About an inspiring person in my life", "targetLanguagePhrase": "Jag skulle vilja prata om en inspirerande person i mitt liv.", "emoji": "💫"},
    {"phrase": "About a language I'm learning", "targetLanguagePhrase": "Jag skulle vilja prata om ett språk jag lär mig.", "emoji": "🌐"},
    {"phrase": "About my favorite piece of art", "targetLanguagePhrase": "Jag skulle vilja prata om mitt favoritkonstverk.", "emoji": "🎨"}
  ],
  "questionPrompts": {
    "meaning": ["Vad betyder __*__?", "Vad betyder det?"],
    "correct": ["Är __*__ korrekt?", "Är det korrekt?"],
    "alternative": ["Menade du något annat än __*__?", "Menade du något annat?"],
    "intentPrompt": ["Vad försökte du säga istället för __*__? Jag ska berätta hur du säger det på __target_lang__.", "Vad försökte du säga? Jag ska berätta hur du säger det på __target_lang__."],
    "mistakePrompt": ["Vad är felen i meningen __*__", "Vad är felen i denna mening?"]
  },
  "commands": {
    "translate": "^hur säger man (.*)\\?",
    "repeat": "säg det igen",
    "repeatSlowly": "säg det långsamt",
    "retry": "gå tillbaka"
  }
}
