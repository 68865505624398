import {LanguageFile} from "../helpers/language";

export const en: LanguageFile = {
  "code": "en",
  "localCode": "en-GB",
  "name": "english",
  "displayName": "English",
  "voices": ["en-GB-Standard-A"],
  "iconURL": "en.png",
  "phrases": [
    {"phrase": "About myself", "targetLanguagePhrase": "I would like to talk about myself.", "emoji": "🧑"},
    {"phrase": "Favourite British dish", "targetLanguagePhrase": "I would like to talk about my favourite British dish.", "emoji": "🍲"},
    {"phrase": "About my pet", "targetLanguagePhrase": "I would like to talk about my pet.", "emoji": "🐾"},
    {"phrase": "Favourite season", "targetLanguagePhrase": "I would like to talk about my favourite season.", "emoji": "🌦"},
    {"phrase": "About music", "targetLanguagePhrase": "I would like to talk about music.", "emoji": "🎵"},
    {"phrase": "Favourite book", "targetLanguagePhrase": "I would like to talk about my favourite book.", "emoji": "📚"},
    {"phrase": "Dream holiday", "targetLanguagePhrase": "I would like to talk about my dream holiday.", "emoji": "🌴"},
    {"phrase": "About sports", "targetLanguagePhrase": "I would like to talk about sports.", "emoji": "⚽"},
    {"phrase": "About a film I've recently watched", "targetLanguagePhrase": "I would like to talk about a film I've recently watched.", "emoji": "🎬"},
    {"phrase": "Favourite type of tea", "targetLanguagePhrase": "I would like to talk about my favourite type of tea.", "emoji": "☕"},
    {"phrase": "About my hobbies", "targetLanguagePhrase": "I would like to talk about my hobbies.", "emoji": "🎨"},
    {"phrase": "About my job", "targetLanguagePhrase": "I would like to talk about my job.", "emoji": "💼"},
    {"phrase": "About gardening", "targetLanguagePhrase": "I would like to talk about gardening.", "emoji": "🌿"},
    {"phrase": "About fashion", "targetLanguagePhrase": "I would like to talk about fashion.", "emoji": "👗"},
    {"phrase": "Favourite childhood memory", "targetLanguagePhrase": "I would like to talk about my favourite childhood memory.", "emoji": "🧸"},
    {"phrase": "About technology", "targetLanguagePhrase": "I would like to talk about technology.", "emoji": "💻"},
    {"phrase": "About my favourite city", "targetLanguagePhrase": "I would like to talk about my favourite city.", "emoji": "🏙"},
    {"phrase": "About my favourite car", "targetLanguagePhrase": "I would like to talk about my favourite car.", "emoji": "🚗"},
    {"phrase": "About sustainability", "targetLanguagePhrase": "I would like to talk about sustainability.", "emoji": "🌍"},
    {"phrase": "About baking", "targetLanguagePhrase": "I would like to talk about baking.", "emoji": "🧁"},
    {"phrase": "Favourite workout routine", "targetLanguagePhrase": "I would like to talk about my favourite workout routine.", "emoji": "🏋️"},
    {"phrase": "About photography", "targetLanguagePhrase": "I would like to talk about photography.", "emoji": "📸"},
    {"phrase": "About my favourite video game", "targetLanguagePhrase": "I would like to talk about my favourite video game.", "emoji": "🎮"},
    {"phrase": "About a concert I've attended", "targetLanguagePhrase": "I would like to talk about a concert I've attended.", "emoji": "🎤"},
    {"phrase": "About a hiking trip", "targetLanguagePhrase": "I would like to talk about a hiking trip I've been on.", "emoji": "🥾"},
    {"phrase": "About my favourite cocktail", "targetLanguagePhrase": "I would like to talk about my favourite cocktail.", "emoji": "🍹"},
    {"phrase": "About astronomy", "targetLanguagePhrase": "I would like to talk about astronomy.", "emoji": "🌌"},
    {"phrase": "About an inspiring person in my life", "targetLanguagePhrase": "I would like to talk about an inspiring person in my life.", "emoji": "💫"},
    {"phrase": "About a language I'm learning", "targetLanguagePhrase": "I would like to talk about a language I'm learning.", "emoji": "🌐"},
    {"phrase": "About my favourite piece of art", "targetLanguagePhrase": "I would like to talk about my favourite piece of art.", "emoji": "🎨"}
  ],
  "questionPrompts": {
    "meaning": ["What does __*__ mean?", "What does it mean?"],
    "correct": ["Is __*__ correct?", "Is it correct?"],
    "alternative": ["Did you mean something other than __*__?", "Did you mean something else?"],
    "intentPrompt": ["What were you trying to say instead of __*__? I'll tell you how to say it in __target_lang__.", "What were you trying to say? I'll tell you how to say it in __target_lang__."],
    "mistakePrompt": ["What are the mistakes in the phrase __*__", "What are the mistakes in this phrase?"]
  },
  "commands": {
    "translate": "^how do you say (.*)\\?",
    "repeat": "repeat",
    "repeatSlowly": "repeat slowly",
    "retry": "go back"
  }
}
