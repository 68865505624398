import {Language} from "../helpers/language";

  async function getAudioDataFromText(
    text: string, 
    targetLanguage: Language, 
    options?: { speed: number }
  ): Promise<any> {
    try {
      const response = await fetch('/.netlify/functions/text-to-speech', {
        method: 'POST',
        body: JSON.stringify({ 
          text, 
          localeLangCode: targetLanguage.localCode, 
          voice: targetLanguage.voices[0], 
          ...options 
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      if (!data.audioContent) {
        throw new Error('reponse should contain audioContent')
      }
      return data.audioContent
    } catch (error) {
      console.error('error reading text', error);
    }
  } 

  export const TextToSpeechClient = { speak: getAudioDataFromText }
