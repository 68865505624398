import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {Card} from '../components/Card'
import {LanguageIcon} from '../components/LanguageIcon'
import {PhraseDTO} from '../../lib/interfaces/Phrase'
import { useLessonClient } from '../clients/lesson'
import {SelectDropdown} from '../components/SelectDropdown'
import {LoadingWrapper} from '../components/LoadingWrapper'
import {SupportedLanguageCodes} from 'interfaces/SupportedLanguages'
import {ReviewDTO} from 'interfaces/Review'
import {useError} from '../contexts/ErrorContext'

export type HistoricalLesson = {
  UID: string,
  targetLanguageCode: SupportedLanguageCodes,
  vocabulary: {vocabularyItem: string, enabled: boolean}[],
  phrases: PhraseDTO[],
  startedAt: Date,
  finishedAt: Date,
  reviews: ReviewDTO[],
}

export type HistoricalLessonOverview = {
  ID: string,
  targetLanguageCode: SupportedLanguageCodes,
  startedAt: Date,
  finishedAt: Date,
  phraseCount: number,
}

const months = [
  'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
]

const startingYear = 2020
const currentYear = new Date().getFullYear()
const yearsRange = [...Array(currentYear - startingYear + 1)].map((_, index) => index + startingYear);
const yearsDropdownItems = yearsRange.map(year => ({id: ""+year, name: ""+year}))

export function History() {
  const [history, setHistory] = useState<HistoricalLessonOverview[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [year, setYear] = useState<number>(new Date().getFullYear())
  const [month, setMonth] = useState<number>(new Date().getMonth())
  const { list, isReady } = useLessonClient();
  const { setError } = useError();
  const navigate = useNavigate();

  const lastMonthInYear = year === currentYear
    ? new Date().getMonth()
    : 11

  const monthDropdownItems = months
    .slice(0, lastMonthInYear + 1)
    .map(month => ({ id: month, name: month }))

  useEffect(() => {
    const callApi = async () => {
      setLoading(true)
      try {
        const beginningOfMonth = new Date(year, month, 1);
        const endOfMonth = new Date(year, month + 1, 0);
        const filter = { from: beginningOfMonth, to: endOfMonth }
        const historicalLessons = await list(filter)
        const newHistoryOverview = historicalLessons
          .filter(l => !!l.finishedAt)
          .map<HistoricalLessonOverview>((l) => ({ 
            ID: l.UID, 
            targetLanguageCode: l.targetLanguageCode as SupportedLanguageCodes,
            startedAt: new Date(l.startedAt), 
            finishedAt: new Date(l.finishedAt), 
            phraseCount: l.phrases.length,
          }))
          .sort((a, b) => b.finishedAt.getTime() - a.finishedAt.getTime()) // most recent to oldest

        setError(null)
        setHistory(newHistoryOverview)
      } catch (e: any) {
        setError(e)
      }
      
      setLoading(false)
    }

    isReady && callApi()
  }, [month, year, isReady ])

  function getDayOfWeek(dayNumber: number): string {
    const daysOfWeek: string[] = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ];

    if (dayNumber >= 0 && dayNumber <= 6) {
      return daysOfWeek[dayNumber];
    } else {
      throw new Error('Invalid day number. Must be between 0 and 6.');
    }
  }
  const buildTimeString = (date: Date) => `${getDayOfWeek(date.getDay())} ${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}`

  return (
    <div 
      title='Lesson History' 
      className="container h-full py-6 mx-auto bg-gray-100 dark:bg-gray-700"
    >
      <div className='flex flex-col items-center h-full px-2 dark:text-gray-100'>
        <h1 className='mb-6 text-2xl font-semibold text-center text-gray-900 dark:text-white'>Lesson History</h1>
        <div className='text-sm'>
          <p className='inline-block px-2'>Showing lessons from </p>
          <div className='inline-block font-bold'>
            <SelectDropdown 
              options={monthDropdownItems}
              selected={monthDropdownItems[month]}
              setSelected={(item) => setMonth(months.findIndex(month => month === item.id) || 0)}
            />
          </div>
          <p className='inline-block px-2'>in year </p>
          <div className='inline-block font-bold'>
            <SelectDropdown 
              options={yearsDropdownItems}
              selected={yearsDropdownItems.find(item => item.id === "" + year)!}
              setSelected={(item) => setYear(parseInt(item.id))}
            />
          </div>
        </div>
        <div className='flex flex-col items-center justify-start h-full'>
          <LoadingWrapper loading={loading} transparent>
            {history.length === 0 
              ? <p className='p-4 py-12 text-lg font-semibold text-center'>You have no lessons for this month.</p>
                : history.map(lesson => {
                    return <div className='py-6' key={lesson.ID}>
                    <Card onClick={() => {navigate(`/summary/${lesson.ID}`)}}>
                      <h2 className="text-base font-semibold text-gray-900 leading-7 dark:text-gray-100">
                        <LanguageIcon targetLanguageCode={lesson.targetLanguageCode} />
                        <span> {buildTimeString(lesson.startedAt)} </span>
                      </h2>
                      <p className="mt-1 text-sm text-gray-600 leading-6 dark:text-gray-300">{lesson.phraseCount} phrases saved.</p>
                    </Card>
                  </div>
                })}
          </LoadingWrapper>
        </div>
      </div>
    </div>
  )
}

