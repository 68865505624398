import {PropsWithChildren} from "react"
import {useNavigate} from "react-router-dom"
import { ReactComponent as BotIcon } from '../images/chatbot-icon.svg'
import {Loading} from "./Loading"

type Props = {
  title: string | JSX.Element,
  isLoading?: boolean,
}

export const FormWrapper: React.FC<PropsWithChildren<Props>> = ({children, title, isLoading}) => {
    const navigate = useNavigate();
    return (
    <div className="w-full h-full bg-gray-50 dark:bg-gray-700 dark:text-gray-100">
      <div className="relative">
        {isLoading && 
          <div className="absolute z-10 w-full h-full">
            <div className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2">
              <Loading />
            </div>
          </div>
        } 
        <section className={`relative h-full  ${isLoading && 'opacity-20 pointer-none disabled'}`}>
          <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto">
              <a onClick={(e) => {e.preventDefault(); navigate('/')}} className="flex flex-col items-center py-2 mb-6 text-2xl font-semibold text-gray-900 md:flex-row dark:text-gray-100">
                  <BotIcon 
                    className="w-8 h-8 m-2 fill-secondary"
                  />
                  {title}
              </a>
              <div className="w-full bg-white rounded-lg shadow min-h-96 dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                  <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                    {children}
                  </div>
              </div>
          </div>
        </section>
      </div>
    </div>
  )
} 
