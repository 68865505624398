import {LanguageFile} from "../helpers/language";

export const no: LanguageFile = { 
  "code": "no",
  "localCode": "nb-NO",
  "name": "norwegian",
  "displayName": "Norwegian",
  "voices": ["nb-NO-Standard-A"],
  "iconURL": "no.png",
  "phrases": [
    {"phrase": "About myself", "targetLanguagePhrase": "Jeg vil gjerne snakke om meg selv.", "emoji": "🧑"},
    {"phrase": "Favorite French dish", "targetLanguagePhrase": "Jeg vil gjerne snakke om min favoritt franske rett.", "emoji": "🥘"},
    {"phrase": "About my pet", "targetLanguagePhrase": "Jeg vil gjerne snakke om kjæledyret mitt.", "emoji": "🐾"},
    {"phrase": "Favorite season", "targetLanguagePhrase": "Jeg vil gjerne snakke om min favorittsesong.", "emoji": "🌦"},
    {"phrase": "About music", "targetLanguagePhrase": "Jeg vil gjerne snakke om musikk.", "emoji": "🎵"},
    {"phrase": "Favorite book", "targetLanguagePhrase": "Jeg vil gjerne snakke om min favorittbok.", "emoji": "📚"},
    {"phrase": "Dream vacation", "targetLanguagePhrase": "Jeg vil gjerne snakke om min drømmeferie.", "emoji": "🌴"},
    {"phrase": "About sports", "targetLanguagePhrase": "Jeg vil gjerne snakke om sport.", "emoji": "⚽"},
    {"phrase": "About a film I've recently watched", "targetLanguagePhrase": "Jeg vil gjerne snakke om en film jeg nylig så.", "emoji": "🎬"},
    {"phrase": "Favorite type of coffee", "targetLanguagePhrase": "Jeg vil gjerne snakke om min favoritt type kaffe.", "emoji": "☕"},
    {"phrase": "About my hobbies", "targetLanguagePhrase": "Jeg vil gjerne snakke om mine hobbyer.", "emoji": "🎨"},
    {"phrase": "About my job", "targetLanguagePhrase": "Jeg vil gjerne snakke om jobben min.", "emoji": "💼"},
    {"phrase": "About gardening", "targetLanguagePhrase": "Jeg vil gjerne snakke om hagearbeid.", "emoji": "🌿"},
    {"phrase": "About fashion", "targetLanguagePhrase": "Jeg vil gjerne snakke om mote.", "emoji": "👗"},
    {"phrase": "Favorite childhood memory", "targetLanguagePhrase": "Jeg vil gjerne snakke om min favoritt barndomsminne.", "emoji": "🧸"},
    {"phrase": "About technology", "targetLanguagePhrase": "Jeg vil gjerne snakke om teknologi.", "emoji": "💻"},
    {"phrase": "About my favorite city", "targetLanguagePhrase": "Jeg vil gjerne snakke om min favorittby.", "emoji": "🏙"},
    {"phrase": "About my favorite car", "targetLanguagePhrase": "Jeg vil gjerne snakke om min favorittbil.", "emoji": "🚗"},
    {"phrase": "About sustainability", "targetLanguagePhrase": "Jeg vil gjerne snakke om bærekraft.", "emoji": "🌍"},
    {"phrase": "About baking", "targetLanguagePhrase": "Jeg vil gjerne snakke om baking.", "emoji": "🧁"},
    {"phrase": "Favorite workout routine", "targetLanguagePhrase": "Jeg vil gjerne snakke om min favoritt treningsrutine.", "emoji": "🏋️"},
    {"phrase": "About photography", "targetLanguagePhrase": "Jeg vil gjerne snakke om fotografering.", "emoji": "📸"},
    {"phrase": "About my favorite video game", "targetLanguagePhrase": "Jeg vil gjerne snakke om mitt favoritt videospill.", "emoji": "🎮"},
    {"phrase": "About a concert I've attended", "targetLanguagePhrase": "Jeg vil gjerne snakke om en konsert jeg har deltatt på.", "emoji": "🎤"},
    {"phrase": "About a hiking trip", "targetLanguagePhrase": "Jeg vil gjerne snakke om en fottur jeg har gjort.", "emoji": "🥾"},
    {"phrase": "About my favorite cocktail", "targetLanguagePhrase": "Jeg vil gjerne snakke om min favoritt cocktail.", "emoji": "🍹"},
    {"phrase": "About astronomy", "targetLanguagePhrase": "Jeg vil gjerne snakke om astronomi.", "emoji": "🌌"},
    {"phrase": "About an inspiring person in my life", "targetLanguagePhrase": "Jeg vil gjerne snakke om en inspirerende person i livet mitt.", "emoji": "💫"},
    {"phrase": "About a language I'm learning", "targetLanguagePhrase": "Jeg vil gjerne snakke om et språk jeg lærer.", "emoji": "🌐"},
    {"phrase": "About my favorite piece of art", "targetLanguagePhrase": "Jeg vil gjerne snakke om mitt favoritt kunstverk.", "emoji": "🎨"}
  ],
  "questionPrompts": {
    "meaning": ["Hva betyr __*__?", "Hva betyr det?"],
    "correct": ["Er __*__ korrekt?", "Er det korrekt?"],
    "alternative": ["Mente du noe annet enn __*__?", "Mente du noe annet?"],
    "intentPrompt": ["Hva prøvde du å si i stedet for __*__? Jeg vil fortelle deg hvordan du sier det på __target_lang__.", "Hva prøvde du å si? Jeg vil fortelle deg hvordan du sier det på __target_lang__."],
    "mistakePrompt": ["Hva er feilene i setningen __*__", "Hva er feilene i denne setningen?"]
  },
  "commands": {
    "translate": "^hvordan sier du (.*)\\?",
    "repeat": "si det igjen",
    "repeatSlowly": "si det sakte",
    "retry": "gå tilbake"
  }
}
