import {LanguageFile} from "../helpers/language";

export const it: LanguageFile = {
  "code": "it",
  "localCode": "it-IT",
  "name": "italian",
  "displayName": "Italian",
  "voices": ["it-IT-Standard-A"],
  "iconURL": "it.png",
  "phrases": [
    {"phrase": "About myself", "targetLanguagePhrase": "Vorrei parlare di me stesso.", "emoji": "🧑"},
    {"phrase": "Favorite Italian dish", "targetLanguagePhrase": "Vorrei parlare del mio piatto italiano preferito.", "emoji": "🍝"},
    {"phrase": "About my pet", "targetLanguagePhrase": "Vorrei parlare del mio animale domestico.", "emoji": "🐾"},
    {"phrase": "Favorite season", "targetLanguagePhrase": "Vorrei parlare della mia stagione preferita.", "emoji": "🌦"},
    {"phrase": "About music", "targetLanguagePhrase": "Vorrei parlare di musica.", "emoji": "🎵"},
    {"phrase": "Favorite book", "targetLanguagePhrase": "Vorrei parlare del mio libro preferito.", "emoji": "📚"},
    {"phrase": "Dream vacation", "targetLanguagePhrase": "Vorrei parlare della mia vacanza da sogno.", "emoji": "🌴"},
    {"phrase": "About sports", "targetLanguagePhrase": "Vorrei parlare di sport.", "emoji": "⚽"},
    {"phrase": "About a film I've recently watched", "targetLanguagePhrase": "Vorrei parlare di un film che ho visto di recente.", "emoji": "🎬"},
    {"phrase": "Favorite type of coffee", "targetLanguagePhrase": "Vorrei parlare del mio tipo di caffè preferito.", "emoji": "☕"},
    {"phrase": "About my hobbies", "targetLanguagePhrase": "Vorrei parlare dei miei hobby.", "emoji": "🎨"},
    {"phrase": "About my job", "targetLanguagePhrase": "Vorrei parlare del mio lavoro.", "emoji": "💼"},
    {"phrase": "About gardening", "targetLanguagePhrase": "Vorrei parlare di giardinaggio.", "emoji": "🌿"},
    {"phrase": "About fashion", "targetLanguagePhrase": "Vorrei parlare di moda.", "emoji": "👗"},
    {"phrase": "Favorite childhood memory", "targetLanguagePhrase": "Vorrei parlare del mio ricordo d'infanzia preferito.", "emoji": "🧸"},
    {"phrase": "About technology", "targetLanguagePhrase": "Vorrei parlare di tecnologia.", "emoji": "💻"},
    {"phrase": "About my favorite city", "targetLanguagePhrase": "Vorrei parlare della mia città preferita.", "emoji": "🏙"},
    {"phrase": "About my favorite car", "targetLanguagePhrase": "Vorrei parlare della mia auto preferita.", "emoji": "🚗"},
    {"phrase": "About sustainability", "targetLanguagePhrase": "Vorrei parlare di sostenibilità.", "emoji": "🌍"},
    {"phrase": "About baking", "targetLanguagePhrase": "Vorrei parlare di pasticceria.", "emoji": "🧁"},
    {"phrase": "Favorite workout routine", "targetLanguagePhrase": "Vorrei parlare della mia routine di allenamento preferita.", "emoji": "🏋️"},
    {"phrase": "About photography", "targetLanguagePhrase": "Vorrei parlare di fotografia.", "emoji": "📸"},
    {"phrase": "About my favorite video game", "targetLanguagePhrase": "Vorrei parlare del mio videogioco preferito.", "emoji": "🎮"},
    {"phrase": "About a concert I've attended", "targetLanguagePhrase": "Vorrei parlare di un concerto a cui ho assistito.", "emoji": "🎤"},
    {"phrase": "About a hiking trip", "targetLanguagePhrase": "Vorrei parlare di un'escursione che ho fatto.", "emoji": "🥾"},
    {"phrase": "About my favorite cocktail", "targetLanguagePhrase": "Vorrei parlare del mio cocktail preferito.", "emoji": "🍹"},
    {"phrase": "About astronomy", "targetLanguagePhrase": "Vorrei parlare di astronomia.", "emoji": "🌌"},
    {"phrase": "About an inspiring person in my life", "targetLanguagePhrase": "Vorrei parlare di una persona che mi ispira nella mia vita.", "emoji": "💫"},
    {"phrase": "About a language I'm learning", "targetLanguagePhrase": "Vorrei parlare di una lingua che sto imparando.", "emoji": "🌐"},
    {"phrase": "About my favorite piece of art", "targetLanguagePhrase": "Vorrei parlare del mio capolavoro preferito.", "emoji": "🎨"}
  ],
  "questionPrompts": {
    "meaning": ["Cosa significa __*__?", "Cosa significa?"],
    "correct": ["È corretto __*__?", "È corretto?"],
    "alternative": ["Intendevi qualcosa di diverso da __*__?", "Intendevi qualcos'altro?"],
    "intentPrompt": ["Cosa stavi cercando di dire invece di __*__? Ti dirò come dirlo in __target_lang__.", "Cosa stavi cercando di dire? Ti dirò come dirlo in __target_lang__."],
    "mistakePrompt": ["Quali sono gli errori nella frase __*__", "Quali sono gli errori in questa frase?"]
  },
  "commands": {
    "translate": "^come si dice (.*)\\?",
    "repeat": "ripeti quello",
    "repeatSlowly": "dillo lentamente",
    "retry": "torna indietro"
  }
}
