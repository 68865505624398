import {SupportedLanguageCodes} from "interfaces/SupportedLanguages";
import _, { get } from "lodash";
import {useState} from "react";
import {Button} from "./Button";
import {Form, SubmitButton, TextField} from "./Form";
import { getLanguages } from "../helpers/language";

interface ChooseLanguageProps { 
  onSubmit: (languageCode: SupportedLanguageCodes) => void,
  omit?: SupportedLanguageCodes[],
}

const NewLanguageForm: React.FC = () => <Form name='missing-language'>
  <TextField
    name={"language"}
    placeholder={"french"}
    type={"text"}
    required
  >Type your language</TextField>
  <SubmitButton>submit</SubmitButton>
</Form>

const ChooseLanguage: React.FC<ChooseLanguageProps> = function({ onSubmit, omit = [] }) {
  const [formIsOpen, setFormIsOpen] = useState(false)
  const languages = getLanguages()

 return (
    <>
        <ul className="mb-8 text-left text-gray-500 space-y-4 dark:text-gray-400">
        { _.values(languages)
          .filter(l => !omit.some(o => o === l.code))
          .map((language) => (
          <li 
            className="flex" 
            key={`${language.code}-item`}
            onClick={(e) => onSubmit(language.code)}
          >
            <div className="flex items-center cursor-pointer space-x-3">
              <img
                src={language.iconURL}
                alt={language.name}
                className="w-8 h-8 rounded-full"
              />
              <span className="">{language.name}</span>
            </div>
          </li>))
        }
        </ul>
        {/*
          {!formIsOpen 
            ? <Button onClick={() => setFormIsOpen(!formIsOpen)}>
              Missing your language?
            </Button>
            : <NewLanguageForm />
          }
        */ }
    </>
  );
 }

export {ChooseLanguage}
