import {FC, PropsWithChildren, ReactNode, useEffect, useRef, useState} from "react"

type MenuItemProps = {
  onClick: () => void,
  id?: string | number,
  danger?: boolean,
}
export const DDMenuItem: FC<PropsWithChildren<MenuItemProps>> = ({children, onClick, id, danger}) => {
  return <li key={id}>
    <a
      className={`block mx-2 px-4 py-2 transition-colors rounded hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer ${danger ? 'bg-red-500 text-white hover:bg-red-600' : ''}`}
      onClick={(e) => {e.preventDefault(); onClick()}}    >
      {children}
    </a>
  </li>
}
type DDPosition = 'left' | 'right' | 'bottomLeft' | 'bottomRight'

type Props = {
  position: DDPosition, 
  children: (props: any) => ReactNode,
  renderButton: (props: { onClick: () => void, buttonRef: React.Ref<HTMLButtonElement> }) => React.ReactElement,
  onOpen?: () => void,
}

export const DropDownMenu: FC<Props> = ({children, position, renderButton, onOpen }) => {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent | TouchEvent) {
      if (buttonRef.current && !buttonRef.current.contains(event.target as Node)) {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
          setIsOpen(false)
        }
      }
    }

    // Attach the listeners on component mount.
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    // Detach the listeners on component unmount.
    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
        document.removeEventListener("touchstart", handleClickOutside);
    };
  }, []); 

  const toggleMenu = () => {
    if (onOpen && !isOpen) onOpen()
    setIsOpen(open => !open)
  }
  
  const ButtonWithRefAndOnClick = renderButton({ onClick: toggleMenu, buttonRef })
  const positionalClasses = ' ' + {
    left: 'top-0 mx-2 right-full',
    right: 'top-0 mx-2 left-full',
    bottomLeft: 'right-0 my-2',
    bottomRight: 'my-2',
  }[position]

  return <div className="relative">
    {ButtonWithRefAndOnClick}
    {isOpen && <div
      id="dropdownDotsHorizontal"
      className={"absolute z-10 bg-white rounded-lg shadow divide-y divide-gray-100 min-w-44 dark:bg-gray-900 dark:divide-gray-600" + positionalClasses}
      ref={dropdownRef}
    >
        <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownMenuIconHorizontalButton">
          {children(toggleMenu)}
        </ul>      
      </div>}
    </div>
}
