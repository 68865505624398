import _ from "lodash";

export enum LessonEventType {
  ASSISANT_MESSAGE = 'ASSISANT_MESSAGE',
  USER_MESSAGE = 'USER_MESSAGE',
  TRANSLATION_REQUEST = 'TRANSLATION_REQUEST',
  VOCABULARY_REQUEST = 'VOCABULARY_REQUEST',
  VOCABULARY_RECORD_REQUEST = 'VOCABULARY_RECORD_REQUEST',
  REPETITION_REQUEST = 'REPETITION_REQUEST',
  RETRY_REQUEST = 'RETRY_REQUEST',
  BASE = 'BASE',
}

export type LessonEvent = 
  AssistantMessageLessonEvent 
  | UserMessageLessonEvent 
  | RepetitionRequestLessonEvent
  | TranslationRequestLessonEvent
  | RetryRequestLessonEvent
  | VocabularyRequestLessonEvent
  | VocabularyRecordRequestLessonEvent

export interface LessonEventBase {
  readonly type: LessonEventType
  readonly id: Date
  data(): string
}

export interface MessageComponent {
  message: string;
}

export interface SpeedComponent {
  speed: 'normal' | 'slow';
}

export interface LinkedComponent {
  messageId: string;
  phrase: string;
}

export interface TranslationComponent {
  userLanguagePhrase: string;
  targetLanguagePhrase: string;
}

export class AssistantMessageLessonEvent implements LessonEventBase {
  public readonly type = LessonEventType.ASSISANT_MESSAGE
  constructor(
    public readonly messageComponent: MessageComponent,
    public readonly id: Date,
  ) {}

  public data(): string {
    return this.messageComponent.message
  }
}

export class UserMessageLessonEvent implements LessonEventBase {
  public readonly type = LessonEventType.USER_MESSAGE
  constructor(
    public readonly messageComponent: MessageComponent,
    public readonly id: Date,
  ) { }
  public data(): string {
    return this.messageComponent.message
  }
}

export class RepetitionRequestLessonEvent implements LessonEventBase {
  public readonly type = LessonEventType.REPETITION_REQUEST
  constructor(
    public readonly id: Date,
    public readonly linkedComponent: LinkedComponent,
    public readonly speedComponent: SpeedComponent
  ) { }
  
  public data(): string {
    return this.linkedComponent.phrase
  }
}

export class TranslationRequestLessonEvent implements LessonEventBase {
  public readonly type = LessonEventType.TRANSLATION_REQUEST
  constructor(
    public readonly id: Date,
    public readonly linkedComponent: LinkedComponent,
    public readonly translationComponent: TranslationComponent
  ) {}

  public data(): string {
    return JSON.stringify({
      from: this.translationComponent.targetLanguagePhrase,
      to: this.translationComponent.userLanguagePhrase,
    })
  }
}

export class RetryRequestLessonEvent implements LessonEventBase {
  public readonly type = LessonEventType.RETRY_REQUEST
  constructor(
    public readonly linkedComponents: LinkedComponent[],
    public readonly id: Date,
  ) { }

  public data(): string {
    return JSON.stringify(this.linkedComponents.map(l => l.messageId))
  }
}

export class VocabularyRequestLessonEvent implements LessonEventBase {
  public readonly type = LessonEventType.VOCABULARY_REQUEST
  constructor(
    public readonly id: Date,
    public readonly translationComponent: TranslationComponent,
  ) { }

  public data(): string {
    return this.translationComponent.targetLanguagePhrase
  }
}

export class VocabularyRecordRequestLessonEvent implements LessonEventBase {
  public readonly type = LessonEventType.VOCABULARY_RECORD_REQUEST
  constructor(
    public readonly id: Date,
    public readonly messageComponent: MessageComponent,
  ) { }

  public data(): string {
    return this.messageComponent.message
  }
}
