import {FC, PropsWithChildren} from "react"

type Props = {
  onClick?: () => void
}

export const Card: FC<PropsWithChildren<Props>> = ({children, onClick, ...props}) => {
  return (
    <div {...props} onClick={onClick} className={ 
      `w-full 
      grow 
      bg-white 
      rounded-lg shadow 
      cursor-pointer 
      dark:border dark:bg-gray-800 dark:border-gray-700` 
      }>
      <div className="px-6 py-4">
        {children}
      </div>
    </div>
  )
}
