import {SupportedLanguageCodes} from "interfaces/SupportedLanguages"
import {FC} from "react"

type Props = {
  targetLanguageCode: SupportedLanguageCodes
}

export const LanguageIcon: FC<Props> = ({targetLanguageCode}) => {
  const imageSrc = `/${targetLanguageCode}.png`
  return ( <img src={imageSrc} alt={targetLanguageCode} className="flex-shrink-0 inline-block w-5 h-5 rounded-full" />)
}
