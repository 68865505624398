import {faCircleCheck} from "@fortawesome/free-solid-svg-icons"
import {faCircle} from "@fortawesome/free-regular-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {FC} from "react"
import {HistoricalLesson} from "../routes/History"

const dayLetter = (i: number) => {
  return [
    "M",
    "T",
    "W",
    "T",
    "F",
    "S",
    "S"
  ][i]
} 

type DayProps = {
  day: string,
  checked?: boolean,
}
const Day: FC<DayProps> = ({ day, checked }) => {
  return <li className={`flex flex-col items-center px-1 w-6`}>
      <div className={`text-${checked ? 'green-600' : 'main' }`}>
        {checked 
          ? <FontAwesomeIcon icon={faCircleCheck} fixedWidth />
          : <FontAwesomeIcon icon={faCircle} fixedWidth />
        }
      </div>
      <p className={`text-gray-800 text-xs dark:text-gray-200`}>{day}</p>
    </li>  
}

type Props = {
  lessons: HistoricalLesson[]
}
export const WeeklyStreak: FC<Props> = ({ lessons }) => {
  const lessonDays = new Array(7).fill(false)
    .map((_, i) => lessons.some(l => l.finishedAt.getDay()-1 === i))
    .map((checked, i) => ({ day: dayLetter(i), checked }))

  return <ul className={`flex flex-row`}>
    {lessonDays.map((l, i) => <Day day={l.day} checked={l.checked} key={i}></Day>)}
  </ul>
}
