import React from "react"
import {HtmlHTMLAttributes} from "react"

type Props = { } & HtmlHTMLAttributes<HTMLButtonElement>

export const DropDownDots = React.forwardRef<HTMLButtonElement, Props>(
  (props, ref) => {
    return <button 
          id="dropdownMenuIconHorizontalButton" 
          data-dropdown-toggle="dropdownDotsHorizontal" 
          className={`
            inline-flex items-center 
            p-2 
            text-sm text-gray-900 text-center 
            font-medium 
            bg-gray-200
            rounded-full
            transition-colors
            hover:bg-gray-300
            focus:ring-4 focus:outline-none focus:ring-gray-50 
          `}
          type="button"
          { ...props }
          ref={ref}
        > 
          <svg 
            className="w-6 h-6" 
            aria-hidden="true" 
            fill="currentColor" 
            viewBox="0 0 20 20" 
            xmlns="http://www.w3.org/2000/svg">
              <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"></path>
          </svg>
        </button>
})
