import {faChevronDown, faList} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {FC, useState} from "react"
import {LessonVocabulary} from "../contexts/LessonContext"
import {DDMenuItem, DropDownMenu} from "./DropDownMenu"
import {VocabularyIcon} from "./VocabularyIcon"
import { CircleCounter } from "./CircleCounter"

type Props = {
  vocabulary: LessonVocabulary[]
}

export const VocabularyDropDown: FC<Props> = ({ vocabulary }) => {
  const reviewedVocabulary = vocabulary.filter(v => v.reviewed)
  const newVocabulary = vocabulary.filter(v => v.addedDuringLesson)

  // state for submenu
  const [menuIndex, setMenuIndex] = useState(0)

  const buildVocabularyItemBuilder = (toggle: () => void) => (vocabularyItem: LessonVocabulary) => <DDMenuItem onClick={() => toggle()} key={vocabularyItem.id}>
      <div className="flex items-center justify-start">
        <FontAwesomeIcon icon={faList} fixedWidth className="pr-2 text-gray-500" />
        <p>{vocabularyItem.text}</p>
      </div>
    </DDMenuItem> 

  const reviewedVocabularyMenu = (toggle: () => void) => <div className="flex flex-col w-full">
    {reviewedVocabulary.map(buildVocabularyItemBuilder(toggle))}
  </div>

  const inLessonVocabularyMenu = (toggle: () => void) => <div className="flex flex-col w-full">
    {newVocabulary.map(buildVocabularyItemBuilder(toggle))}
  </div>

  const counters = [
    <CircleCounter color="main" count={reviewedVocabulary.length} />,
    <CircleCounter color="green" count={newVocabulary.length} />
  ]

  return <DropDownMenu
    position='bottomRight'
    renderButton={({onClick, buttonRef}) => 
      <VocabularyIcon 
        totalCount={reviewedVocabulary.length}
        notificationCount={newVocabulary.length} 
        ref={buttonRef} 
        onClick={onClick} />
      }
  >
    {toggle => <>
      <div className={`font-bold`}>
        <div className="flex items-center">
          <div className="pl-4">
            {counters[menuIndex]}
          </div>
          <span className="p-4">{menuIndex === 0 ? "Reviewed Vocabulary" : "In Lesson Vocabulary"}</span>
          <DropDownMenu 
            position='bottomRight' 
            renderButton={({ onClick, buttonRef }) => 
              <FontAwesomeIcon 
                icon={faChevronDown} 
                fixedWidth 
                className="pr-4 text-gray-900" 
                onClick={onClick}
              />}
          >
            {toggle => ["Reviewed Vocabulary", "In Lesson Vocabulary"].map((title, i) => 
            <DDMenuItem onClick={() => { toggle(); setMenuIndex(i) }} key={'reviewed'}>
              <div className="font-normal whitespace-nowrap">
                <div className="inline-block pr-2">
                  {counters[i]}
                </div>
                {title}
              </div>
            </DDMenuItem>) 
              }
            </DropDownMenu>
        </div>
      </div>
      <div className="flex flex-row">
        {menuIndex === 0 
          ? reviewedVocabularyMenu(toggle)
          : inLessonVocabularyMenu(toggle)}
      </div>
    </>}
  </DropDownMenu>
}
