import { useAudioRecorder } from 'react-audio-voice-recorder';
import { useState, useEffect, useRef } from "react";
import { MicButton } from './MicButton';
import { MessageTextInput } from './MessageTextInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPauseCircle, faPlayCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ChatMessageSource } from '../types/ChatMessage';
import { TranscriptionResult, TranscriptionResultError, TranscriptionResultMessage, useTranscriber } from '../hooks/use-transcriber';

type Props = {
  sendMessage: (message: string, source?: ChatMessageSource) => void,  
  stopAllAudio: () => void,
  isEnabled: boolean,
}

export const ChatInput: React.FC<Props> = ({
  sendMessage,
  stopAllAudio,
  isEnabled,
}) => {
  const [isHandsFreeRecording, setHandsFreeRecording] = useState(false);
  const [hasCancelled, setCancelled] = useState(false);
  const { transcribe } = useTranscriber();
  const [transcribedText, setTranscribedText] = useState("");  // New state for transcribed text
  const [isTranscribing, setIsTranscribing] = useState(false);  // New state for tracking transcription process

  const inputRef = useRef<HTMLInputElement>(null);

  const { isRecording, startRecording: _startRecording, stopRecording, recordingBlob, recordingTime, togglePauseResume, isPaused } = useAudioRecorder();

  const startRecording = function() {
    setCancelled(false);
    stopAllAudio();
    _startRecording();
  }

  const cancelRecording = function() {
    setCancelled(true);
    stopRecording();
  }

  useEffect(() => {
    if (!recordingBlob) return;
    if (hasCancelled) return;

    const transcribeRecording = async () => {
      setIsTranscribing(true);  // Start the transcription process
      const t: TranscriptionResult = await transcribe(recordingBlob);
      if (t.type === 'message') {
        const msg = (t as TranscriptionResultMessage).message;
        setTranscribedText(msg);  // Set transcribed text in input field
      } else if (t.type === 'error') {
        const err = (t as TranscriptionResultError).e;
        console.error(err);
      }
      setIsTranscribing(false);  // End the transcription process
    };

    transcribeRecording();
  }, [recordingBlob, hasCancelled]);

  const d = new Date(0,0,0,0,0,recordingTime);
  const padding = d.getSeconds() < 10 ? "0" : "";
  const formattedRecordingTime = d.getMinutes() + ":" + padding + d.getSeconds();

  return (
    <section className="container flex items-center p-2 mx-auto bg-gray-100 rounded-lg">
      {(isRecording) 
        ? <div className="flex items-center w-full p-2">
            {isHandsFreeRecording && <button className={'text-xl text-gray-500 px-2 hover:drop-shadow-lg hover:text-gray-600 transition:colors'}
              onClick={(e) => {
                e.preventDefault();
                cancelRecording();
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>}

            <p className={"w-12 h-full text-gray-500 align-middle leading-8 " + (isPaused ? "text-red-300 animate-pulse" : "")}>
              {formattedRecordingTime}
            </p>

            {isHandsFreeRecording && <div className={'flex grow shrink:0 justify-center'}>
              <button className={'text-2xl text-red-300 px-2 hover:drop-shadow-lg hover:text-red-400 transition:colors'}
              onClick={(e) => {
                e.preventDefault();
                togglePauseResume();
              }}>
                {isPaused 
                  ? <FontAwesomeIcon icon={faPlayCircle} />
                  : <FontAwesomeIcon icon={faPauseCircle} />
                }
              </button> 
            </div>}
        </div>
        : <MessageTextInput 
            inputRef={inputRef} 
            isEnabled={isEnabled} 
            sendMessage={(message: string) => sendMessage(message)}
            value={transcribedText}  // Pass transcribed text
            onChange={(text) => setTranscribedText(text)}  // Handle text changes
            isTranscribing={isTranscribing}  // Pass transcription state
          />
      }
      <div className='px-2'>
        <MicButton 
          isEnabled={isEnabled} 
          isRecording={isRecording} 
          startRecording={startRecording} 
          stopRecording={stopRecording}
          cancelRecording={cancelRecording}
          setHandsFreeRecording={setHandsFreeRecording}
        />
      </div>
    </section>
  );
}
