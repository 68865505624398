import {FC, } from "react";
import { Auth } from '@supabase/auth-ui-react'
import { SupabaseClient } from "@supabase/supabase-js";
import { ThemeSupa, ViewType } from "@supabase/auth-ui-shared";
import { FormWrapper } from "../components/FormWrapper";
import { useLocation } from "react-router-dom";

type Props = { supabaseClient: SupabaseClient }

export const SignIn: FC<Props> = ({supabaseClient}) => {
  const location = useLocation();
  let view = location.state?.authview === "sign_up" 
    ? "sign_up" 
    : "sign_in" as ViewType;

  return <FormWrapper title={"Lingful"}>
    <Auth supabaseClient={supabaseClient} 
      appearance={{theme: ThemeSupa}}
      providers={['google']}
      view={view}
      redirectTo={`${window.location.origin}/login`} // this would not work for server side rendering
    />
    </FormWrapper>

}
