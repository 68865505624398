import React, {FC, PropsWithChildren, useState} from 'react';

export type ErrorContextType = {
  setError: (err: Error | null) => void,
  error: Error | null,
};
  
export const ErrorContext = React.createContext<ErrorContextType | undefined >(undefined);

export const ErrorProvider: FC<PropsWithChildren> = ({ children }) => {
  const [error, setError] = useState<Error | null>(null)

  return <ErrorContext.Provider value={{ error, setError }}>
    {children} 
  </ErrorContext.Provider>
}


export const useError = () => {
  const ctx = React.useContext(ErrorContext)
  if (ctx === undefined) {
    throw new Error("error context must be defined")
  }

  return ctx
}

