import React, {FC, PropsWithChildren, useContext, useEffect, useState} from 'react';
import {LingoUser} from '..';
import { AuthChangeEvent, Session, SupabaseClient } from '@supabase/supabase-js'
import {UserClient} from '../clients/user';
import _ from 'lodash';
import { getLanguages } from '../helpers/language';

type AuthContextMethods = {
  setUser: (u: LingoUser) => void;
  logout: () => Promise<boolean>,
  refreshUser: () => Promise<void>,
}

type LoadedAuthContext = {
  loadingUser: false,
  user: LingoUser,
  session: Session | null,
  isUserReady: boolean,
}

type UnloadedAuthContext = {
  loadingUser: true,
  user: null,
  session: Session | null,
  isUserReady: boolean,
}

type AuthType = LoadedAuthContext | UnloadedAuthContext

export type AuthContextType = AuthType & AuthContextMethods;

export const AuthContext = React.createContext<AuthContextType>({
  user: null,
  setUser: () => {
    // noop
  },
  loadingUser: true,
  logout: async () => {
    return false
  },
  session: null,
  refreshUser: async () => {},
  isUserReady: false,
});

type ProviderProps = {
  supabaseClient: SupabaseClient,
  onUserUpdated?: () => void
  onInitialSignIn?: () => void
}
export const AuthProviderSB: FC<PropsWithChildren<ProviderProps>> = ({ children, supabaseClient }) => {
  const languages = getLanguages()
  const [session, setSession] = useState<Session | null>(null)
  const [authState, setAuthState] = useState<AuthChangeEvent>()
  const [user, setUser] = useState<LingoUser | null>(null);
  const [loadingUser, setLoadingUser] = useState<boolean>(true);
  const [isInPasswordRecoveryFlow, setInPasswordRecoveryFlow] = useState<boolean>(false);

  const fetchUserData = async function (token: string): Promise<LingoUser> {
    const user = await UserClient.get(token)
    return { 
      ...user,
      userLanguage: languages[user.userLanguageCode],
      targetLanguage: user.targetLanguageCode ? languages[user.targetLanguageCode] : null,
    }
  }

  const refreshUser = async () => {
    const token = session?.access_token
    if (!token) throw new Error("user must be logged in to refresh")
    if (!user) throw new Error("user must be logged in to refresh")

    const refreshedUser = await fetchUserData(token)

    setUser(refreshedUser)
  }

  const logout = async function (): Promise<boolean> {
    const { error } = await supabaseClient.auth.signOut({ scope: 'local' })
    if (error) {
      console.error(error)
      return false
    }
    setUser(null)

    return true
  }

  useEffect(() => {
    if (!authState) return;

    if (authState == 'PASSWORD_RECOVERY') {
      setInPasswordRecoveryFlow(true)
    }

    if (authState == 'USER_UPDATED' || authState == 'SIGNED_OUT') {
      setInPasswordRecoveryFlow(false)
    }

   }, [authState])

  useEffect(() => {
    const run = async () => {
      if (!session) return;
      try {
        const lingoUser = await fetchUserData(session.access_token)
        setUser(user => !_.isEqual(lingoUser, user) ? lingoUser : user)
        setLoadingUser(false)
      } catch (e) {
        console.error(e)
        setUser(null)
        setLoadingUser(false)
      }
    }

    run()
  }, [session])


  useEffect(() => {
    supabaseClient.auth.getSession().then(({ data: { session: newSession } }) => {
      setSession(oldSession => !_.isEqual(oldSession, newSession) ? newSession : oldSession)
    })

    const { data: { subscription } } = supabaseClient.auth.onAuthStateChange((async (event: AuthChangeEvent, newSession: Session | null): Promise<void> => {
      setAuthState(event)
      if (newSession) {
        setSession(oldSession => !_.isEqual(oldSession, newSession) ? newSession : oldSession);
      } else {
        setUser(null);
        setLoadingUser(false);
      }
    }))

    return () => subscription.unsubscribe();
  }, [])

  if (loadingUser) {
    return <AuthContext.Provider value={{user: null, setUser, loadingUser: true, logout, session, refreshUser, isUserReady: !!user && !isInPasswordRecoveryFlow }}>
      {children} 
    </AuthContext.Provider>
  }

  return <AuthContext.Provider value={{user: user!, setUser, loadingUser: false, logout, session, refreshUser, isUserReady: !!user && !isInPasswordRecoveryFlow }}>
    {children} 
  </AuthContext.Provider>
}

export const useAuth = () => useContext(AuthContext)
