import { MotionBox } from './MotionBox';
import { MotionZoomIn } from './MotionZoomIn';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as BotIcon } from '../images/chatbot-icon.svg'
import _, { get } from 'lodash';
import { authViewStates } from '../constants/links';
import { getLanguages } from '../helpers/language';

const Lingful = <span className="font-bold text-main">Lingful</span>

const LandingInfo: React.FC = function() {
  const languages = getLanguages();
  const languagesList = _.values(languages).slice(0, 18)
  
  // Framer Animations - can be imported in from 1 variants js file

  const isMobile = window.innerWidth < 768;

  const fadeLeft = {
    visible: { opacity: 1, x: 0, transition:{duration: 2, type: "spring"} },
    hidden: { display: 'hidden', opacity: 0, x: "-100%" },
  };

  const fadeRight = {
    visible: { opacity: 1, x: 0, transition:{duration: 2, type: "spring"} },
    hidden: { display: 'hidden', opacity: 0, x: "100%" }
  }

  const fadeUpRapid = {
    visible: { opacity: 1, y: 0, transition:{duration: 0.5} },
    hidden: { opacity: 0, y: "100%" },
  }

  const navigate = useNavigate();

  return (
    <div className="flex flex-col">
      {/* first page section*/}
      <section className="flex flex-col justify-center h-screen isolate lg:px-24 bg-main">
        <div className="container flex-grow px-5 mx-auto grid grid-cols-1 md:grid-cols-2 gap-4 md:px-10">
          <div className="flex flex-col items-center md:justify-center md:items-start">
            <div>
              <h1 className="mt-6 text-6xl font-bold text-center text-gray-100 md:text-start md:text-5xl">
                Lingful.app
              </h1>
              <p className="text-sm font-bold text-right text-green-600">Beta</p>
            </div>
            <h3 className="mt-2 text-gray-300 text-md md:text-start">
              The fully automated language tutor.
            </h3>
            <h2 className="mt-6 text-2xl font-bold text-center text-gray-100 md:text-start md:text-5xl">
              <span className="text-green-600">Practice.{" "}</span> Anytime. Anywhere.
            </h2>
            <div>
              <div className="flex flex-wrap justify-center">
                {languagesList.map(l => <img 
                  key={l.iconURL}
                  className="w-5 h-5 m-2 rounded-full" 
                  src={l.iconURL}/>
                )}
              </div>
            </div>
            <div className="flex flex-col items-center justify-between w-full mt-10 md:items-start gap-y-6">
              <a
                onClick={() => navigate("/login", authViewStates.sign_up)}
                className="w-full max-w-xs rounded-full bg-green-600 px-3.5 py-2.5 text-md text-center font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
              >
                <span> Sign up for an account </span>
                <span className="inline-block text-sm text-gray-200">(free during beta!)</span>
              </a>
              <a
                onClick={() => navigate("/login", authViewStates.sign_in)}
                className="w-full max-w-xs rounded-full bg-slate-200 px-3.5 py-2.5 text-md text-center font-semibold text-black shadow-sm hover:bg-slate-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
              >
                Log in
              </a>
            </div>
          </div>
          <div className='flex justify-center order-first md:order-last md:p-6'>
            <BotIcon className='w-24 md:w-72 fill-slate-200'></BotIcon>
          </div>
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
      </section>

      <section className='container px-4 py-16 mx-auto'>
        <h2 className='mb-12 text-5xl font-bold text-center text-sky-700 md:mb-24'>
          Speaking Practice on the go
        </h2>
        <div className='mt-10 text-center grid grid-cols-1 md:grid-cols-3'>
          <div className="flex flex-col items-center justify-center"> 
            <MotionZoomIn 
              key={1}
              src="mobile-chat.png" 
              alt="temp mobile phone picture"
              variants={fadeUpRapid}
              />
            <h4 className="my-4 text-2xl font-bold">
              Easy to use
            </h4>
            <p className="px-10 mb-12">
              Just talk, and {Lingful} talks back.
            </p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <MotionZoomIn 
              key={2} 
              src="ai.svg" 
              alt="ai robot"
              variants={fadeUpRapid}
            />
            <h4 className="my-4 text-2xl font-bold">
              Chat about anything
            </h4>
            <p className="px-10 mb-12">
              Choose from our custom conversation starters, or start chatting about what's on your mind.
            </p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <MotionZoomIn 
              key={3} 
              src="temparrow.png" 
              alt="temp arrow picture"
              variants={fadeUpRapid}
            />
            <h4 className="my-4 text-2xl font-bold">
              Boost Your Level
            </h4>
            <p className="px-10 mb-12">
              {Lingful} remembers the vocabulary you found difficult, and prompts you with it in future lessons.
            </p>
          </div>
        </div>
        <div className='items-center justify-center py-10 overflow-hidden grid grid-cols-1 md:grid-cols-2' >
          <MotionBox
            key={4}  
            src={"mobile-chat.png"} 
            alt={"chat picture"}
            variants={isMobile? fadeLeft : fadeUpRapid}
            />
          <h3 className='text-2xl text-center md:text-3xl'>
            Need a 5 minute lesson during your daily commute? Learn <strong>wherever</strong> and <strong>whenever</strong> you want.
          </h3>
        </div>
        <div className='items-center justify-center py-10 overflow-hidden grid grid-cols-1 md:grid-cols-2'>
          <h3 className='order-last text-2xl text-center md:text-3xl md:order-first'>
            {Lingful} will learn the <strong>vocabulary</strong> and <strong>grammar</strong> perfect for you, and gently introduce them in conversation. 
          </h3>
          <MotionBox
            key={5}  
            src={"mobile-vocab.png"} 
            alt={"vocabulary picture"}
            variants={isMobile? fadeRight : fadeUpRapid} 
            />
        </div>
        <div className='items-center justify-center py-10 overflow-hidden grid grid-cols-1 md:grid-cols-2'>
          <MotionBox
            key={6} 
            src={"mobile-lessons.png"} 
            alt={"lessons picture"}
            variants={isMobile? fadeLeft : fadeUpRapid} 
            />
          <h3 className='text-2xl text-center md:text-3xl'>
            The more you use {Lingful}, the smarter it gets.
          </h3>
        </div>
      </section>

      <div className='bg-main'>
        <section className='container py-24 mx-auto '>
          <h2 className='mb-12 text-4xl font-bold text-center text-gray-100 md:text-5xl md:mb-24'>
            Perfect your Next Language
          </h2>
          <div className='flex flex-wrap items-center justify-between mb-12 gap-12 md:mb-24'>
            {languagesList.map(lang => 
              <img 
                key={lang.code}
                className="object-cover w-32 h-32 m-auto rounded-full shadow-lg cursor-pointer hover:scale-110 hover:contrast-200 transition-all shadow-slate-800/50" 
                src={lang.iconURL}
                alt={`${lang.name} flag`} 
              />
            )}
          </div>

          <div className='items-center justify-between grid grid-cols-1 md:grid-cols-3'>
            <div className="p-5 m-5 bg-gray-300 card rounded-md">
              <img className='rounded-lg shadow-lg align-self-center justify-self-center shadow-slate-800/50' src="travel.jpg" alt="" />
              <h1 className="mt-5 mb-3 text-3xl font-bold text-center text-sky-800">For Travel</h1>
              <p className='px-4 text-center text-1xl text-sky-800'>
                Ask {Lingful} to teach you travel-phrases and local expressions. Or roleplay a trip to the restaurant!
              </p>
            </div>
            <div className="p-5 m-5 bg-gray-300 card rounded-md">
              <img className='rounded-lg shadow-lg align-self-center justify-self-center shadow-slate-800/50' src="social.jpg" alt="" />
              <h1 className="mt-5 mb-3 text-3xl font-bold text-center text-sky-800">For Fun</h1>
              <p className='px-4 text-center text-1xl text-sky-800'>
                Ask {Lingful} to speak casually, and teach you slang or day-to-day expressions.
              </p>
            </div>
            <div className="p-5 m-5 bg-gray-300 card rounded-md">
              <img className='rounded-lg shadow-lg align-self-center justify-self-center shadow-slate-800/50' src="work.jpg" alt="" />
              <h1 className="mt-5 mb-3 text-3xl font-bold text-center text-sky-800">For Work</h1>
              <p className='px-4 text-center text-1xl text-sky-800'>
                Ask {Lingful} to go over a problem you're having at work, or roleplay a meeting or technical report.
              </p>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center mx-auto mt-10 gap-12">
            <h2 className='my-0 text-4xl font-bold text-center text-gray-100 md:text-5xl'>
              Get Started Today!
            </h2>
            <a
              onClick={() => navigate("/login", authViewStates.sign_up)}
              className="md:w-72 rounded-full bg-green-600 px-3.5 py-2.5 text-md text-center font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
            >
                  Sign up for an account
            </a>
          </div>

          {/* footer */}
          <div className="flex flex-col items-center justify-center py-8 mt-12 text-gray-100 gap-2">
              <p>© Lingful 2024</p>
              <p><a href={"privacy-policy"} className="hover:text-green-500 transition-colors"> Privacy Policy </a></p>
          </div>
        </section>
      </div>
    </div>
  )
}

export {LandingInfo}
