import {isLeft} from 'fp-ts/lib/Either'
import {LessonDTO} from 'interfaces/Lesson'
import {ReviewDTO, ReviewDTOValidator} from 'interfaces/Review'
import {LessonDTOValidator} from 'interfaces/Lesson'
import {HistoricalLesson} from '../routes/History'
import {PathReporter} from 'io-ts/lib/PathReporter'
import * as t from 'io-ts'
import {LessonEvent} from '../types/LessonEvent'
import {SupportedLanguageCodes} from 'interfaces/SupportedLanguages'
import {useAuth} from '../contexts/AuthContext'
import _ from 'lodash'
import {ensureToken } from '../helpers/clients'

const get = async (token: string, lessonId: string): Promise<HistoricalLesson> => {
  const response = await fetch(`/.netlify/functions/get-lesson/${lessonId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  if (!response.ok) {
    throw new Error(response.statusText)
  }

  const { lessonData } = await response.json()

  const decoded = LessonDTOValidator.decode(lessonData)
  if (isLeft(decoded)) {
    throw new Error(`Could not validate data: ${PathReporter.report(decoded).join("\n")}`)
  }

  const lesson = decoded.right

  const historicalLesson: HistoricalLesson = { 
    ...lesson,
    UID: lesson.UID,
    targetLanguageCode: lesson.targetLanguageCode as SupportedLanguageCodes,
    startedAt: lesson.startedAt,
    finishedAt: lesson.finishedAt!, // will always have finished as is historical lesson
    vocabulary: lesson.vocabulary.map(([vocabularyItem, enabled]) => ({
      vocabularyItem,
      enabled
    })),
    reviews: lesson.reviews,
  }

  return historicalLesson
}

const listResponseValidator = t.type({
  lessons: t.array(LessonDTOValidator)
})

const list = async (
  token: string, 
  filter?: { from?: Date, to?: Date }
): Promise<HistoricalLesson[]> => {
  const fromString = filter?.from
    ? `from=${filter?.from?.toISOString()}`
    : ""
  const toString = filter?.to
    ? `to=${filter?.to?.toISOString()}`
    : ""
  let queryString = [fromString, toString].filter(s => s != "").join("&")
  if (queryString.length > 0) queryString = "?" + queryString

  const response = await fetch(`/.netlify/functions/get-lessons${queryString}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  if (!response.ok) {
    throw new Error(response.statusText)
  }

  const responseData = await response.json()

  const decoded = listResponseValidator.decode(responseData)
  if (isLeft(decoded)) {
    throw new Error(`Could not validate data: ${PathReporter.report(decoded).join("\n")}`)
  }

  const { lessons } = decoded.right

  const historicalLessons: HistoricalLesson[] = lessons.map(lesson => ({ 
    ...lesson,
    ID: lesson.UID,
    targetLanguageCode: lesson.targetLanguageCode as SupportedLanguageCodes,
    startedAt: new Date(lesson.startedAt),
    finishedAt: new Date(lesson.finishedAt!), // will always have finished as is historical lesson
    vocabulary: lesson.vocabulary.map(([vocabularyItem, enabled]) => ({
      vocabularyItem,
      enabled
    }))
  }))

  return historicalLessons
}

const addEventResponseValidator = t.type({
  reviews: t.array(ReviewDTOValidator),
  lessonId: t.number,
})

const addEvent = async (token: string, lessonEvent: LessonEvent, lessonId?: number): Promise< { reviews: ReviewDTO[] }> => {
  const updateData = {
    lessonId,
    messages: [ { 
      text: lessonEvent.data(), 
      source: lessonEvent.type, 
      createdAt: lessonEvent.id,
    } ]
  }

  const updateResponse = await fetch('/.netlify/functions/update-messages', {
      method: 'PATCH',
      body: JSON.stringify(updateData),
      headers: {
        Authorization: `Bearer ${token}`
      }
  })

  const updateResponseData = await updateResponse.json()
  if (!updateResponse.ok) throw new Error(updateResponseData)

  const decoded = addEventResponseValidator.decode(updateResponseData)
  if (isLeft(decoded)) {
    throw new Error(`Could not validate data: ${PathReporter.report(decoded).join("\n")}`)
  }

  return { 
    reviews: decoded.right.reviews 
  }
}

const update = async (token: string, lesson: Partial<LessonDTO>): Promise<string> => {
    const updateResponse = await fetch('/.netlify/functions/update-lesson', {
        method: 'PATCH',
        body: JSON.stringify({ lessonData: lesson }),
        headers: {
          Authorization: `Bearer ${token}`
        }
    })

    const updateResponseData = await updateResponse.json()
    if (!updateResponse.ok) throw new Error(updateResponseData)

    const { lessonId } = updateResponseData
    return lessonId
}

const end = async (token:string) => {
    const response = await fetch('/.netlify/functions/end-lesson', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`
        }
    })
    const responseData = await response.json()
    if (!response.ok) throw new Error(responseData)
    
    const { lessonId } = responseData
    return lessonId
}

const start = async (token:string) => {
    const response = await fetch('/.netlify/functions/start-lesson', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`
        }
    })
    const responseData = await response.json()
    if (!response.ok) throw new Error(responseData)
    
    const { lessonId } = responseData
    return lessonId
}

export const useLessonClient = () => {
  const { session } = useAuth()
  const token = session?.access_token

  return {
    start: () => start(ensureToken(token)),
    end: () => end(ensureToken(token)),
    update: (lesson: Parameters<typeof update>[1]) => update(ensureToken(token), lesson),
    get: (lessonId: Parameters<typeof get>[1]) => get(ensureToken(token), lessonId),
    list: (filter?: Parameters<typeof list>[1]) => list(ensureToken(token), filter),
    addEvent: (
      lessonEvent: Parameters<typeof addEvent>[1],
      lessonId: Parameters<typeof addEvent>[2],
    ) => addEvent(ensureToken(token), lessonEvent, lessonId),
    isReady: !!token
  }
}

const LessonClient = { start, update, end, get, list, addEvent }
