import React from 'react'
import { motion, useAnimation } from 'framer-motion'
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer'

function MotionZoomIn(props:
    { src: string, alt: string, variants: any}) {
    const control = useAnimation()
    const [ref, inView] = useInView()
    
    

    useEffect(() => {
        if (inView) {
          control.start("visible");
        }}, [control, inView]);

  return (
    <motion.img 
            className="h-48 mb-3"
            src={props.src} 
            alt={props.alt}
            ref={ref}
            variants={props.variants}
            initial="hidden"
            animate={control} 
    />
         
  )
}

export {MotionZoomIn}
