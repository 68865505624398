import {faGaugeSimpleHigh, faLanguage, faPencil, faRepeat, faX} from "@fortawesome/free-solid-svg-icons"
import {FC, useRef, useState} from "react"
import {TranslationClient} from "../clients/translate"
import {useAuth} from "../contexts/AuthContext"
import {useLesson} from "../contexts/LessonContext"
import {Language} from "../helpers/language"
import {useChat} from "../hooks/use-chat"
import {UseSelectableWords} from "../hooks/use-selectable-words"
import {ChatMessageRoles, ChatMessageT, newChatMessage} from "../types/ChatMessage"
import {Button} from "./Button"
import {ChatBody} from "./ChatBody"
import {ChatInput} from "./ChatInput"
import {IconButton} from "./IconButton"
import { SelectableWords } from "./SelectableWords"

type AssistantPromptButtonsProps = {
  overwriteHistory: (msgs: ChatMessageT[]) => void
  subject: string,
  userLanguage: Language,
}
const AssistantMessagePromptButtons: FC<AssistantPromptButtonsProps> = ({ overwriteHistory, subject, userLanguage }) => {
  const message = userLanguage.meaningPrompt(subject)
  return <>
      <div className="py-2">
        <Button 
          inverse
          onClick={() => overwriteHistory([ newChatMessage(message, ChatMessageRoles.USER) ])}
        >
         { message }
        </Button>
      </div>
  </>
}
type UserPromptButtonsProps = {
  overwriteHistory: (msgs: ChatMessageT[]) => void
  subject: string,
  userLanguage: Language,
  targetLanguage: Language,
}
const UserMessagePromptButtons: FC<UserPromptButtonsProps> = ({ overwriteHistory, subject, userLanguage, targetLanguage }) => {
  const correctMessage = userLanguage.correctPrompt(subject)
  const alternativeMessage = userLanguage.alternativePrompt(subject)

  return <>
      <div className="py-2">
        <Button 
          onClick={() => overwriteHistory([
            newChatMessage(userLanguage.intentPrompt(targetLanguage.displayName, subject), ChatMessageRoles.ASSISTANT)
          ])}
        >{ alternativeMessage }</Button>
      </div>
      <div className="py-2">
        <Button 
          inverse
          onClick={() => overwriteHistory([
            newChatMessage(userLanguage.mistakePrompt(subject), ChatMessageRoles.USER)
          ])}
        >{ correctMessage }</Button>
      </div>
  </>
}

type Props = {
  msg: ChatMessageT,
}
export const ThreadChat: React.FC<Props> = ({ msg }) => {
  const backgroundRef = useRef<HTMLDivElement>(null);
  const [translation, setTranslation] = useState<string | null>(null)
  const [showTranslation, setShowTranslation] = useState<boolean>(false)

  const { user } = useAuth()
  const { saveVocabulary, getVocabulary } = useLesson()

  const vocabulary = getVocabulary()
  const reviewedVocabulary = vocabulary.filter(v => v.reviewed)
  const addedDuringLessonVocabulary = vocabulary.filter(v => v.addedDuringLesson)
  
  const { words, hasSelection, selection, onEnterWordAtIndex, unselectAll, isSelecting } = UseSelectableWords(
    msg.content,
    reviewedVocabulary.concat(addedDuringLessonVocabulary),
  )

  if (!user) throw Error("user must be logged in")
  if (!user.targetLanguage) throw Error("user must have a target language set")

  // threadChat has it's own chat instance
  const {
    currentChat,
    chatHistory,
    sendMessage,
    overwriteHistory,
    state,
    readAloud,
    stopAllAudio,
  } = useChat({
prompt: `You are a ${user.targetLanguage.displayName} teacher. 
You are analysing the phrase "${hasSelection ? selection : msg.content}". Answer any questions about the phrase. 
Respond with short sentences in ${user.userLanguage.displayName}.`
  })

  const toggleShowTranslation = () => setShowTranslation(show => !show)

  const translate = async (msg: ChatMessageT) => {
    if (!translation) {
      if (!user.targetLanguage) throw Error("user must have a target language set")
      const translationResult = await TranslationClient.translate(
        msg.content, 
        user.userLanguageCode, 
        user.UID, 
        user.targetLanguage.code) as string
      setTranslation(translationResult)
    }

    toggleShowTranslation()
  }

  const subject = hasSelection ? selection : msg.content

  return <div className={`w-full h-full p-2 ${isSelecting ? 'overflow-clip' : ''}`}>
    <div className={`container mx-auto`} ref={backgroundRef}>
      <SelectableWords words={words} onEnterWordAtIndex={onEnterWordAtIndex} />
      {translation && showTranslation && 
      <div>
        <img className="p-2 mx-auto h-7" src="color-regular.png" />
        <p className={`text-xl text-center text-gray-600 italic`}>{translation}</p>
      </div> 
      }
      <div className={`py-2 flex justify-center justify-evenly`}>
        <IconButton icon={faRepeat} circle inverse onClick={() => readAloud({...msg, content: subject})} />
        <IconButton icon={faGaugeSimpleHigh} circle inverse iconProps={{flip: 'horizontal'}} onClick={() => readAloud({...msg, content: subject}, 'slow')} />
        <IconButton icon={faLanguage} circle inverse onClick={() => translate(msg)} />
        {hasSelection &&
          <>
            <IconButton icon={faPencil} circle primary onClick={() => saveVocabulary(subject)} />
            <IconButton icon={faX} circle onClick={() => { unselectAll(); stopAllAudio()} } />
          </>
        }
      </div>
      <div>
        {msg.role === ChatMessageRoles.USER 
          ? <UserMessagePromptButtons overwriteHistory={overwriteHistory} subject={subject} userLanguage={user.userLanguage} targetLanguage={user.targetLanguage}/>
          : <AssistantMessagePromptButtons overwriteHistory={overwriteHistory} subject={subject} userLanguage={user.userLanguage}/>
        }
      </div>
      <ChatBody 
        readAloud={async (msg: ChatMessageT) => {}}
        removeTo={async (msg: ChatMessageT) => {}}
        toggleMessageTranslation={async (msg: ChatMessageT) => {}}
        cancel={() => {}}
        currentChat={currentChat}
        state={state}
        chatHistory={chatHistory}
        vocabulary={[]}
        onMessageClick={() => {}}
        buttonsEnabled={false}
      />
      <div className="py-2">
        <ChatInput 
          sendMessage={sendMessage}
          isEnabled={state === 'idle'}
          stopAllAudio={stopAllAudio}
        />
      </div>
    </div>
  </div>
}
