
export const ensureToken = (token?: string): string => {
  if (!token) throw new Error("no token provided")
  return token
}

type OmitFirstArg<Func> = Func extends (arg: any, ...rest: infer P) => infer R
  ? (...args: P) => R
  : never;

type FunctionMap<ClientType> = {
  [K in keyof ClientType]: OmitFirstArg<ClientType[K]>;
};

function createWrapperFunction<Func extends (arg: any, ...rest: any[]) => any>(fn: Func, token?: Parameters<Func>[0]): OmitFirstArg<Func> {
  return ((_, ...args: any[]) => {  // Using _ to indicate we're ignoring the first argument
    let ensuredToken = ensureToken(token);
    return fn(ensuredToken, ...args);
  }) as OmitFirstArg<Func>;
}

export function wrapClient<ClientType extends Record<string, (...args: any[]) => any>>(client: ClientType, token?: Parameters<ClientType[keyof ClientType]>[0]): FunctionMap<ClientType> {
  const keys = Object.keys(client) as Array<keyof ClientType>;

  return keys.reduce<FunctionMap<ClientType>>((acc, key) => {
    const fn = client[key];
    acc[key] = createWrapperFunction(fn, token);
    return acc;
  }, {} as FunctionMap<ClientType>);
}

