import {ReviewDTO} from 'interfaces/Review';
import {SupportedLanguageCodes} from 'interfaces/SupportedLanguages';
import {VocabularyDTO} from 'interfaces/Vocabulary';
import React, {FC, PropsWithChildren, useEffect, useState} from 'react';
import {LessonDTO} from '../../lib/interfaces/Lesson';
import {PhraseDTO} from '../../lib/interfaces/Phrase';
import { useLessonClient } from '../clients/lesson';
import { UserClient } from '../clients/user';
import { useVocabularyClient } from '../clients/vocabulary';
import {ChatHook, useChat} from '../hooks/use-chat';
import {HistoricalLesson} from '../routes/History';
import {ChatMessageRoles, ChatMessageT, newChatMessage} from '../types/ChatMessage';
import {LessonEvent, VocabularyRecordRequestLessonEvent } from '../types/LessonEvent';
import {useAuth} from './AuthContext';

export type LessonVocabulary = VocabularyDTO & {
  reviewed: boolean,
  addedDuringLesson: boolean,
}

export type Lesson = {
  ID: string,
  startedAt: Date,
  finishedAt?: Date,
  targetLanguageCode: SupportedLanguageCodes,
  phrases: PhraseDTO[],
  starterMessage?: string,
  reviews: ReviewDTO[],
}

export type LessonContextType = {
  isInLesson: () => boolean,
  startLesson: (msg?: string) => Promise<void>
  endLesson: () => Promise<string>
  getChat: () => ChatHook
  addEvent: (e: LessonEvent) => void
  getVocabulary: () => LessonVocabulary[]
  saveVocabulary: (v: string) => void
  isCalculating: boolean
  isLoading: boolean
};
  
export const LessonProvider: FC<PropsWithChildren> = ({children}) => {
  const vocabularyClient = useVocabularyClient()
  const lessonClient = useLessonClient()

  const [lesson, setLesson] = useState<Lesson | null>(null)
  const [isCalculating, setIsCalculating] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [vocabulary, setVocabulary] = useState<VocabularyDTO[]>([])
  const [viewedVocabulary, setViewedVocabulary] = useState<VocabularyDTO[]>([])
  const [rememberedVocabulary, setRememberedVocabulary] = useState<Set<string>>(new Set())
  const { user, session } = useAuth()
  const chat = useChat({
    addEvent, 
    getVocabulary
  })
  const { overwriteHistory } = chat
  const reviewedVocabulary = vocabulary?.filter(v => viewedVocabulary.some(vv => vv.id === v.id))
  const unreviewedVocabulary = vocabulary?.filter(v => !viewedVocabulary.some(vv => vv.id === v.id))

  useEffect(() => {
    const getLessonFromAPI = async () => {
      const token = session?.access_token
      if (!token) return;
      if (!user) return;
      
      if (!user.currentLessonId) {
        overwriteHistory([])
        setLesson(null)
        return;
      };

      const lesson: Omit<HistoricalLesson, 'finishedAt'> = await lessonClient.get("" + user.currentLessonId)
      setViewedVocabulary(lesson.reviews.map(r => r.vocabulary))

      const vocab = await vocabularyClient.list({
        enabled: true, 
        language: lesson?.targetLanguageCode
      })
      setVocabulary(vocab)

      const lessonHistory  = recreateChatHistoryFromPhrases(lesson.phrases)

      overwriteHistory(lessonHistory)
      setLesson({
        ...lesson,
        ID: lesson.UID,
        finishedAt: undefined,
      })
    }

   vocabularyClient.isReady 
    && lessonClient.isReady
    && user
    && getLessonFromAPI()
  }, [ user, vocabularyClient.isReady, lessonClient.isReady ])

  useEffect(() => {
    if (lesson?.starterMessage && chat.chatHistory.length === 0) {
      chat.sendMessage(lesson.starterMessage)
    }
  }, [lesson])

  function addToRememberedVocabulary(v: string) {
    setRememberedVocabulary(old => new Set(old).add(v))
  }

  function getVocabulary(): LessonVocabulary[] {
    const reviewed = reviewedVocabulary
      .map(v => ({...v, reviewed: true, addedDuringLesson: false}))
    const unreviewed = unreviewedVocabulary
      .map(v => ({...v, reviewed: false, addedDuringLesson: false}))
    const remembered = Array.from(rememberedVocabulary)
      .map((v, i) => ({
        id: 1000000 + i, 
        text: v, 
        source: "", 
        date: new Date().toISOString(), 
        languageCode: lesson!.targetLanguageCode, 
        enabled: true, 
        reviewed: false,
        addedDuringLesson: true, 
      }))
    
    return reviewed.concat(unreviewed).concat(remembered)
  }

  function recreateChatHistoryFromPhrases(phrases: PhraseDTO[]): ChatMessageT[] {
    let history: ChatMessageT[] = []

    // sort from oldest to most recent
    phrases.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime()) 

    for (const phrase of phrases) {
      switch (phrase.source) {
        case "ASSISANT_MESSAGE":
          history.push(newChatMessage(phrase.text, ChatMessageRoles.ASSISTANT, 'text', phrase.createdAt))
          break
        case "USER_MESSAGE":
          history.push(newChatMessage(phrase.text, ChatMessageRoles.USER, 'text', phrase.createdAt))
          break
        case "RETRY_REQUEST":
          JSON.parse(phrase.text)
            .map((id: string) => new Date(id))
            .forEach((date: Date) => {
                const i = history.findIndex(msg => msg.id.getTime() === date.getTime())
                if (i >= 0) history.splice(i, 1)
            })
            break
        case "TRANSLATION_REQUEST":
          const translation = JSON.parse(phrase.text)
          history = history.map(msg => msg.content === translation.from 
            ? { ...msg, translation: translation.to }
            : msg)
          break;
        case "VOCABULARY_RECORD_REQUEST":
          // create fake vocabulary record for review request
          addToRememberedVocabulary(phrase.text)
          break;
      }
    }

    return history
  }

  function isInLesson(): boolean {
    return !!lesson
  }

  async function startLesson(starterMessage?: string): Promise<void> {
    // get latest vocabulary for lesson
    setIsLoading(true)
    try {
      const vocab = await vocabularyClient.list({enabled: true, language: lesson?.targetLanguageCode})
      setVocabulary(vocab)
      setViewedVocabulary([])

      const lessonId = await lessonClient.start()

      const newLesson = {
        ID: lessonId,
        startedAt: new Date(),
        phrases: [],
        targetLanguageCode: user!.targetLanguage!.code, // todo: type saftey
        starterMessage: starterMessage,
        reviews: [],
      }
      chat.clear()
      setLesson(newLesson)
    } catch (e) {
      // todo: user facing error handling
      console.error(e)
      setLesson(null)
    } finally {
      setIsLoading(false)
    }
  } 
    
  async function endLesson(): Promise<string> {
    try {
      if (!lesson) throw new Error('Trying to end lesson before started')

      const lessonToUpload: Omit<LessonDTO, 'UID' | 'vocabulary'> = {
        ...lesson, 
        startedAt: lesson.startedAt,
        finishedAt: lesson.finishedAt && lesson.finishedAt,
      }

      setIsCalculating(true)
      await lessonClient.update(lessonToUpload)
      const lessonId = await lessonClient.end()
      await lessonClient.get(lessonId) // get lesson to calculate early
      setIsCalculating(false)
      
      setLesson(null)
      setVocabulary([])
      setViewedVocabulary([])
      setRememberedVocabulary(new Set())
      return lessonId
    } catch (e) {
      console.error(e)
      setLesson(null)
      throw e
    }   
  } 

  // asynchronously sends data to API
  async function addEvent(e: LessonEvent) {
    const id = lesson?.ID ? parseInt(lesson.ID) : undefined
    lessonClient.addEvent(e, id)
      .then(({ reviews }) => setViewedVocabulary(oldViewedVocabulary => 
        oldViewedVocabulary.concat(reviews
          .map(r => r.vocabulary)
          .filter(v => !oldViewedVocabulary.some(vv => vv.id === v.id)))
        ))
  }

  async function saveVocabulary(vocabulary: string) {
    // remove any trailing punctuation 
    const endsWithPunctuactionRegex = /[.!?]$/;
    vocabulary = vocabulary.replace(endsWithPunctuactionRegex, '')

    addEvent(new VocabularyRecordRequestLessonEvent(new Date(), {
      message: vocabulary
    }))

    addToRememberedVocabulary(vocabulary)
  }
  
  return (
    <LessonContext.Provider value={{ 
      isInLesson, 
      startLesson, 
      endLesson, 
      isLoading, 
      getChat: () => chat, 
      addEvent, 
      saveVocabulary,
      getVocabulary, 
      isCalculating 
    }}>
      {children}
    </LessonContext.Provider>
  )
}

export const LessonContext = React.createContext<LessonContextType | undefined >(undefined);

export const useLesson = () => {
  const ctx = React.useContext(LessonContext)
  if (ctx === undefined) {
    throw new Error("lesson context must be defined")
  }

  return ctx
}

