import {faEllipsis} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FC, PropsWithChildren, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Card} from "../components/Card";
import {LanguageIcon} from "../components/LanguageIcon";
import { useLessonClient } from "../clients/lesson"
import {Toggle} from "../components/Toggle";
import {PhraseSourceDTO} from "interfaces/Phrase";
import HighlightInstances from "../components/HighlightInstances";
import {SupportedLanguageCodes} from "interfaces/SupportedLanguages";
import {useError} from "../contexts/ErrorContext";

const Section: FC<PropsWithChildren> = ({ children }) => {
  return <div className="mt-6 border-t border-gray-100">
    { children }
  </div>
}

const SectionHeader: FC<PropsWithChildren> = ({ children }) => {
  return <div className="py-4 sm:px-0">
    <h2 className="text-xl font-semibold text-center text-gray-900 leading-7 dark:text-gray-100">{ children }</h2>
  </div>
}

const SectionSubHeader: FC<PropsWithChildren> = ({ children }) => {
  return <div className="pb-2 sm:px-0">
    <h3 className="text-sm leading-tight text-left text-gray-500 dark:text-gray-200">{ children }</h3>
  </div>
}

type VocabularySectionProps = {
  vocabulary: Vocabulary[]
  onToggleVocabulary: (i: number) => Promise<void>
}

const VocabularySection: FC<VocabularySectionProps> = ({ vocabulary, onToggleVocabulary }) => {
  return <Section>
    <div className="flex flex-col items-start">
      <SectionHeader>Vocabulary</SectionHeader>
      <SectionSubHeader>Key vocabulary items found during the lesson. Enabled vocabulary will be used in future lessons to promote retention.</SectionSubHeader>
      <ul>
      {vocabulary.map(({ vocabularyItem, enabled }, i:number) => {
          return <li className="flex flex-row p-2" key={vocabularyItem + ":" + i}>
            <Toggle checked={enabled} value={vocabularyItem} onClick={() => onToggleVocabulary(i)}/>
            <p className="ml-3 font-medium text-gray-900 dark:text-gray-300">{vocabularyItem}</p>
          </li>
        })}
      </ul>
    </div>
  </Section>
}

const PhrasesSection: FC<{
  title?: string,
  subTitle?: string,
  phrases: { text: string }[], 
  targetLanguageCode: SupportedLanguageCodes
  highlight?: string[],
}> = ({ 
  title = 'Key Phrases', 
  subTitle = "Key phrases from the lesson, such as those you asked to be repeated or translated.",
  phrases, 
  targetLanguageCode,
  highlight = [],
}) => {
  return <Section>
    <div className="flex flex-col items-start">
      <SectionHeader>{title}</SectionHeader>
      <SectionSubHeader>{subTitle}</SectionSubHeader>
      <ul>
        {phrases.map(({ text }, i: number) => {
          return <li className="p-2" key={text + ":" + i}>
            <div className="float-left p-2">
              <LanguageIcon targetLanguageCode={targetLanguageCode} />
            </div>
              <p className="p-2 leading-loose dark:text-gray-300">
                "<HighlightInstances text={text} toHighlight={highlight} />"
              </p>
          </li>
        })}
      </ul>
    </div>
  </Section>
}

type Vocabulary = { vocabularyItem: string, enabled: boolean }
type LessonSummary = {
phrases: { text: string, source: PhraseSourceDTO }[],
  vocabulary: Vocabulary[],
  targetLanguageCode: SupportedLanguageCodes,
}

const editInArray = <T, >(arr: T[], i: number, modifier: (t: T) => T): T[] => {
  if (i < 0 || i >= arr.length) {
    return arr; // Return the original array if the index is out of bounds
  }

  const modifiedElement = modifier(arr[i]); // Apply the modifier function to the element at index i

  const before = arr.slice(0, i); // Elements before the modified element
  const after = arr.slice(i + 1); // Elements after the modified element

  return [...before, modifiedElement, ...after]; // Return a new array combining the slices with the modified element
};

export default function Summary() {
  const { lessonId } = useParams();
  const [loading, setLoading] = useState(true)
  const [lessonSummary, setLessonSummary] = useState<LessonSummary | null>(null)
  const { setError } = useError()
  const [enabledVocabulary, setEnabledVocabulary] = useState<Vocabulary[]>([])
  const lessonClient = useLessonClient()

  const onToggleVocabulary = async (i: number): Promise<void> => {
    if (!lessonId) throw Error('lessonID must be provided')

    const { vocabularyItem, enabled } = enabledVocabulary[i]

    lessonClient.update({ 
      UID: lessonId,
      vocabulary: [ [vocabularyItem, !enabled] ] 
    })
    
    setEnabledVocabulary(vocabulary => editInArray(vocabulary, i, (item) => {
      const { vocabularyItem } = item
      return { vocabularyItem, enabled: !item.enabled }
    }))
  }

  useEffect(() => {
    const loadSummaryData = async () => {
      try {
        if (!lessonId) throw Error('lessonID must be provided')

        const historicalLesson = await lessonClient.get(lessonId)
        setLessonSummary({ 
          ...historicalLesson, 
        })

        setEnabledVocabulary(historicalLesson.vocabulary)
        setError(null)
      } catch (e) {
        setLessonSummary(null)
        setError(new Error('Error loading lesson summary'))
      }

      setLoading(false)
    }

    loadSummaryData()
  }, [])

  // messages which include the highlighted vocabulary
  const keyMessages = lessonSummary 
    ? lessonSummary.phrases
      .filter(p => p.source === "USER_MESSAGE" || p.source === "ASSISANT_MESSAGE")
      .filter(p => enabledVocabulary
        .filter(v => v.enabled)
        .some(v => p.text.includes(v.vocabularyItem)))
      .map(p => p.text) 
    : []

  const translations = lessonSummary 
    ? lessonSummary.phrases
      .filter(p => p.source === "TRANSLATION_REQUEST")
      .map(p => JSON.parse(p.text).from)
    : []

  return (
    <div className="flex items-start justify-center h-full p-10 bg-gray-200">
      <Card>
        {loading 
          ? <div className={`flex flex-col items-center justify-center`}>
            <FontAwesomeIcon icon={faEllipsis} beatFade />
          </div>
          :  <>
                <div className="px-4 sm:px-0">
                  <h1 className="text-2xl font-semibold text-center text-gray-900 leading-7 dark:text-gray-100">Lesson Summary</h1>
                </div>
                {lessonSummary?.vocabulary 
                  ? <VocabularySection vocabulary={enabledVocabulary} onToggleVocabulary={onToggleVocabulary}/> 
                  : <div className="p-2 dark:text-gray-300">
                      <p>No vocabulary was recorded during this lesson :( </p> 
                    </div>                }
                {lessonSummary && translations.length > 0 
                  ? <PhrasesSection 
                      title={"Translations"}
                      subTitle={"Translations requested during the lesson."}
                      phrases={translations.map(text => ({text}))} targetLanguageCode={lessonSummary.targetLanguageCode}/>
                  : <div className="p-2 dark:text-gray-300">
                      <p>No translations were recorded during this lesson :( </p> 
                    </div>
                }
                {lessonSummary && keyMessages.length > 0 && 
                  <PhrasesSection 
                    subTitle={"Key phrases from the lesson which include the target vocabulary you've selected."}
                    phrases={keyMessages.map(text => ({text}))} 
                    targetLanguageCode={lessonSummary.targetLanguageCode}
                    highlight={enabledVocabulary.filter(v => v.enabled).map(v => v.vocabularyItem)}
                  />
                }
              </> 
        }
      </Card>
    </div>
  )
}

