import React, {FC, PropsWithChildren} from 'react';
import {useAudioPlayer} from '../hooks/use-audio';

export type AudioContextType = {
  audioPlayer: ReturnType<typeof useAudioPlayer>,
};
  
export const AudioContext = React.createContext<AudioContextType | undefined >(undefined);

export const AudioProvider: FC<PropsWithChildren> = ({ children }) => {
  const audioPlayer = useAudioPlayer()

  return <AudioContext.Provider value={{ audioPlayer }}>
    {children} 
  </AudioContext.Provider>
}


export const useAudio = () => {
  const ctx = React.useContext(AudioContext)
  if (ctx === undefined) {
    throw new Error("context must be defined")
  }

  return ctx
}

