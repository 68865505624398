"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserWithLessonsDTOValidator = exports.UserDTOValidator = exports.UserLanguageDTOValidator = void 0;
const Lesson_1 = require("./Lesson");
const SupportedLanguages_1 = require("./SupportedLanguages");
const t = __importStar(require("io-ts"));
const Level_1 = require("./Level");
exports.UserLanguageDTOValidator = t.type({
    languageCode: SupportedLanguages_1.SupportedLanguageCodes,
    level: Level_1.LevelDTOValidator,
});
exports.UserDTOValidator = t.type({
    UID: t.string,
    userLanguageCode: SupportedLanguages_1.SupportedLanguageCodes,
    targetLanguageCode: t.union([SupportedLanguages_1.SupportedLanguageCodes, t.null]),
    currentLessonId: t.union([t.number, t.null]),
    languages: t.array(exports.UserLanguageDTOValidator),
    autoReadEnabled: t.boolean,
});
exports.UserWithLessonsDTOValidator = t.intersection([
    exports.UserDTOValidator,
    t.type({
        lessons: t.array(Lesson_1.LessonDTOValidator),
    }),
]);
