import {faQuestion, IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {useState} from "react"
import {ConfirmModal} from "../components/ConfirmModal"

type Options = {
  confirmButtonText: string,
  cancelButtonText: string,
  icon: IconDefinition,
  title: string,
  text: string,
}

export const useConfirm = (options?: Partial<Options>) => {
  const [promise, setPromise] = useState<any>(null);

  const confirm = () => new Promise((resolve, reject) => {
    setPromise({ resolve });
  });

  const handleClose = () => {
    setPromise(null);
  };

  const handleConfirm = () => {
    promise?.resolve(true);
    handleClose();
  };

  const handleCancel = () => {
    promise?.resolve(false);
    handleClose();
  };

  const props = {handleConfirm, handleCancel}

  // defaults
  const opts: Options = {
    confirmButtonText: 'Confirm',
    cancelButtonText: 'Cancel',
    icon: faQuestion,
    title: "Confirm",
    text: "Are you sure?",
    ...options,
  } 

  const ConfirmModalElement = () => (<ConfirmModal 
    open={promise !== null} 
    {...props}
    {...opts}
  />);

  return [ConfirmModalElement, confirm] as const
}
