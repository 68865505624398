import {flattenOverlappingSeries, Series, findAllIndicies} from "./series";

const trimPunctuationRegex = /^[\p{P}]+|[\p{P}]+$/gu;

export const wordsMatch = (word: string, search: string): boolean => {
  const trimmedWord = word.replace(trimPunctuationRegex, '');
  const trimmedSearch = search.replace(trimPunctuationRegex, '');
  return trimmedWord.toLowerCase() === trimmedSearch.toLowerCase();
}

export const indiciesOfMatches = (
  text: string, 
  search: string[], 
  opts: { flat: boolean } = { flat: false },
): Series[] => {
  const words = text.split(" ")
   // todo: this can probably be extracted
  const matchingIndicies: Series[] = search.flatMap((match) => {
    // account for multiword
    const wordCount = match.split(" ").length
    if (wordCount > 1) {
      const indicies = findAllIndicies(words, word => wordsMatch(word, match.split(" ")[0]))
      return indicies.flatMap<Series>(i => {
        const wordsToMatch = words.slice(i, i + wordCount).join(" ")
        if (wordsMatch(wordsToMatch, match)) {
          return [[i, i + wordCount]]
        } else {
          return []
        }
      })
    }

    const indicies = findAllIndicies(words, word => wordsMatch(word, match))
    return indicies.map<Series>(i => [i, i + 1])
  })

  if (opts.flat) return flattenOverlappingSeries<Series>(matchingIndicies, { mergeTouching: false })

  return matchingIndicies
}
