import {faEllipsis} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {FC, PropsWithChildren} from "react"

type ButtonProps = {
  isLoading?: boolean
  stretch?: boolean
  primary?: boolean
  inverse?: boolean
}
export const Button: FC<PropsWithChildren<React.HTMLProps<HTMLButtonElement>> & ButtonProps> = ({
  children, 
  stretch = true, 
  primary = false,
  inverse = false,
  isLoading = false,
  ...props}) => {

  const styles = {
    normal: {
      main: "main",
      counter: "white",
    },
    primary: {
      main: "green-600",
      counter: "white",
    },
  }

  const style = styles[primary ? 'primary' : 'normal']
  if (inverse) {
    const temp = style.main
    style.main = style.counter
    style.counter = temp
  }

  return <button {...props} className={`
    inline-flex flex-row justify-center items-center
    text-${style.counter} text-center text-sm font-medium 
    bg-${style.main}
    border-${style.counter} border-2 rounded-lg 
    px-2 py-2.5 
    ${!props.disabled && !isLoading ? `hover:bg-${style.counter} hover:text-${style.main} hover:border-${style.main}` : ''}
    focus:ring-4 focus:text-${style.counter} focus:outline-none focus:ring-blue-200 
    dark:focus:ring-blue-900 
    ${stretch ? "w-full" : "w-auto"} 
    disabled:opacity-50 disabled:cursor-not-allowed
    `} 
    type='button'
    disabled={isLoading || props.disabled}
    >
    {isLoading ? <FontAwesomeIcon icon={faEllipsis} beat /> : children}
  </button>
}
