import {ValuesOf} from "./utils";

export const ChatMessageCommands = {
  COMMAND_RETRY : "command-retry",
  COMMAND_REPEAT_SLOWLY : "command-repeat-slowly",
  COMMAND_REPEAT : "command-repeat",
  COMMAND_TRANSLATE : "command-translate"
} as const

export type ChatMessageCommand = ValuesOf<typeof ChatMessageCommands>

export type ChatMessageSource = 'audio' | 'text';

export const ChatMessageRoles = {
  // these must be like this for chat gpt API
  USER : "user",
  ASSISTANT : "assistant" ,
} as const

export type ChatMessageRole = ValuesOf<typeof ChatMessageRoles> | ChatMessageCommand

export interface ChatMessageT {
  id: Date;
  role: ChatMessageRole;
  content: string;
  source: ChatMessageSource;
  translation: string;
  translationEnabled: boolean;
  translationLoading: boolean;
}

const getNextId = (): Date => {
  return new Date()
}

export const newChatMessage = function(message: string, role: ChatMessageRole, source: ChatMessageSource = 'text', id?: Date): ChatMessageT {
  return {
    id: id || getNextId(),
    role,
    content: message,
    source,
    translation: "",
    translationEnabled: false,
    translationLoading: false,
  }
}

export const newChatCommand = function(command: ChatMessageCommand, options?: {
  content?: string, 
  source?: ChatMessageSource
}): ChatMessageT {
  return {
    id: getNextId(),
    role: command,
    content: options?.content || "",
    source: options?.source || 'audio',
    translation: "",
    translationEnabled: false,
    translationLoading: false,
  }
}
