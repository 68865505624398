import {useCallback, useEffect, useMemo, useState} from "react"
import {LessonVocabulary} from "../contexts/LessonContext"
import {Series} from "../helpers/series"
import {indiciesOfMatches} from "../helpers/words"

export type Highlight = 'single' | 'leftEnd' | 'rightEnd' | 'middle' | 'none'

export type SelectableWord = {
  text: string,
  startSelecting: () => void,
  onEnterWord: () => void,
  match: Highlight,
  selected: Highlight, 
  reviewed: Highlight,
}

const buildHighlightFromIndicies = (i: number, indicies: Series[]): Highlight => {
  const series = indicies.find(s => s[0] <= i && s[1] > i)
  if (!series) {
    return 'none'
  }
  if (series[0] === i && series[1] === i+1) {
    return 'single'
  }
  if (series[0] === i) {
    return 'leftEnd'
  }
  if (series[1] === i+1) {
    return 'rightEnd'
  }
  return 'middle'
}

export const UseSelectableWords = (text: string, vocabulary: LessonVocabulary[] = []): {
  words: SelectableWord[],
  selection: string,
  hasSelection: boolean,
  isSelecting: boolean,
  onEnterWordAtIndex: (i: number) => void,
  stopSelecting: () => void,
  unselectAll: () => void,
} => {
  const [selectedText, setSelectedText] = useState<string>("")
  const [isSelecting, setIsSelecting] = useState(false)
  const [initalSelectedWord, setInitalSelectedWord] = useState(-1)
  const [finalSelectedWord, setFinalSelectedWord] = useState(-1)

  const words = text.split(" ")
  const stopSelecting = () => setIsSelecting(false)
  const unselect = () => {
    setInitalSelectedWord(-1)
    setFinalSelectedWord(-1)
    stopSelecting()
  }

  const inLessonVocabulary = vocabulary.filter(v => v.addedDuringLesson).map(v => v.text)
  const reviewedVocabulary = vocabulary.filter(v => v.reviewed).map(v => v.text)

  const inLessonVocabularyIndicies = useMemo(() => indiciesOfMatches(text, inLessonVocabulary, { flat: true }), [text, vocabulary])
  const reviewedVocabularyIndicies = useMemo(() => indiciesOfMatches(text, reviewedVocabulary, { flat: true }), [text, vocabulary])

  // move this out of compt?
  useEffect(() => {
    window.addEventListener("mouseup", stopSelecting);
    window.addEventListener("touchend", stopSelecting);

    return () => {
      window.removeEventListener("mouseup", stopSelecting)
      window.removeEventListener("touchend", stopSelecting)
    }
  }, []);

  useEffect(() => {
    if (initalSelectedWord === -1 || finalSelectedWord === -1) {
      setSelectedText("")
    } else if (selectingForwards) {
      setSelectedText(words.slice(initalSelectedWord, finalSelectedWord + 1).join(" "))
    } else if (selectingBackwards) {
      setSelectedText(words.slice(finalSelectedWord, initalSelectedWord + 1).join(" "))
    } else if (selectingSingle) {
      setSelectedText(words[initalSelectedWord])
    }
  }, [initalSelectedWord, finalSelectedWord])

  const startSelecting = (i: number) => {
    setIsSelecting(true)
    setInitalSelectedWord(i)
    setFinalSelectedWord(i)
  }

  const onEnterWordAtIndex = useCallback((i: number) => {
    if (!isSelecting) return;
    setFinalSelectedWord((fi) => {
        if (i !== fi) {
          return i
        } 
        return fi
    })
  }, [isSelecting])

  const selectingForwards = initalSelectedWord < finalSelectedWord
  const selectingBackwards = initalSelectedWord > finalSelectedWord
  const selectingSingle = initalSelectedWord === finalSelectedWord

  const leftIndex = selectingSingle || selectingForwards
    ? initalSelectedWord
    : finalSelectedWord
  const rightIndex = selectingSingle || selectingForwards
    ? finalSelectedWord
    : initalSelectedWord

  const hasSelection = selectedText !== ""

  const wordSelected = (i: number): Highlight => {
    const isLeftEnd = i === leftIndex
    const isRightEnd = i === rightIndex
    const inRange = i >= leftIndex && i <= rightIndex

    if (inRange && isLeftEnd && isRightEnd) {
      return 'single'
    }
    if (isLeftEnd) {
      return 'leftEnd'
    }
    if (isRightEnd) {
      return 'rightEnd'
    }
    if (inRange) {
      return 'middle'
    }
    return 'none'
  }

  const wordsNEW: SelectableWord[] = words.map((text, i) => {
    return {
      text,
      stopSelecting,
      startSelecting: () => startSelecting(i),
      onEnterWord: () => onEnterWordAtIndex(i),
      selected: wordSelected(i),
      match: buildHighlightFromIndicies(i, inLessonVocabularyIndicies),
      reviewed: buildHighlightFromIndicies(i, reviewedVocabularyIndicies),
    }
  })

  return { 
    words: wordsNEW,
    selection: selectedText,
    hasSelection,
    isSelecting,
    stopSelecting,
    unselectAll: unselect,
    onEnterWordAtIndex,
  }
}
