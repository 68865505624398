import {SupportedLanguageCodes} from "interfaces/SupportedLanguages";

  async function translate(
    text: string, 
    toLanguageCode: SupportedLanguageCodes, 
    userUID: string,
    fromLanguageCode: SupportedLanguageCodes,
  ): Promise<any> {
    try {
      const response = await fetch('/.netlify/functions/translate', {
          method: 'POST',
          body: JSON.stringify({ 
            text, 
            userLang: fromLanguageCode,
            targetLang: toLanguageCode,
            userUID: userUID, 
            record: false 
          })
      })

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      return await response.json()
    } catch (error) {
      console.error('error translating text', error);
    }
  } 

  export const TranslationClient = { translate }
