import {FC, ReactElement} from "react"
type Props = {
  steps: ReactElement[],
  currentStep: number,
}

export const MultiStep: FC<Props> = ({ steps, currentStep }) => {
  return <div>
    { steps[currentStep] }
  </div>
}
